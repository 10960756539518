import useSWRMutation from 'swr/mutation'

import { getRequest, putRequest } from '../util/utility'

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useGetInstitute = ()  => {
    const { data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute', getRequest)
    return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
    }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useUpdateInstitute = (arg)  => {
    const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute', putRequest)
    return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
    }
}

export const useGetSubscriptions = (arg)  => {
    const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/subscription', getRequest)
    return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
    }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useGetUser = ()  => {
  const { data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/user', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useUpdateUser = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/user', putRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}
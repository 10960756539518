import constants from "../util/constants";

/*
    Store session data, it is only available for specific browser window.
    Session data is Not visible in other window
*/
export const putSessionData = (key, value)  => {
    sessionStorage.setItem(key, value);
}

export const getSessionData = (key)  => {
    return sessionStorage.getItem(key);
}

export const clearSessionData = ()  => {
    sessionStorage.removeItem(constants.SESSION_KEY)

    sessionStorage.removeItem(constants.SESSION_KEY_SUBSCRIPTION)
    sessionStorage.removeItem(constants.SESSION_KEY_TEST_BANK)
    sessionStorage.removeItem(constants.SESSION_KEY_TEST_EVENT)
    sessionStorage.removeItem(constants.SESSION_KEY_TEST_RESPONSE)

    sessionStorage.removeItem(constants.SESSION_KEY_PURCHASE)
    sessionStorage.removeItem(constants.SESSION_KEY_PRACTICE_EXAM)
    sessionStorage.removeItem(constants.SESSION_KEY_PRACTICE_EXAM_RESPONSE)
    sessionStorage.removeItem(constants.SESSION_KEY_PRACTICE_EXAM_RESULT)
}
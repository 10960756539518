import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppContext } from '../../AppContext';
import { isFutureDate } from '../../util/utility';
import { getSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
  const { setHeaderTitle } = React.useContext(AppContext);
  React.useEffect(() => {
    setHeaderTitle('Dashboard')
  }, []); // Run once

  const [subscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
  const navigate = useNavigate();
  const handleLink = (title) => {
    navigate('/'+title)
  }
  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
      
      <Stack direction={{xs:'column',sm:'row'}} justifyContent="center" mx={{ xs: 4, sm: 1, md: 4}} spacing={{xs:2, sm:2, md:5}} marginTop={5}>

        <Grid item xs={4} sm={3} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Test Bank
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Manage your Test Bank with Quiz and Survey questions.
              </Typography>
            </CardContent>
            <Stack direction="row" justifyContent="center">
              <Button variant='outlined' color='info' size="small" onClick={()=>{handleLink('institute/test/bank')}}>Test Bank</Button>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={4} sm={4} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Test Events
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Manage your Test Events by conducting Quiz and Survey to the attendees.
              </Typography>
            </CardContent>
            <Stack direction="row" justifyContent="center">
              <Button variant='outlined' color="info" size="small" onClick={()=>{handleLink('institute/test/events')}}>Test Event</Button>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={4} sm={3} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Subscription
              </Typography>
              {isFutureDate(subscription?.edt) &&
                <Typography variant="body1" color="green">
                  Your current subscription is Active. You can review and upgrade subscription plans.
                </Typography>
              }
              {!isFutureDate(subscription?.edt) &&
                <Typography variant="body1" color="red">
                  Your current subscription is Expired. Please renew by purchasing new subscription.
                </Typography>
              }
            </CardContent>
              <Stack direction="row" justifyContent="center">
                <Button variant='outlined' color="info" size="small" onClick={()=>{handleLink('institute/subscription')}}>Subscription</Button>
              </Stack>
          </Card>
        </Grid>

      </Stack>
    </Grid>
  );
};

export default DashboardPage;

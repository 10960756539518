import { Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MUITextField from '../../components/widgets/fields/MUITextField';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { getEventIdValidator } from '../../services/validations';
import { styled } from '@mui/material/styles';
import { useGetTestEvent } from '../../services/attendee-services';
import { putSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import errors from '../../util/errors';
import MUISnackBar from '../../components/widgets/MUISnackBar';
import { AppContext } from '../../AppContext';

const AttendeeLoginPage = () => {
  var navigate = useNavigate();
  var [snackBar, setSnackBar] = React.useState({show:false});
  var { setPageMode } = React.useContext(AppContext);

  React.useEffect(() => {
    setPageMode('attendee-login')
  }, []);

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '80%'
  });

  // Form Validations
  const testEventForm = Yup.object().shape({
    id: getEventIdValidator()
  });
  const { handleSubmit:validateTestEventForm, reset:resetTestEventForm, control:controlTestEventForm } = useForm({mode:'onTouched', resolver: yupResolver(testEventForm)});   
  
  const {data:testEventData, error:testEvenDataError, trigger:triggerTestEvent, isMutating:testEventMutating} = useGetTestEvent()
  React.useEffect(() => {
    if(testEventData && testEventData.ttl){
      putSessionData(constants.SESSION_KEY_TEST_EVENT, JSON.stringify(testEventData))
      navigate('/attendee/test/event')
    }else if(testEventData?.errs && testEventData?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:testEventData.errs[0].msg})
    }else if(testEvenDataError){
      setSnackBar({show:true,severity:'error',message:errors.E102})
    }
  }, [testEventData]); // Run the effect whenever the data changes

  const handleEventSearch = async(formData) => {
    // Verify it is Numerical
    if(/^\d+$/.test(formData.id)){
      const arg = { 
        pathParams: { eventId: formData.id } 
      }
      await triggerTestEvent(arg)
    }else{
      setSnackBar({show:true,severity:'error',message:errors.E107})
    }
  }

  return (
    <>
      <MUISnackBar open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
      <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }} paddingTop={5} marginBottom={10}>
          <Grid item xs={4} sm={4} md={5} sx={{display: { xs: "none", md: "block" }}}>
              <Img src="/images/brain.jpg" />
          </Grid>
          <Grid item xs={4} sm={8} md={5} align="center">
            <Stack direction="column" spacing={2} width={0.6} alignItems={'center'}>
              <Typography id="attendee-search-title" variant="h4" marginBottom={4}>Take Test</Typography>
              <div />
              <Stack direction="column" spacing={2} width={0.6}>
                <MUITextField id="attendee-testevent-id" label="Event Id" name="id" control={controlTestEventForm} required />
                <MUIButton id="attendee-testevent-btn" loading={testEventMutating} onClick={validateTestEventForm(handleEventSearch)} label="Search" variant="contained" type="secondary" />
              </Stack>
              <div />
              <div />
              <Typography id="attendee-search-title" variant="body1">Enter test Event Id provided by your institution.</Typography>
              <Typography id="attendee-search-title" variant="body1">If you are looking for certification practice exam, please refer confirmation email for the exam link.</Typography>
            </Stack>
          </Grid>
      </Grid>
    </>  
  );
};

export default AttendeeLoginPage;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function MUIQuestionsAccordion(props) {
    const [expanded, setExpanded] = React.useState('question1');
  
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    let rows = [];
    if(props.questions && props.questions.qtns && props.questions.qtns.length > 0){
        props.questions.qtns.forEach((question) => {
            rows.push({ ...question});
        });
    }

    const getAnswerOptionColor = (row,index) => {
      // if(row.typ === 'SCQ'){
      //   if(row.opts[index].id === row.answer[0]){
      //     return 'green'
      //   }
      // }
      return ''
    }

    return (
      <div>
        {rows.map((row) => (
          <Accordion expanded={props.showAll || expanded === 'question-'+row.id} onChange={handleChange('question-'+row.id)} key={row.id}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Typography>Question {row.id}</Typography>
                {row.correct!=undefined && 
                  <Typography>{row.txt}</Typography>
                }
            </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={10} sm={10} md={10} marginBottom={3}>
                {row.txt}
              </Grid>
              <Grid item xs={10} sm={10} md={10} >
                {row.typ === 'SCQ' && 
                  <RadioGroup name="ans" id="ans" key={row.id}>
                    {Array.from(Array(row.opts.length)).map((_, index) => (
                        <FormControlLabel id={"q-"+row.id+"-opt-"+index} style={{color: getAnswerOptionColor(row,index)}}
                                          value={row.opts[index].id}
                                          checked={row.opts[index].id === row.ans[0]}
                                          control={<Radio />} key={row.id+'-'+index}
                                          label={row.opts[index].id +'. '+row.opts[index].txt} />
                    ))}
                  </RadioGroup>
                }
                {row.typ === 'MCQ' && 
                  <FormGroup>
                    {Array.from(Array(row.opts.length)).map((_, index) => (
                        <FormControlLabel id={"q-"+row.id+"-opt-"+index} style={{color: getAnswerOptionColor(row,index)}}
                                          value={row.opts[index].id}
                                          checked={row.ans.indexOf(row.opts[index].id) !== -1}
                                          control={<Checkbox />} key={row.id+'-'+index}
                                          label={row.opts[index].id +'. '+row.opts[index].txt} />
                    ))}
                  </FormGroup>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
import useSWRMutation from 'swr/mutation'

import { deleteRequest, getRequest, postRequest, putRequest } from '../util/utility'


/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useCreateTest = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test', postRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useUpdateTestQuestions = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/{testId}/questions', putRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

export const useDeleteTest = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/{testId}', deleteRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

export const useGetTestBank = ()  => {
  const { data, error, trigger, isMutating} = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

export const useGetTestQuestions = (arg)  => {
  const { data, error, trigger, isMutating} = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/{testId}/questions', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}
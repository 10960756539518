import React from 'react';
import constants from '../../../util/constants';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { Alert, Card, CardContent, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Paper, RadioGroup, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { getCurrentYear, getDisplayDate, getPastYearItems, getSubscriptionValue, isFutureDate } from '../../../util/utility';
import { useGetSubscriptions } from '../../../services/institute-services';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import MUISelectField from '../../../components/widgets/fields/MUISelectField';
import { AppContext } from '../../../AppContext';
import MUISubscriptionsTable from '../../../components/widgets/tables/MUISubscriptionsTable';
import { getSessionData } from '../../../services/storage-services';
import { useGetSubscriptionCatalog } from '../../../services/catalog-services';
import errors from '../../../util/errors';
import { useCreatePurchaseItem } from '../../../services/practiceexam-services';
import MUIRadioButton from '../../../components/widgets/fields/MUIRadioButton';

const SubscriptionsPage = () => {

  var [openPurchaseDialog, setOpenPurchaseDialog] = React.useState(false);
  const handleLink = (action) => {
    if(action === 'terms-and-conditions'){
        setOpenPurchaseDialog({tandc:true})
        window.dataLayer.push({event:'page_view',event_category:'subscription',event_action:'click',event_label:'terms-and-conditions'})
    }
  }

  var [tandcAccepted, setTandCAccepted] = React.useState(false);
  const { setHeaderTitle } = React.useContext(AppContext);
  React.useEffect(() => {
    setHeaderTitle('Subscription')
    triggerGetSubscriptionsCatalog()
    resetSearch({year:getCurrentYear()})
  }, []); // Run once

  const [subscription, setSubscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
  React.useEffect(() => {
    var defaultCur = "USD"
    if(subscription?.cur){
        resetPaymentForm({cur: subscription.cur})
    }else{
        const country = getSessionData(constants.SESSION_KEY_COUNTRY)
        if(country && country!=='undefined'){
            defaultCur = JSON.parse(country).cur
        }
        resetPaymentForm({cur: defaultCur})
    }
  }, [subscription]); // Run once for subscription


  var [selectedCatalog, setSelectedCatalog] = React.useState();
  var {data:subscriptionCatalog, error:subscriptionCatalogError, trigger:triggerGetSubscriptionsCatalog, isMutating:subscriptionCatalogMutating} = useGetSubscriptionCatalog()
  React.useEffect(() => {
    if(subscriptionCatalog && subscriptionCatalog.prices.length>0){
        setCatalogPrice(getPaymentForm('cur'))
    }else if(subscriptionCatalogError){

    }
  }, [subscriptionCatalog]); // Run subscriptionCatalog changes

  React.useEffect(() => {
    setCatalogPrice(getPaymentForm('cur'))
  }, [subscriptionCatalog]); 

  const setCatalogPrice = (currency) => {
    if(subscriptionCatalog && subscriptionCatalog.prices.length>0){
        const filter = subscriptionCatalog.prices.filter(x => x.cur === currency)
        if(filter && filter.length > 0){
            selectedCatalog = filter[0]
            setSelectedCatalog(selectedCatalog)
        }
    }
  }

  const eventSearchSchema = Yup.object().shape({
    
  });
  const {handleSubmit:validateSearch, reset:resetSearch, control:controlSearch } = useForm({mode:'onTouched', resolver: yupResolver(eventSearchSchema)});   
  const {data:subscriptions, error:subscriptionsError, trigger:triggerGetSubscriptions, isMutating:subscriptionsMutating } = useGetSubscriptions()
  const handleRefreshResults = async (formData) => {
      await triggerGetSubscriptions({queryParams: {year:formData.year}})
  }

  const handleCurrencyChange = (event) => {
    resetPaymentForm({cur:event.target.value})
    setCatalogPrice(event.target.value)
  }

  const paymentFormSchema = Yup.object().shape({
  });
  const { handleSubmit:validatePaymentForm, reset:resetPaymentForm, control:controlPaymentForm, getValues:getPaymentForm } = useForm({mode:'onTouched', resolver: yupResolver(paymentFormSchema)});   

  const {data:createPurchase, error:createPurchaseError, trigger:triggerCreatePurchase, isMutating:createPurchaseeMutating} = useCreatePurchaseItem({})
  const handlePayment = async(formData, pgwy) => {
    if(!tandcAccepted){
      // setSnackBar({show:true,severity:'error',message:errors.E204})
      window.dataLayer.push({event:'error_event',event_category:'practice-exam',event_action:'error',event_label:'E204',event_value:errors.E204})
      return;
    }
    const arg = { 
      requestBody: {pgwy:{nm:pgwy}, cur:formData.cur}
    }
    await triggerCreatePurchase(arg)
  }

  var [selectedSub, setSelectedSub] = React.useState();
  const handleDialogOpen = async(sub) => {
    setSelectedSub({code:sub, bal:subscription.bal})
    setOpenPurchaseDialog(true)
  };

  const determinePrice = (sub,type,mrp) => {
    if(selectedCatalog && sub === constants.PKG10 && type === 'MONTHLY'){
        return selectedCatalog?.PKG10[0].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG25 && type === 'MONTHLY'){
        return selectedCatalog?.PKG25[0].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG100 && type === 'MONTHLY'){
        return selectedCatalog?.PKG100[0].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG10 && type === 'YEARLY' && !mrp){
        return selectedCatalog?.PKG10[1].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG25 && type === 'YEARLY' && !mrp){
        return selectedCatalog?.PKG25[1].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG100 && type === 'YEARLY' && !mrp){
        return selectedCatalog?.PKG100[1].amt+' '+selectedCatalog?.sym
    }else if(selectedCatalog && sub === constants.PKG10 && type === 'YEARLY' && mrp){
        return selectedCatalog?.PKG10[1].mrp;
    }else if(selectedCatalog && sub === constants.PKG25 && type === 'YEARLY' && mrp){
        return selectedCatalog?.PKG25[1].mrp;
    }else if(selectedCatalog && sub === constants.PKG100 && type === 'YEARLY' && mrp){
        return selectedCatalog?.PKG100[1].mrp;
    }
  } 

  const handleUPIPayment = async(formData) => {
    handlePayment(formData, 'PHONEPE')
  };

  const handleStripePayment = async(formData) => {
    handlePayment(formData, 'STRIPE')
  };

  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Grid item xs={4} sm={8} md={10} marginTop={2}>
                <Typography variant="h5">Current Subscription</Typography>
                <Divider />
            </Grid>
            <Grid item xs={4} sm={8} md={9}>
                {isFutureDate(subscription?.edt) ? 
                    <Alert id="subscription-heading-id" variant="outlined" severity="success">Your current subscription is Active.</Alert> 
                    : <Alert id="subscription-heading-id" variant="filled" severity="error">Your current subscription is Expired. Please renew by purchasing new subscription.</Alert>
                }
            </Grid>
            <Grid item xs={4} sm={2} md={2.5} marginBottom={2}>
                <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Subscription:</strong></Typography>
                    <Typography id="subscription-sub" variant='body1'>{getSubscriptionValue(subscription?.pkg)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
                <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Start Date:</strong></Typography>
                    <Typography variant='body1'>{getDisplayDate(subscription?.sdt)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
                <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Expiry Date:</strong></Typography>
                    <Typography variant='body1'>{getDisplayDate(subscription?.edt)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Amount:</strong></Typography>
                    <Typography variant='body1'>{subscription?.pay.amt}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
                <Stack direction="row" spacing={1} marginBottom={1}>
                    <Typography variant='body1'><strong>Currency:</strong></Typography>
                    <MUISelectField id="exam-currency-code" name="cur" variant='standard' control={controlPaymentForm} options={constants.PAY_CURRENCIES} onChange={handleCurrencyChange}/>
                </Stack>
            </Grid>

            <Stack direction={{sm: 'column', md: 'row'}} spacing={6} margin={2}>
                <Grid item xs={4} sm={3} md={3}>
                <Card sx={{ minWidth:350, minHeight: 180}} variant='outlined'>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">Silver Package</Typography>
                        <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1" color='success'/><Typography variant="body2" color="text.secondary">&nbsp;Includes all features</Typography></Stack>
                        <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 10 Tests in Test Bank</Typography></Stack>
                        <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 10 Test Events per month</Typography></Stack>
                        <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 50 Attendees for each Test Event</Typography></Stack>

                        <Stack direction="row" spacing={1} marginTop={2} alignItems={'center'}>
                            <Typography id="exam-price-id" variant='body1'>Monthly Price:</Typography>
                            {subscriptionCatalogMutating ? <Skeleton width={50}/> : <Typography id="exam-price-val" variant='body1'><strong>{determinePrice(constants.PKG10,'MONTHLY')}</strong></Typography>}
                        </Stack>
                        <Stack direction="row" spacing={1} marginTop={1} alignItems={'center'}>
                            <Typography id="exam-price-id" variant='body1'>Yearly Price:</Typography>
                            {subscriptionCatalogMutating ? <Typography id="exam-price-val" variant='body1'><Skeleton width={80}/></Typography> 
                                : <Typography id="exam-price-val" variant='body1'><s>{determinePrice(constants.PKG10,'YEARLY',true)}</s>&nbsp;&nbsp;{determinePrice(constants.PKG10,'YEARLY')}</Typography>}
                            <Tooltip title="10% Off - Offer!!" placement="right-start" fontSize="small"><LocalOfferOutlinedIcon /></Tooltip>
                        </Stack>
                    </CardContent>
                    <Stack direction="row" justifyContent="center" marginBottom={1}>
                        <MUIButton loading={subscriptionCatalogMutating} label="Purchase" variant='contained' type='secondary' size="small" onClick={()=>{handleDialogOpen(constants.PKG10)}} />
                    </Stack>
                </Card>
                </Grid>
                <Grid item xs={4} sm={3} md={3}>
                    <Card sx={{minWidth:350, minHeight: 180, backgroundColor:'lightcyan'}} variant='outlined'>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">Gold Package</Typography>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1" color='success'/><Typography variant="body2" color="text.secondary">&nbsp;Includes all features</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 25 Tests in Test Bank</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 25 Test Events per month</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 250 Attendees for each Test Event</Typography></Stack>
                        
                            <Stack direction="row" spacing={1} marginTop={2} alignItems={'center'}>
                                <Typography id="exam-price-id" variant='body1'>Monthly Price:</Typography>
                                {subscriptionCatalogMutating ? <Skeleton width={50}/> : <Typography id="exam-price-val" variant='body1'><strong>{determinePrice(constants.PKG25,'MONTHLY')}</strong></Typography>}
                            </Stack>
                            <Stack direction="row" spacing={1} marginTop={1} alignItems={'center'}>
                                <Typography id="exam-price-id" variant='body1'>Yearly Price:</Typography>
                                {subscriptionCatalogMutating ? <Typography id="exam-price-val" variant='body1'><Skeleton width={80}/></Typography> 
                                    : <Typography id="exam-price-val" variant='body1'><s>{determinePrice(constants.PKG25,'YEARLY',true)}</s>&nbsp;&nbsp;{determinePrice(constants.PKG25,'YEARLY')}</Typography>}
                                <Tooltip title="25% Off - Offer!!" placement="right-start" fontSize="small"><LocalOfferOutlinedIcon /></Tooltip>
                            </Stack>
                        </CardContent>
                        <Stack direction="row" justifyContent="center" marginBottom={1}>
                            <MUIButton loading={subscriptionCatalogMutating} label="Purchase" variant='contained' type='secondary' size="small" onClick={()=>{handleDialogOpen(constants.PKG25)}} />
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={4} sm={3} md={3}>
                    <Card sx={{minWidth:350, minHeight: 180 }} variant='outlined'>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">Platinum Package</Typography>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1" color='success'/><Typography variant="body2" color="text.secondary">&nbsp;Includes all features</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 100 Tests in Test Bank</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 100 Test Events per month</Typography></Stack>
                            <Stack direction="row" alignItems={'center'}><TaskAltIcon fontSize="1"/><Typography variant="body2" color="text.secondary">&nbsp;Up to 500 Attendees for each Test Event</Typography></Stack>
                        
                            <Stack direction="row" spacing={1} marginTop={2} alignItems={'center'}>
                                <Typography id="exam-price-id" variant='body1'>Monthly Price:</Typography>
                                {subscriptionCatalogMutating ? <Skeleton width={50}/> : <Typography id="exam-price-val" variant='body1'><strong>{determinePrice(constants.PKG100,'MONTHLY')}</strong></Typography>}
                            </Stack>
                            <Stack direction="row" spacing={1} marginTop={1} alignItems={'center'}>
                                <Typography id="exam-price-id" variant='body1'>Yearly Price:</Typography>
                                {subscriptionCatalogMutating ? <Typography id="exam-price-val" variant='body1'><Skeleton width={80}/></Typography> 
                                    : <Typography id="exam-price-val" variant='body1'><s>{determinePrice(constants.PKG100,'YEARLY',true)}</s>&nbsp;&nbsp;{determinePrice(constants.PKG100,'YEARLY')}</Typography>}
                                <Tooltip title="40% Off - Offer!!" placement="right-start" fontSize="small"><LocalOfferOutlinedIcon /></Tooltip>
                            </Stack>
                        </CardContent>
                        <Stack direction="row" justifyContent="center" marginBottom={1}>
                            <MUIButton loading={subscriptionCatalogMutating} label="Purchase" variant='contained' type='secondary' size="small" onClick={()=>{handleDialogOpen(constants.PKG100)}} />
                        </Stack>
                    </Card>
                </Grid>
            </Stack>

            <Grid item xs={4} sm={8} md={10} marginTop={2}>
                <Typography variant="h5">Subscription History</Typography>
                <Divider />
            </Grid>
            <Stack direction="column" spacing={1} sx={{ width: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                    <Grid item xs={1} sm={4} md={1.5} marginTop={3}>
                        <MUISelectField id="institute-subscription-year" name="year" label='Year' control={controlSearch} options={getPastYearItems(2)} />
                    </Grid>
                    <Grid item xs={3} sm={4} md={8} marginTop={3}>
                        <Stack direction="row" spacing={2}>
                            <MUIButton loading={subscriptionsMutating} id="institute-subscription-reload" label="Reload" type="info" variant="outlined" size="medium" onClick={handleRefreshResults}/>
                        </Stack>  
                    </Grid>
                    
                    <Grid item xs={4} sm={8} md={8.9}>
                        <MUISubscriptionsTable data={subscriptions} />
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
        <Dialog open={openPurchaseDialog}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <Typography id="take-practice-exam-lbl" fontSize={24}>Purchase Subscription</Typography>
                <IconButton onClick={() => {setOpenPurchaseDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                    
                    <Grid item xs={4} sm={4} md={5}>
                        <Paper elevation={0} sx={{ backgroundColor: "info.light", padding: "5px"}}>
                          <Stack direction="row" spacing={1} marginTop={1} marginBottom={1}>
                            <Typography variant='body1'><>New Package:</></Typography>
                            <Typography variant='body1'><strong>{getSubscriptionValue(selectedSub?.code)}</strong></Typography>
                          </Stack>
                          <Stack direction="row" spacing={1} width={0.8} marginTop={2} marginBottom={1}>
                            <Typography variant='body1'><>Currency:</></Typography>
                            <MUISelectField id="exam-currency-code" name="cur" variant='standard' control={controlPaymentForm} options={constants.PAY_CURRENCIES} onChange={handleCurrencyChange}/>
                          </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} sm={4} md={5}>
                        <Paper elevation={0} sx={{ backgroundColor: "info.light", padding: "5px"}}>
                            <RadioGroup value={null} onChange={()=>{}}>
                                <MUIRadioButton id={"monthly"} name={"monthly"} value={"monthly"} label={<Typography variant="body1">Monthly Price: <strong>{determinePrice(selectedSub?.code,'MONTHLY')}</strong></Typography>} />
                                <MUIRadioButton id={"monthly"} name={"monthly"} value={"monthly"} label={<Typography variant="body1">Yearly Price: <s>{determinePrice(selectedSub?.code,'YEARLY',true)}</s>&nbsp;&nbsp;{determinePrice(selectedSub?.code,'YEARLY')}</Typography>} />
                          </RadioGroup>
                        </Paper>
                    </Grid>
                    <Stack direction="column" spacing={1} width={1} marginTop={1} marginBottom={2}><Divider /></Stack>
                    
                    <Grid item xs={4} sm={4} md={5}>
                        <Stack direction="row" justifyContent={'space-between'} marginLeft={1} marginRight={2}>
                            <Stack direction="column" spacing={1} marginBottom={0}>
                                <Typography variant='body1'>New Purchase:</Typography>
                                <Typography variant='body1'>Previous Balance:</Typography>
                                <Typography variant='body1'>Net Pay Amount:</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1} marginBottom={0}>
                                <Typography variant='body1'>{selectedSub?.bal+' '+selectedCatalog?.sym}</Typography>
                                <Typography variant='body1'>{selectedSub?.bal+' '+selectedCatalog?.sym}</Typography>
                                <Typography variant='body1'><strong>{selectedSub?.bal+' '+selectedCatalog?.sym}</strong></Typography> 
                            </Stack>
                        </Stack>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={4} sm={3} md={4} alignItems={'center'}>
                        <Stack direction="column" spacing={2} marginLeft={4}>
                            <MUIButton id="exam-upi-pay-btn" loading={createPurchaseeMutating} onClick={validatePaymentForm(handleUPIPayment)} label="UPI Pay" type="info" />
                            <MUIButton id="exam-stripe-pay-btn" loading={createPurchaseeMutating} onClick={validatePaymentForm(handleStripePayment)} label="Stripe Pay" type="primary" variant="outlined"/>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={8} md={10} marginTop={2}>
                        <Stack direction="row" spacing={0} alignItems="center">
                            <Checkbox id="exam-accept-terms-btn" required color="success" sx={{[`&`]: {color: 'red'}}} onChange={(event)=>setTandCAccepted(event.target.checked)}/>
                            <Typography variant='body2' color={'red'}>No cancellation allowed, you accept our <Link color="inherit" component={'button'} onClick={()=>handleLink('terms-and-conditions')} target="_self">Terms and Conditions</Link></Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </Grid>
  );
};

export default SubscriptionsPage;

module.exports = Object.freeze({
    PKGTRAIL: 'PKGTRIAL',
    PKG10: 'PKG10',
    PKG25: 'PKG25',
    PKG100: 'PKG100',

    SCQ_TYPE: 'SCQ',
    MCQ_TYPE: 'MCQ',

    SUBSCRIPTION_CODE_ENUM: [
        {code:'PKGTRIAL', value:'Trial Silver Package'},
        {code:'PKG10', value:'Silver Package'},
        {code:'PKG25', value:'Gold Package'},
        {code:'PKG100', value:'Platinum Package'}
    ],

    SESSION_KEY: 'session',
    SESSION_KEY_SUBSCRIPTION: 'subscription',
    SESSION_KEY_COUNTRY: 'country',
    SESSION_KEY_TEST_BANK: 'testbank',
    SESSION_KEY_TEST_EVENT: 'testevent',
    SESSION_KEY_TEST_RESPONSE: 'testresponse',
    SESSION_KEY_PURCHASE: 'purchase',
    SESSION_KEY_PRACTICE_EXAM: 'practiceexam',
    SESSION_KEY_PRACTICE_EXAM_RESPONSE: 'practiceexamresponse',
    SESSION_KEY_PRACTICE_EXAM_RESULT: 'practiceexamresult',

    QUIZ_TYPES: [
        {label:'Single Choice (SCQ)', value:'SCQ'},
        {label:'Multiple Choice (MCQ)', value:'MCQ'}
    ],

    TEST_TYPES: [
        {label:'Quiz', value:'QUIZ'},
        {label:'Exam', value:'EXAM'}
    ],

    STATUS_CODE_SCHEDULED: 'SCH',
    STATUS_CODE_INPROGRESS: 'IPG',
    STATUS_CODE_COMPLETED: 'COM',
    STATUS_CODE_CANCELLED: 'CAN',
    STATUS_CODE_ENUM: [
        {code:'SCH', value:'Scheduled'},
        {code:'IPG', value:'In Progress'},
        {code:'COM', value:'Completed'}
    ],

    PAYMENT_STS_CODE_ENUM: [
        {code:'PEN', value:'Pending'},
        {code:'IPG', value:'Processing'},
        {code:'COM', value:'Completed'}
    ],

    INSTITUTE_TYPES: [
        {label:'Training Institute',value:'TRAINING'},
        {label:'School',value:'SCHOOL'},
        {label:'College',value:'COLLEGE'},
        {label:'Corporate',value:'CORPORATE'},
        {label:'Other',value:'OTHER'}
    ],

    CALENDAR_MONTHS_LIST: [
        {label:'Jan',value:1},
        {label:'Feb',value:2},
        {label:'Mar',value:3},
        {label:'Apr',value:4},
        {label:'May',value:5},
        {label:'Jun',value:6},
        {label:'Jul',value:7},
        {label:'Aug',value:8},
        {label:'Sep',value:9},
        {label:'Oct',value:10},
        {label:'Nov',value:11},
        {label:'Dec',value:12}
    ],

    COUNTRY_LIST: [
        { label: 'America', value: 'USA' },
        { label: 'Argentina', value: 'ARG' },
        { label: 'Australia', value: 'AUS' },
        { label: 'Austria', value: 'AUT' },
        { label: 'Bangladesh', value: 'BGD' },
        { label: 'Belgium', value: 'BEL' },
        { label: 'Brazil', value: 'BRA' },
        { label: 'Bulgaria', value: 'BGR' },
        { label: 'Canada', value: 'CAN' },
        { label: 'Chile', value: 'CHL' },
        { label: 'China', value: 'CHN' },
        { label: 'Colombia', value: 'COL' },
        { label: 'Denmark', value: 'DNK' },
        { label: 'Egypt', value: 'EGY' },
        { label: 'Finland', value: 'FIN' },
        { label: 'France', value: 'FRA' },
        { label: 'Germany', value: 'DEU' },
        { label: 'Greece', value: 'GRC' },
        { label: 'Hong Kong', value: 'HKG' },
        { label: 'Hungary', value: 'HUN' },
        { label: 'India', value: 'IND' },
        { label: 'Indonesia', value: 'IDN' },
        { label: 'Ireland', value: 'IRL' },
        { label: 'Israel', value: 'ISR' },
        { label: 'Italy', value: 'ITA' },
        { label: 'Japan', value: 'JPN' },
        { label: 'Luxembourg', value: 'LUX' },
        { label: 'Malaysia', value: 'MYS' },
        { label: 'Mexico', value: 'MEX' },
        { label: 'Netherlands', value: 'NLD' },
        { label: 'New Zealand', value: 'NZL' },
        { label: 'Norway', value: 'NOR' },
        { label: 'Switzerland', value: 'CHE' },
        { label: 'Taiwan', value: 'TWN' },
        { label: 'Thailand', value: 'THA' },
        { label: 'Turkey', value: 'TUR' },
        { label: 'Ukraine', value: 'UKR' },
        { label: 'United Kingdom', value: 'GBR' },
        { label: 'Vietnam', value: 'VNM' }
    ],

    // https://unicode.org/Public/emoji/1.0/emoji-data.txt
    PAY_CURRENCIES: [
        {label:'🇦🇷 ARS ($)', value:'ARS'},
        {label:'🇦🇺 AUD ($)', value:'AUD'},
        {label:'🇧🇩 BDT (৳)', value:'BDT'},
        {label:'🇧🇬 BGN (лв)', value:'BGN'},
        {label:'🇧🇷 BRL (R$)', value:'BRL'},
        {label:'🇨🇦 CAD ($)', value:'CAD'},
        {label:'🇨🇭 CHF (Fr)', value:'CHF'},
        {label:'🇨🇱 CLP ($)', value:'CLP'},
        {label:'🇨🇳 CNY (¥)', value:'CNY'},
        {label:'🇩🇰 DKK (kr)', value:'DKK'},
        {label:'🇪🇬 EGP (£)', value:'EGP'},
        {label:'🇪🇺 EUR (€)', value:'EUR'},
        {label:'🇬🇧 GBP (£)', value:'GBP'},
        {label:'🇭🇰 HKD ($)', value:'HKD'},
        {label:'🇭🇺 HUF (Ft)', value:'HUF'},
        {label:'🇮🇱 ILS (₪)', value:'ILS'},
        {label:'🇮🇳 INR (₹)', value:'INR'},
        {label:'🇯🇵 JPY (¥)', value:'JPY'},
        {label:'🇰🇷 KRW (₩)', value:'KRW'},
        {label:'🇱🇰 LKR (₨)', value:'LKR'},
        {label:'🇲🇽 MXN ($)', value:'MXN'},
        {label:'🇲🇾 MYR (RM)', value:'MYR'},
        {label:'🇳🇴 NOK (kr)', value:'NOK'},
        {label:'🇳🇿 NZD ($)', value:'NZD'},
        {label:'🇵🇭 PHP (₱)', value:'PHP'},
        {label:'🇵🇱 PLN (zł)', value:'PLN'},
        {label:'🇶🇦 QAR (﷼)', value:'QAR'},
        {label:'🇸🇦 SAR (﷼)', value:'SAR'},
        {label:'🇸🇪 SEK (kr)', value:'SEK'},
        {label:'🇸🇬 SGD ($)', value:'SGD'},
        {label:'🇹🇭 THB (฿)', value:'THB'},
        {label:'🇹🇷 TRY (₺)', value:'TRY'},
        {label:'🇹🇼 TWD (NT$)', value:'TWD'},
        {label:'🇺🇦 UAH (₴)', value:'UAH'},
        {label:'🇺🇸 USD ($)', value:'USD'},
        {label:'🇿🇦 ZAR (R)', value:'ZAR'}
    ],

    /*
        Run below command in any browser console

        for (let name of Intl.supportedValuesOf('timeZone')) {
            console.log('{ tz: \''+name+'\', cnt: \'ARG\', cur: \'ARS\'}')
        }

        https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    */
    COUNTRY_MAP: [
        // Argentina
        { tz: 'America/Argentina/Buenos_Aires', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Catamarca', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/ComodRivadavia', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Cordoba', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Jujuy', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/La_Rioja', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Mendoza', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Rio_Gallegos', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Salta', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/San_Juan', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/San_Luis', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Tucuman', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Argentina/Ushuaia', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Buenos_Aires', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Catamarca', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Cordoba', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Jujuy', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Mendoza', cnt: 'ARG', cur: 'ARS'},
        { tz: 'America/Rosario', cnt: 'ARG', cur: 'ARS'},
        // Australia
        { tz: 'Australia/Broken_Hill', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Perth', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Melbourne', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Adelaide', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Eucla', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Sydney', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Lindeman', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Antarctica/Macquarie', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Lord_Howe', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Brisbane', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Darwin', cnt: 'AUS', cur: 'AUD' },
        { tz: 'Australia/Hobart', cnt: 'AUS', cur: 'AUD' },
        // Austria
        { tz: 'Europe/Vienna', cnt: 'AUT', cur: 'EUR'},
        // Bangladesh
        { tz: 'Asia/Dhaka', cnt: 'BGD', cur: 'BDT'},
        { tz: 'Asia/Dacca', cnt: 'BGD', cur: 'BDT'},
        // Belgium
        { tz: 'Europe/Brussels', cnt: 'BEL', cur: 'EUR'},
        // Brazil
        { tz: 'America/Araguaina', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Bahia', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Belem', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Boa_Vista', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Campo_Grande', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Cuiaba', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Eirunepe', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Fortaleza', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Maceio', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Manaus', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Noronha', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Porto_Acre', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Porto_Velho', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Recife', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Rio_Branco', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Santarem', cnt: 'BRA', cur: 'BRL'},
        { tz: 'America/Sao_Paulo', cnt: 'BRA', cur: 'BRL'},
        { tz: 'Brazil/Acre', cnt: 'BRA', cur: 'BRL'},
        { tz: 'Brazil/DeNoronha', cnt: 'BRA', cur: 'BRL'},
        { tz: 'Brazil/East', cnt: 'BRA', cur: 'BRL'},
        { tz: 'Brazil/West', cnt: 'BRA', cur: 'BRL'},
        // Bulgaria
        { tz: 'Europe/Sofia', cnt: 'BGR', cur: 'BGN'},
        // Canada
        { tz: 'America/Atikokan', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Blanc-Sablon', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Cambridge_Bay', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Coral_Harbour', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Creston', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Dawson', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Dawson_Creek', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Edmonton', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Fort_Nelson', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Glace_Bay', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Goose_Bay', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Halifax', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Montreal', cnt: 'CAN', cur: 'CAD'},
        { tz: 'America/Toronto', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Atlantic', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Central', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Eastern', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Mountain', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Newfoundland', cnt: 'CAN', cur: 'CAD'},
        { tz: 'Canada/Pacific', cnt: 'CAN', cur: 'CAD'},
        // Chile
        { tz: 'America/Punta_Arenas', cnt: 'CHL', cur: 'CLP' },
        { tz: 'America/Santiago', cnt: 'CHL', cur: 'CLP' },
        { tz: 'Chile/Continental', cnt: 'CHL', cur: 'CLP' },
        { tz: 'Chile/EasterIsland', cnt: 'CHL', cur: 'CLP' },
        { tz: 'Pacific/Easter', cnt: 'CHL', cur: 'CLP' },
        // China
        { tz: 'Asia/Chongqing', cnt: 'CHN', cur: 'CNY' },
        { tz: 'Asia/Chungking', cnt: 'CHN', cur: 'CNY' },
        { tz: 'Asia/Harbin', cnt: 'CHN', cur: 'CNY' },
        { tz: 'Asia/Kashgar', cnt: 'CHN', cur: 'CNY' },
        { tz: 'Asia/Shanghai', cnt: 'CHN', cur: 'CNY' },
        { tz: 'Asia/Urumqi', cnt: 'CHN', cur: 'CNY' },
        // Denmark
        { tz: 'Europe/Copenhagen', cnt: 'DNK', cur: 'DKK' },
        // Egypt
        { tz: 'Africa/Cairo', cnt: 'EGY', cur: 'EGP' },
        { tz: 'Egypt', cnt: 'EGY', cur: 'EGP' },
        // Finland
        { tz: 'Europe/Helsinki', cnt: 'FIN', cur: 'EUR' },
        // France
        { tz: 'Europe/Paris', cnt: 'FRA', cur: 'EUR' },
        // Germany
        { tz: 'Europe/Berlin', cnt: 'DEU', cur: 'EUR' },
        { tz: 'Europe/Busingen', cnt: 'DEU', cur: 'EUR' },
        // Greece
        { tz: 'Europe/Athens', cnt: 'GRC', cur: 'EUR' },
        // Hong Kong
        { tz: 'Asia/Hong_Kong', cnt: 'HKG', cur: 'HKD' },
        { tz: 'Hongkong', cnt: 'HKG', cur: 'HKD' },
        // Hungary
        { tz: 'Europe/Budapest', cnt: 'HUN', cur: 'HUF' },
        // India
        { tz: 'Asia/Calcutta', cnt: 'IND', cur: 'INR' },
        { tz: 'Asia/Kolkata', cnt: 'IND', cur: 'INR' },
        // Ireland
        { tz: 'Europe/Dublin', cnt: 'IRL', cur: 'EUR' },
        // Israel
        { tz: 'Asia/Jerusalem', cnt: 'ISR', cur: 'ILS' },
        { tz: 'Asia/Tel_Aviv', cnt: 'ISR', cur: 'ILS' },
        // Italy
        { tz: 'Europe/Rome', cnt: 'ITA', cur: 'EUR' },
        // Japan
        { tz: 'Asia/Tokyo', cnt: 'JPN', cur: 'JPY' },
        // Luxembourg
        { tz: 'Europe/Luxembourg', cnt: 'LUX', cur: 'EUR' },
        // Malaysia
        { tz: 'Asia/Kuala_Lumpur', cnt: 'MYS', cur: 'MYR' },
        { tz: 'Asia/Kuching', cnt: 'MYS', cur: 'MYR' },
        // Mexico
        { tz: 'America/Bahia_Banderas', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Cancun', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Chihuahua', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Ciudad_Juarez', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Ensenada', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Hermosillo', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Matamoros', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Mazatlan', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Merida', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Mexico_City', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Monterrey', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Ojinaga', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Santa_Isabel', cnt: 'MEX', cur: 'MXN' },
        { tz: 'America/Tijuana', cnt: 'MEX', cur: 'MXN' },
        // Netherlands
        { tz: 'Europe/Amsterdam', cnt: 'NLD', cur: 'EUR' },
        // New Zealand
        { tz: 'Pacific/Auckland', cnt: 'NZL', cur: 'NZD' },
        { tz: 'Pacific/Chatham', cnt: 'NZL', cur: 'NZD' },
        // Norway
        { tz: 'Europe/Oslo', cnt: 'NOR', cur: 'NOK' },
        // Philippines
        { tz: 'Asia/Manila', cnt: 'PHL', cur: 'PHP' },
        // Poland
        { tz: 'Europe/Warsaw', cnt: 'POL', cur: 'PLN' },
        // Portugal
        { tz: 'Atlantic/Azores', cnt: 'PRT', cur: 'EUR' },
        { tz: 'Atlantic/Madeira', cnt: 'PRT', cur: 'EUR' },
        { tz: 'Europe/Lisbon', cnt: 'PRT', cur: 'EUR' },
        // Qatar
        { tz: 'Asia/Qatar', cnt: 'QAT', cur: 'QAR' },
        // Saudi Arabia
        { tz: 'Asia/Riyadh', cnt: 'SAU', cur: 'SAR' },
        // Singapore
        { tz: 'Asia/Singapore', cnt: 'SGP', cur: 'SGD' },
        // South Africa
        { tz: 'Africa/Johannesburg', cnt: 'ZAF', cur: 'ZAR' },
        // South Korea
        { tz: 'Asia/Seoul', cnt: 'KOR', cur: 'KRW' },
        // Spain
        { tz: 'Africa/Ceuta', cnt: 'ESP', cur: 'EUR' },
        { tz: 'Atlantic/Canary', cnt: 'ESP', cur: 'EUR' },
        { tz: 'Europe/Madrid', cnt: 'ESP', cur: 'EUR' },
        // Sri Lanka
        { tz: 'Asia/Colombo', cnt: 'LKA', cur: 'LKR' },
        // Sweden
        { tz: 'Europe/Stockholm', cnt: 'SWE', cur: 'SEK' },
        // Switzerland
        { tz: 'Europe/Zurich', cnt: 'CHE', cur: 'CHF' },
        // Taiwan
        { tz: 'Asia/Taipei', cnt: 'TWN', cur: 'TWD' },
        // Thailand
        { tz: 'Asia/Bangkok', cnt: 'THA', cur: 'THB' },
        // Turkey
        { tz: 'Asia/Istanbul', cnt: 'TUR', cur: 'TRY' },
        { tz: 'Europe/Istanbul', cnt: 'TUR', cur: 'TRY' },
        // Ukraine
        { tz: 'Europe/Kiev', cnt: 'UKR', cur: 'UAH' },
        { tz: 'Europe/Kyiv', cnt: 'UKR', cur: 'UAH' },
        // United Kingdom
        { tz: 'Europe/London', cnt: 'GBR', cur: 'GBP' },
        { tz: 'Europe/Belfast', cnt: 'GBR', cur: 'GBP' }
    ],
});


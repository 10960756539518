import React from 'react'
import { Outlet } from 'react-router-dom'
import theme from './../theme';
import { ThemeProvider, CssBaseline, Container, Box, Toolbar } from '@mui/material';
import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';

const PublicLayout = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <CssBaseline />
                <PublicHeader />
                <Box component="main" sx={{ml:2, mt:2, mr:0}}>
                    <Toolbar />
                    <Outlet />
                </Box>
                <PublicFooter />
            </Box>
        </ThemeProvider>
    )
}

export default PublicLayout
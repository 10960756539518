import { Dialog, DialogContent, DialogTitle, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AppContext } from '../../AppContext';

const CookiePolicyPage = (props) => {

  var { openModel, setOpenModel } = React.useContext(AppContext);

  return (
    <Dialog open={openModel.ckypolicy || false} fullWidth maxWidth="md" sx={{zIndex:10000}}>
      <DialogTitle sx={{ ml: 1, p: 2 }}>
        <Typography variant='h4' component={'div'}>Cookie Policy</Typography>
        <IconButton id="cookie-close-btn" onClick={()=>setOpenModel({})} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
            <CloseIcon />
        </IconButton>
        <Typography variant='body2'>Last updated: December 15, 2023</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={{ sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='body2'>This Cookie Policy explains how taragati.ai (website from "NiruzCorp Software Services LLP", India. "Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at https://taragati.ai ("Website"). 
              </Typography>
            </Grid>    

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>1. What are Cookies</strong></Typography>
              <Typography variant='body2'>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>2. How do we use Cookies?</strong></Typography>
              <Typography variant='body2'>As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data..</Typography>
              <br/>
              <Typography variant='body2'>The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</Typography>
              <br/>
              <Typography variant='body2'><strong>Log files:&nbsp;</strong>Our systems automatically gather some anonymous information about visitors, including IP addresses, browser type, language, and the times and dates of webpage visits. The data collected will include personally information and is used, as described in our <strong>privacy policy</strong>, for statistical analysis, to understand user behaviour, and to administer the site.</Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>3. Types of Cookies we use</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>  
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>3.1 Essential Cookies:</Typography>
              <Typography variant='body2'>These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as secure areas. These cookies do not store any personally identifiable data.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <TableContainer component={Paper} maxwidth={'90%'}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{backgroundColor: 'lightgray'}}>
                      <TableCell>Cookie</TableCell>
                      <TableCell align="left">Duration</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>taragati-consent</TableCell>
                      <TableCell align="left">1 year</TableCell>
                      <TableCell align="left">Taragati sets this cookie to remember users' consent preferences so that their preferences are respected on subsequent visits to this site. It does not collect or store any personal information about the site visitors.</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br/>
              <Typography variant='body2'><strong>Third-Party Cookies:&nbsp;</strong>We use third-party payment gateways like <strong>Stripe</strong> and <strong>PhonePe</strong>, you can learn more about their cookies by visiting those sites directly (given below)
                <li>Stripe Cookie Policy: <a href="https://stripe.com/in/cookie-settings" target='_blank' rel="noreferrer">https://stripe.com/in/cookie-settings</a></li>
                <li>PhonePe Cookie Policy: <a href="https://www.phonepe.com/privacy-policy" target='_blank' rel="noreferrer">https://www.phonepe.com/privacy-policy</a></li>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>3.2 Analytics Cookies:</Typography>
              <Typography variant='body2'>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <TableContainer component={Paper} maxwidth={'90%'}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{backgroundColor: 'lightgray'}}>
                      <TableCell>Cookie</TableCell>
                      <TableCell align="left">Duration</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>_ga</TableCell>
                      <TableCell align="left">1 year, 1 month & 4 days</TableCell>
                      <TableCell align="left">The _ga cookie, installed by Google Analytics, calculates visitor, session, campaign data, and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.
                      <br /><li>Further refer: <a href="https://policies.google.com/technologies/cookies" target='_blank' rel="noreferrer">https://policies.google.com/technologies/cookies</a></li>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>_ga_*</TableCell>
                      <TableCell align="left">1 year, 1 month & 4 days</TableCell>
                      <TableCell align="left">The _ga_* cookie, installed by Google Analytics, sets this cookie to store and count page views.
                      <br /><li>Further refer: <a href="https://policies.google.com/technologies/cookies" target='_blank' rel="noreferrer">https://policies.google.com/technologies/cookies</a></li>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>_gcl_au</TableCell>
                      <TableCell align="left">3 months</TableCell>
                      <TableCell align="left">The _gcl_au cookie, installed by Google Analytics, to experiment advertisement efficiency of websites using their services.
                      <br /><li>Further refer: <a href="https://policies.google.com/technologies/cookies" target='_blank' rel="noreferrer">https://policies.google.com/technologies/cookies</a></li>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>3.3 Advertisement Cookies:</Typography>
              <Typography variant='body2'>Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <TableContainer component={Paper} maxwidth={'90%'}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{backgroundColor: 'lightgray'}}>
                      <TableCell>Cookie</TableCell>
                      <TableCell align="left">Duration</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>IDE</TableCell>
                      <TableCell align="left">1 year & 24 days</TableCell>
                      <TableCell align="left">The IDE cookie, installed by Google Analytics, to store information about how the user uses the website to present them with relevant ads and according to the user profile.
                      <br /><li>Further refer: <a href="https://policies.google.com/technologies/ads" target='_blank' rel="noreferrer">https://policies.google.com/technologies/ads</a></li>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>ar_debug</TableCell>
                      <TableCell align="left">persistent</TableCell>
                      <TableCell align="left">The ar_debug cookie, installed by Google Analytics, to store and track conversions.
                      <br /><li>Further refer: <a href="https://policies.google.com/technologies/ads" target='_blank' rel="noreferrer">https://policies.google.com/technologies/ads</a></li>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>4. Manage Cookie Preferences</strong></Typography>
              <Typography variant='body2'>Different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. Listed below are the links to the support documents on how to manage and delete cookies from the major web browsers.</Typography>
              <Stack spacing={1} marginTop={1}>
                <Typography variant='body2'>Crome: <a href='https://support.google.com/accounts/answer/32050' target='_blank' rel="noreferrer">https://support.google.com/accounts/answer/32050</a></Typography>
                <Typography variant='body2'>Firefox: <a href='https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies' target='_blank' rel="noreferrer">https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies</a></Typography>
                <Typography variant='body2'>Microsoft Edge: <a href='https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09' target='_blank' rel="noreferrer">https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a></Typography>
                <Typography variant='body2'>Safari: <a href='https://support.apple.com/en-az/guide/safari/sfri11471/mac' target='_blank' rel="noreferrer">https://support.apple.com/en-az/guide/safari/sfri11471/mac</a></Typography>
              </Stack>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>5. General</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>5.1 How to Contact Us</Typography>
              <Typography variant='body2'>If you have any questions or concerns about this cookie policy or how we have handled your personal information, please contact us at <img alt="" src="/images/35n3b.png" height={20} style={{verticalAlign:'bottom'}} /></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>5.2 Changes to this Policy</Typography>
              <Typography variant='body2'>From time to time, we may need to update this cookie policy. We reserve the right to do so by updating this cookie policy which will be pointed to from our websites. The ‘last updated’ date at the top of this page will be modified to show that a change has been made.</Typography>
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CookiePolicyPage;

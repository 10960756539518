import { Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MUITextField from '../../components/widgets/fields/MUITextField';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { getEmailValidator, getNameValidator } from '../../services/validations';
import { styled } from '@mui/material/styles';
import constants from '../../util/constants';
import errors from '../../util/errors';
import MUISnackBar from '../../components/widgets/MUISnackBar';
import { useGetPurchaseItem } from '../../services/practiceexam-services';
import { clearSessionData, putSessionData } from '../../services/storage-services';
import { AppContext } from '../../AppContext';

const AttendeePage = () => {
  const navigate = useNavigate();
  const params= useParams()
  const [snackBar, setSnackBar] = React.useState({show:false});
  const { setPageMode } = React.useContext(AppContext);

  React.useEffect(() => {
    setPageMode('practice-exam-login')
  }, []); // Run this only once

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

  // Form Validations
  const practiceExamForm = Yup.object().shape({
    ln: getNameValidator(),
    eml: getEmailValidator(true)
  });
  const { handleSubmit:validatePracticeExamForm, control:controlPracticeExamForm } = useForm({mode:'onTouched', resolver: yupResolver(practiceExamForm)});   
  
  const {data:practiceExam, error:practiceExamError, trigger:triggerPracticeExam, isMutating:practiceExamMutating} = useGetPurchaseItem()
  React.useEffect(() => {
    if(practiceExam && practiceExam.sts){
      putSessionData(constants.SESSION_KEY_PRACTICE_EXAM, JSON.stringify({id:params.purchaseId, ...practiceExam}))
      navigate('/practice-exam/attempt')
    }else if(practiceExam?.errs && practiceExam?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:practiceExam.errs[0].msg})
      window.dataLayer.push({event:'error_event',event_category:'practice-exam',event_action:'error',event_label:'E201',event_value:errors.E201})
    }else if(practiceExamError){
      setSnackBar({show:true,severity:'error',message:errors.E102})
      window.dataLayer.push({event:'error_event',event_category:'practice-exam',event_action:'error',event_label:'E202',event_value:errors.E202})
    }
  }, [practiceExam]); // Run the effect whenever the data changes

  const handleExamSearch = async(formData) => {
    clearSessionData()
    const arg = {
      headers: {"x-purchaseid": params.purchaseId,"x-lastname": formData.ln,"x-email": formData.eml}
    }
    await triggerPracticeExam(arg)
  }
  
  return (
      <>
        <MUISnackBar id="search-exam-err" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }} paddingTop={5} marginBottom={10}>
          <Grid item xs={4} sm={4} md={5} sx={{display: { xs: "none", md: "block" }}}>
              <Img src="/images/brain.jpg"/>
          </Grid>
          <Grid item xs={4} sm={8} md={5} align="center">
            <Stack direction="column" spacing={2} width={0.6}>
              <Typography id="exam-search-title" variant="h4">Practice Exam</Typography>
              <div/>
              <MUITextField id="exam-search-ln" label="Last Name" name="ln" control={controlPracticeExamForm} maxLength={41} required/>
              <MUITextField id="exam-search-eml" label="Email" name="eml" control={controlPracticeExamForm} maxLength={81} required/>
              <MUIButton id="exam-search-btn" loading={practiceExamMutating} onClick={validatePracticeExamForm(handleExamSearch)} label="Search" variant="contained" type="secondary" />
              <div />
              <div />
              <Typography id="attendee-search-title" variant="body1">Enter last name and email you provided during the purchase.</Typography>
            </Stack>
          </Grid>
        </Grid>
      </>
  );
};

export default AttendeePage;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDisplayTime } from '../../../util/utility';
import { Link } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MUIResultsTable = (props) => {
    let rows = [];
    if(props.data && props.data.length > 0){
        props.data.forEach((value, index) => {
            // Copy the element along with its index to the new array
            rows.push({ index: index + 1, ...value });
        });
    }else if(props.data && props.data.id){
        rows.push({ index: 1, ...props.data });
    }

    return (
        <TableContainer id="test-result-table" component={Paper}>
            <Table size="small">
                {rows.length < 1 &&
                    <caption>No results to display, refresh again after sometime.</caption>
                }
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>S.N</StyledTableCell>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Full Name</StyledTableCell>
                        <StyledTableCell>Started</StyledTableCell>
                        <StyledTableCell>Ended</StyledTableCell>
                        <StyledTableCell align="right">Correct</StyledTableCell>
                        <StyledTableCell align="right">Wrong</StyledTableCell>
                        <StyledTableCell align="right">No Answer</StyledTableCell>
                        <StyledTableCell align="right">Score</StyledTableCell>
                        {props.onDeleteResponse && <StyledTableCell align="right">Action</StyledTableCell>}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell component="th" scope="row">{row.index}</StyledTableCell>
                            <StyledTableCell>{row.idn}</StyledTableCell>
                            <StyledTableCell>{row.fn}&nbsp;{row.ln}</StyledTableCell>
                            <StyledTableCell>{getDisplayTime(row.stm)}</StyledTableCell>
                            <StyledTableCell>{getDisplayTime(row.etm)}</StyledTableCell>
                            <StyledTableCell align="right">{row.rgt || '-' }</StyledTableCell>
                            <StyledTableCell align="right">{row.wng || '-' }</StyledTableCell>
                            <StyledTableCell align="right">{row.nas || '-' }</StyledTableCell>
                            <StyledTableCell align="right">{row.scr || '-' }</StyledTableCell>
                            {props.onDeleteResponse &&
                                <StyledTableCell align="right">
                                    <Link component="button" onClick={() => props.onDeleteResponse(row.id)}>Delete</Link>
                                </StyledTableCell>
                            }
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MUIResultsTable;
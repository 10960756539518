import * as React from 'react';
import Link from '@mui/material/Link';
import {Box, Typography, styled, IconButton } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from './../../../AppContext';
import { clearSessionData, getSessionData, putSessionData } from '../../../services/storage-services';
import { useNavigate } from 'react-router-dom';
import constants from '../../../util/constants';
import { getCountryInfo } from '../../../util/utility';

export default function PrivateHeader(props) {
    const { headerTitle } = React.useContext(AppContext);
    const navigate = useNavigate();

    // To determine the local user country for currency and timezone determination
    React.useEffect(() => {
      if(!getSessionData(constants.SESSION_KEY_COUNTRY)){
        putSessionData(constants.SESSION_KEY_COUNTRY, JSON.stringify(getCountryInfo(Intl.DateTimeFormat().resolvedOptions().timeZone)))
      }
    },[]);

    const rightLink = {
        fontSize: 16,
        color: 'common.white',
        ml: 3,
    };

    const Toolbar = styled(MuiToolbar)(({ theme }) => ({
        height: 64,
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
    }));

    const handleLink = (id) => {
      if(id === 'logout'){
        clearSessionData()
      }
      navigate('')
    }

    return (
      <MuiAppBar elevation={0} position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={props.toggle} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography id="institute-header-title" sx={{ ml: 30}} variant="h4">{headerTitle}</Typography>
          <Box sx={{display: { xs: 'none', sm: 'block' }}}>
            <Link key="1" id="header-logout-btn" color="inherit" variant="h6" underline="none" component="button" onClick={()=>handleLink("logout")} sx={rightLink}>
              {'Logout'}
            </Link>
          </Box>
        </Toolbar>
      </MuiAppBar>
    );
}
import useSWRMutation from 'swr/mutation'

import { getRequest, postRequest, putRequest } from '../util/utility'


/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useCreatePurchaseItem = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/attendee/practice-exam/purchase', postRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWRMutation, it will be invoked manual only as needed
*/
export const useGetPurchaseItem = (arg)  => {
  const { data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/attendee/practice-exam/purchase', getRequest)
  return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
  }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useCreateExamResponse = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/attendee/practice-exam/response', postRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}


/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useSubmitExamResponse = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/attendee/practice-exam/response/{responseId}', putRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWR Hook will avoid invoking the API if the path parameter eventId & responseId are null
*/
export const useGetExamEventResult = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/attendee/practice-exam/response/{responseId}/result', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}
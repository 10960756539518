import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Stack, Typography } from '@mui/material';


const Features = (props) => {

  return (
    <Container component="section" sx={{ mt: {sm:10} }}>
      <Stack direction={'column'} width={1} spacing={{sm:5}}>
        
        <Stack direction={'row'} width={1}>
          <Grid item xs={12} md={3} sx={{ zIndex: 1, display: { xs: "none", sm: "block" }}}>
            <Box sx={{display: 'flex',justifyContent: 'center', py: 2, px: 2}}>
              <Box component="img" onSubmit={()=>{}} sx={{ maxWidth: 400 }} src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"></Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ position: 'relative', display: { xs: "none", sm: "block" }}}>
            <Box sx={{position: 'absolute',top: -67,left: -67,right: 0,bottom: 0,width: '100%', background: 'url(/images/dots.png)'}} />
            <Box component="img" alt="call to action"
              src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
              sx={{
                position: 'absolute', top: -28, left: -28, right: 0, bottom: 0, width: '100%', maxWidth: 600,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} alignItems="center">
            <Typography component={'span'} fontSize={30} align="left"><ul>Practice Exams:</ul></Typography>
            <Typography fontSize={20} align="left" marginLeft={5} marginTop={-3}><li>Take at your convenience</li></Typography>
            <Typography fontSize={20} align="left" marginLeft={5}><li>Multiple attempts to practice</li></Typography>
            <Typography fontSize={20} align="left" marginLeft={5}><li>PDF reports with answers</li></Typography>
          </Grid>
        </Stack>  

        <Stack direction={'row'} width={1}>
          <Grid item xs={12} md={4} alignItems="center">
            <Typography component={'span'} fontSize={30} align="left"><ul>Institutes:</ul></Typography>
            <Typography fontSize={20} align="left" marginLeft={5} marginTop={-3}><li>Scheduled test events</li></Typography>
            <Typography fontSize={20} align="left" marginLeft={5}><li>Consolidated test reports</li></Typography>
            <Typography component={'span'} fontSize={30} align="left"><ul>Attendees:</ul></Typography>
            <Typography fontSize={20} align="left" marginLeft={5} marginTop={-3}><li>Take live tests</li></Typography>
            <Typography fontSize={20} align="left" marginLeft={5}><li>PDF reports with answers</li></Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ zIndex: 1, display: { xs: "none", sm: "block" }}}>
            <Box sx={{display: 'flex',justifyContent: 'center', py: 2, px: 2}}>
              <Box component="img" onSubmit={()=>{}} sx={{ maxWidth: 400 }} src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"></Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ position: 'relative', display: { xs: "none", sm: "block" }}}>
            <Box sx={{position: 'absolute',top: -67,left: -67,right: 0,bottom: 0,width: '100%', background: 'url(/images/dots.png)'}} />
            <Box component="img" alt="call to action"
              src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
              sx={{
                position: 'absolute', top: -28, left: -28, right: 0, bottom: 0, width: '100%', maxWidth: 600,
              }}
            />
          </Grid>
        </Stack>  

      </Stack>
      
    </Container>
  );
};

export default Features;

import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { Outlet } from 'react-router-dom';
import PrivateSidebar from './PrivateSidebar'
import PrivateHeader from './PrivateHeader';
import { ThemeProvider } from '@mui/material';
import theme from './../theme';
import PrivateFooter from './PrivateFooter';

const drawerWidth = 240;

const PrivateLayout = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  React.useEffect(function mount() {
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}>
            <CssBaseline />
            <PrivateHeader toggle={handleDrawerToggle} />
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                <Drawer variant={isMobile ? "temporary" : "permanent"}
                        open={isMobile ? mobileOpen : true}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: isMobile ? 'block' : 'none', sm: isMobile ? 'none' : 'block'},
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}>
                    <PrivateSidebar />
                </Drawer>
            </Box>
            <Box component="main" sx={{ml:2, flexGrow: 1, p: 3, width: { sm: 'calc(100% - ${drawerWidth}px)' } }}>
                <Toolbar />
                <Outlet />
                {/* <PrivateFooter /> */}
            </Box> 
        </Box>
    </ThemeProvider>
  );
}

export default PrivateLayout

import * as React from 'react';
import Link from '@mui/material/Link';
import { styled, Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { AppContext } from './../../../AppContext';
import { clearSessionData, getSessionData, putSessionData } from '../../../services/storage-services';
import { useNavigate } from 'react-router-dom';
import constants from '../../../util/constants';
import { getCountryInfo } from '../../../util/utility';

export default function PublicHeader(props) {
    const { pageMode } = React.useContext(AppContext);
    const navigate = useNavigate();

    // To determine the local user country for currency and timezone determination
    React.useEffect(() => {
      if(!getSessionData(constants.SESSION_KEY_COUNTRY)){
        putSessionData(constants.SESSION_KEY_COUNTRY, JSON.stringify(getCountryInfo(Intl.DateTimeFormat().resolvedOptions().timeZone)))
      }
    },[]);

    const rightLink = {
        fontSize: 16,
        color: 'common.white',
        ml: 3,
    };

    const Img = styled('img')({
      marginTop: 5,
      width: '60%',
      height: 'auto'
    });

    const Toolbar = styled(MuiToolbar)(({ theme }) => ({
        height: 64,
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
    }));

    const handleLink = (action) => {
      if(action === 'logout'){
        clearSessionData()
        navigate('/')
      }else if(action === 'home'){
        navigate('/')
      }else{
        navigate('/#'+action)
      }
    }

    return (
      <MuiAppBar elevation={0} position="fixed">
        <Toolbar>
          <Grid container columns={{ xs: 4, sm: 8, md: 10 }} alignItems="center">
            <Grid sx={{ flex: 1, display: 'flex'}} item xs={2} sm={2} md={2}>
              <Link id="header-logo" href="/">
                <Img src="/images/logo_full.png" />
              </Link>
            </Grid>
            <Grid sx={{flex: 1, display: {xs:'none', sm:'flex'}, justifyContent: 'flex-end'}} item xs={2} sm={6} md={8}>
              { pageMode === 'landing-site' && 
                <>
                  <Link key="1" color="inherit" variant="h6" underline="none" component="button" onClick={()=>handleLink("howitworks")} sx={rightLink}>
                    {'How It Works'}
                  </Link>
                  <Link key="2" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("features")}} sx={rightLink}>
                    {'Features'}
                  </Link>
                  <Link key="3" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("catalog")}} sx={rightLink}>
                    {'Practice Exams'}
                  </Link>
                  <Link key="4" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("contactus")}} sx={rightLink}>
                    {'Contact'}
                  </Link>
                </>
              }
              
              { (pageMode === 'practice-exam-login' || pageMode === 'attendee-login' || pageMode === 'institute-login') && 
                <>
                  <Link key="1" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("home")}} sx={rightLink}>
                    {'Home'}
                  </Link>
                  <Link key="2" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("contactus")}} sx={rightLink}>
                    {'Contact'}
                  </Link>
                </>
              }
              { (pageMode === 'practice-exam' || pageMode === 'attendee-exam') && 
                <>
                  <Link id="header-logout-btn" key="1" color="inherit" variant="h6" underline="none" component="button" onClick={()=>{handleLink("logout")}} sx={rightLink}>
                    {'Logout'}
                  </Link>
                </>
              }
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    );
}
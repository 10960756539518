import { Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AppContext } from '../../AppContext';

const TermsAndConditionsPage = (props) => {

  var { openModel, setOpenModel } = React.useContext(AppContext);

  return (
    <Dialog open={openModel.tandc || false} fullWidth maxWidth="md" sx={{zIndex:10000}}>
      <DialogTitle sx={{ ml: 1, p: 2 }}>
        <Typography variant='h4' component={'div'}>Terms and Conditions</Typography>
        <IconButton id="terms-close-btn" onClick={()=>setOpenModel({})} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
            <CloseIcon />
        </IconButton>
        <Typography variant='body2'>Last updated: December 15, 2023</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={{ sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='body2'>This Terms and Conditions explains what taragati.ai (website from "NiruzCorp Software Services LLP", India. "Company," "we," "us," and "our") establish what you can expect from us as you use our services, and what we expect from you when you visit our website at  https://taragati.ai ("website"). 
              </Typography>
            </Grid>    

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>1. Definitions</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='body2'>a. “Account Information” shall mean your information, including Personal Information, that we require in order for you to effectively use our services (as detailed below).</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='body2'>b. “Content” shall include, but not be limited to, the available practice exams, assessments content such as quiz, exams, survey, reports etc. that are provided to Authorized Users through website.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='body2'>c. “Personal Information” shall have the meaning assigned to this term under the IT (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, and includes any analogous meaning(s) provided under the applicable laws of the Users (for e.g., “Personal Data” defined under the European Union’s General Data Protection Regulation 2016/679).</Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>2. Terms and Conditions</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.1. Institute Accounts</Typography>
              <Typography variant='body2'>Institute need an account for conducting assessments on our platform, including subscription purchase and download reports. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account. If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a user, the account of that user will be closed.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.2 Institute Subscriptions</Typography>
              <Typography variant='body2'>During your subscription to a subscription plan, you get a limited, non-exclusive, non-transferable license from us to access and view / edit the content included in that subscription plan via the services. The subscription that you purchase or renew determines the scope, features, and price of your access to a subscription plan. You may not transfer, assign, or share your subscription with anyone else.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Once subscription plan is purchased, you will not be entitled to receive a refund or credit of any fees already paid for your subscription, unless otherwise required by applicable law.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Availability of free trial is solely at our discretion. Some of the subscription plans, or limited time offers, may start with a free trial. This free trial period may last for any period of time determined by us on its sole discretion as provided by the subscription plan/offer details. your eligibility for a free trial, including the time and access to any content during such free trial, is solely at our discretion. Further, such free trial(s) can be modified, suspended or terminated by us at any point of time without giving notice to you.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.3 Institute Attendee</Typography>
              <Typography variant='body2'>If you are a student (also called as "attendee"), the services enable you to take assessments that instructors created and invited you to submit. Don’t post or submit anything that is not yours. You must follow these basic rules while creating content like, but not limited to, answers to assessments</Typography>
              <Typography variant='body2'><li>Comply with applicable laws, including export control, sanctions, money laundering, and human trafficking laws.</li></Typography>
              <Typography variant='body2'><li>Respect the rights of others, including privacy and intellectual property rights.</li></Typography>
            </Grid>  
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.4 Practice Exams</Typography>
              <Typography variant='body2'>When you purchase practice exam, you must provide and continue to provide accurate and complete information, including a valid email address and names. you have complete responsibility for your practice exam and everything that happens on your purchase. This means you need to be careful with your purchase confirmation email, dont share with anyone else.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Once practice exam is purchased, you will not be entitled to receive a refund or credit of any fees already paid for your practice exam, unless otherwise required by applicable law.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Practice exams are valid for only limited period (example 30 days from the date of payment completed), also practice exam allow limited number of final submissions (example only 3 final submissions allowed). This validity period and number of submissions can vary per exam, please refer each exam details before the purchase.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Availability of free practice exam is solely at our discretion. This free practice exam period may last for any period of time determined by us on its sole discretion. Further, such free practice exam(s) can be modified, suspended or terminated by us at any point of time without giving notice to you.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.5. Pricing</Typography>
              <Typography variant='body2'>We occasionally run promotions and sales for our practice exams and subscriptions, during which certain products are available at discounted prices for a set period of time. The price applicable to the practice exams and/or subscription plans will be the price at the time you complete your purchase of the content (at checkout). Any price offered for particular content may also be different when you are logged into your account from the price available to users who aren’t registered or logged in, because some of our promotions are available only to new users.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.6. Payments</Typography>
              <Typography variant='body2'>When you make a payment, you agree to use a valid payment method, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the content you are enrolling in, you agree to pay us the corresponding fees within 10 days of notification from us. We reserve the right to disable access to any content for which we have not received adequate payment.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>You can reach out to Us for any issue related to payment processing on the website, and the same will be forwarded to the concerned third-party payment gateway provider for redressal.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.7. Refunds</Typography>
              <Typography variant='body2'>Once subscription plan and/or practice exam is purchased, you will not be entitled to receive a refund or credit of any fees already paid for your purchase, unless otherwise required by applicable law. Only in specific situations, case to case solely at our discretion, we will throughly review your refund request and process the refund if eligible.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>We reserve the right to apply your refund as a refund credit or a refund to your original payment method, at our discretion, depending on capabilities of our payment service providers, the platform from which you purchased your subscription or practice exam.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.8. Your content</Typography>
              <Typography variant='body2'>You must follow these basic rules while creating content (like assessment questions, profile information etc):</Typography>
              <Typography variant='body2'><li>Comply with applicable laws, including export control, sanctions, money laundering, and human trafficking laws.</li></Typography>
              <Typography variant='body2'><li>Respect the rights of others, including privacy and intellectual property rights.</li></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.9. Website usage</Typography>
              <Typography variant='body2'>Don’t abuse, harm, interfere with, or disrupt the services — for example, by accessing or using them in fraudulent or deceptive ways, introducing malware, or spamming, hacking, or bypassing our systems or protective measures.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>2.10. Third-Party Links and Advertisements</Typography>
              <Typography variant='body2'>The Website may contain links to third-party links & advertisements (“External Links”) that you may access and/or use at your sole discretion. You acknowledge and agree that such External Links are not under our control, and therefore we shall not be responsible, in any manner, for any transactions related to External Links. You must note that We provide access to these External Links only as a convenience to you, and We do not review, approve, monitor, endorse, warrant, or make any representations with respect to External Links, in any manner whatsoever. You shall use all External Links at your own risk and should exercise a suitable level of caution and discretion in doing so. When you click on any of the External Links, the applicable third party’s Terms and policies shall apply to you, including the third party’s privacy and data gathering practices. You should independently undertake whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such External Links. By using the services, you acknowledge and agree that we are not responsible or liable to you for any content or other materials hosted and served from any website or destination other than the website. Further, we take no responsibility for the External Links posted on the website nor does it take any responsibility for the products or services provided by such advertisers and other third parties.</Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>3. Term and Termination</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>3.1 Agreement</Typography>
              <Typography variant='body2'></Typography>
              <Typography variant='body2'>We may suspend or terminate your rights to use the website (including your Account) at any time and for any reason, at our sole discretion, including for any use of the website and/or its Services in violation of these Terms.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>Upon termination of your rights under these Terms, your account and right to access and use the website and/or its services, will terminate immediately. You understand that any termination of your account may involve deletion of the Account Information and any other Information (like assessments, reports etc) associated with your account from all our databases. We will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account.</Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>4. Warranties and Liability</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>4.1 Disclaimer</Typography>
              <Typography variant='body2'>YOU AGREE THAT YOUR USE OF THE WEBSITE SHALL BE AT YOUR OWN RISK (TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW), AND FOR THE SAME, WE DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND MAKE NO WARRANTIES, REPRESENTATIONS OR GUARANTEES IN CONNECTION WITH THIS WEBSITE, THE SERVICES OFFERED ON OR THROUGH THIS WEBSITE, ALL SUBMITTED CONTENT ON, AND IN RELATION TO, THIS WEBSITE AND/OR SERVICES. UNLESS OTHERWISE STATED, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. ANY AND ALL DATA, MATERIAL, INFORMATION, SUBMITTED CONTENT AND/OR SERVICES CONTAINED OR PRESENTED ON THIS WEBSITE IS PROVIDED TO YOU ON AN “AS-IS”, “AS AVAILABLE” AND BASIS WITH NO WARRANTY OF IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COMPLETENESS, AVAILABILITY, SECURITY, COMPATIBILITY; OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. WE DO NOT ENDORSE, WARRANT, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A SERVICE PROVIDERS OR ANY OTHER THIRD PARTY THROUGH THE WEBSITE, OR ANY HYPERLINKED SITE, OR OTHER ADVERTISEMENT. WE MAKE NO WARRANTY THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, LEGAL, SAFE, OR FREE OF VIRUSES OR ANY OTHER HARMFUL CODE. YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE NECESSARY.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>4.2 Liability</Typography>
              <Typography variant='body2'></Typography>
              <Typography variant='body2'>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WE BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, LOST PROFITS OR LOST/STOLEN DATA ARISING FROM, OR RELATING TO, THE TERMS; OR YOUR USE OF, OR INABILITY TO USE, THE WEBSITE AND/OR SUBMITTED CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANY ACCESS TO, AND USE OF, THE WEBSITE AND THE CONTENT IS AT YOUR SOLE DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU OR YOUR PROPERTY, INCLUDING YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF ANY DATA, INCLUDING ACCOUNT INFORMATION, RESULTING THEREFROM. THE FOREGOING LIMILITATION OF LIABILITY SHALL BE TO THE FULLEST EXTENT PERMITTED BY LAW IN THE EXTENT.</Typography>
            </Grid>


            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h5'><strong>5. General</strong></Typography>
              <Typography variant='body2'></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>5.1 Communication</Typography>
              <Typography variant='body2'></Typography>
              <Typography variant='body2'>You give consent to us, to receive communications from us in an electronic form; and agreee that all Terms and Conditions, agreements, notices, and other communications that we provide to you.</Typography>
              <Typography variant='body2'>&nbsp;</Typography>
              <Typography variant='body2'>You give consent to us, to contact you using your contact information at any time during your association with us or later for any notifications, marketing purpose, includes phone, emails, text messages and social media apps like WhatsApp.</Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>5.2 How to Contact Us</Typography>
              <Typography variant='body2'>If you have any questions or concerns about this terms and conditions or how we have handled your personal information, please contact us at <img alt="" src="/images/35n3b.png" height={20} style={{verticalAlign:'bottom'}} /></Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Typography variant='h6'>5.3 Changes to this Terms</Typography>
              <Typography variant='body2'>From time to time, we may need to update this terms and conditions. We reserve the right to do so by updating this terms and conditions which will be pointed to from our websites. The ‘last updated’ date at the top of this page will be modified to show that a change has been made.</Typography>
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditionsPage;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import ExamsCatalog from '../components/widgets/landing/ExamsCatalog';
import { styled } from '@mui/material/styles';
import HowItWorks from '../components/widgets/landing/HowItWorks';
import PaymentResults from '../components/widgets/landing/PaymentResults';
import { AppContext } from '../AppContext';
import ContactUs from '../components/widgets/landing/ContactUs';
import Features from '../components/widgets/landing/Features';

const LandingPage = () => {
  const navigate = useNavigate();
  var { setPageMode } = React.useContext(AppContext);
  var { setOpenModel } = React.useContext(AppContext);

  var [mode, setMode] = React.useState('');

  React.useEffect(() => {
    setPageMode('landing-site')
    if(window.location){
        if(window.location.pathname.includes('practice-exam/purchase/success')){
          setMode('purchase-success')   
        }else if(window.location.pathname.includes('practice-exam/purchase/cancel')){
          setMode('purchase-cancel')   
        }else if(window.location.hash.includes('#')){
          handleLink(window.location.hash)
        }
    }
  }, [window.location.pathname, window.location.hash]); // Run this when window.location changes

  const handleLink = (title) => {
    if(title.includes('#terms-and-conditions')){
      setOpenModel({tandc:true})
    }else if(title.includes('#privacy-policy')){
      setOpenModel({pcypolicy:true})
    } else if(title.includes('#cookie-policy')){
      setOpenModel({ckypolicy:true})
    }else if(title.includes('#')){
      let link = document.getElementById(title);
      link && link.scrollIntoView({ behavior: "smooth", block: "start" });
    }else{
      navigate('/'+title)
    }
  }

  const Img = styled('img')({
    margin: 'auto',
    paddingRight: 20,
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  return (
    <Grid container alignItems="center" spacing={{ sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
      {(mode === 'purchase-success' || mode === 'purchase-cancel') &&
        <PaymentResults result={mode}/>
      }
      
      <Stack direction="row" spacing={{ xs: 2, md: 2 }} sx={{ width: 1 }} marginTop={{md: 10}} marginBottom={{md: 10, xs: 5}}>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Grid item xs={4} sm={8} md={5}>
              <Container maxWidth="sm">
                <Typography id="banner-title-1" variant="h2" align="center" color="text.primary" gutterBottom>
                  Assess Tech Skills
                </Typography>

                <Typography component="h1" variant="h5" align="center" color="text.primary" gutterBottom>
                  We believe assessments are critical for effective learning. That's why we created an assessment platform that tests knowledge and skills better than anything else.
                </Typography>

                <Typography component="h1" variant="h5" align="center" marginTop={4}>
                  Looking for online AWS practice exam to complete your certification preparation plan?
                </Typography>
                <Stack sx={{ pt: 2 }} direction="row" spacing={2} justifyContent="center">
                  <Button id="main-catalog-btn" variant="contained" color="secondary" onClick={()=>{handleLink("#catalog")}}>Practice Exams</Button>
                </Stack>

                <Typography component="h1" variant="h5" align="center" marginTop={4}>
                    Best in class experience for both Attendees and Institutions to conduct online Assessment in effective way!
                </Typography>
                <Stack sx={{ pt: 2 }} direction="row" spacing={2} justifyContent="center">
                  <Button id="main-institue-btn" variant="outlined" color="info" onClick={()=>{handleLink("institute")}}>Conduct Test</Button>
                  <Button id="main-attendee-btn" variant="outlined" color="info" onClick={()=>{handleLink("attendee")}}>Take Test</Button>
                </Stack>
              </Container>
            </Grid>
            <Grid item xs={4} sm={8} md={5} sx={{display: { xs: "none", md: "block" }}}>
              <Img src="/images/banner.png" />
            </Grid>
        </Grid>
      </Stack>

      <div id="#howitworks"></div>
      <Box component="section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'info.light', width: 1}}>
          <Container component="section" sx={{ mt: 5}}>
            <Typography component="div" variant="h3" marked="center" align="center">
              How It Works
            </Typography>
            <Divider />
          </Container>
          <Grid item xs={4} sm={8} md={10} marginTop={10} marginBottom={10} width={1}>
            <HowItWorks gotoCatalog={()=>{handleLink('#catalog')}}/>
          </Grid>
      </Box>

      <div id="#features"></div>
      <Box component="section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 1}}>
          <Container component="section" sx={{ mt: 5}}>
            <Typography component="div" variant="h3"  marked="center" align="center">
              Explore Features
            </Typography>
            <Divider />
          </Container>
          <Grid item xs={4} sm={8} md={10} marginTop={5} marginBottom={5} width={1}>
            <Features />
          </Grid>
      </Box>

      <Box id="#catalog" component="section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'info.light', width: 1}}>
        <Container component="section" sx={{ mt: 5}}>
            <Typography component="div" variant="h3" marked="center" align="center">
              Certification Practice Exams
            </Typography>
            <Divider />
            <br/>
            <Typography variant="body1" color="text.secondary" align="center">We optimized our practice exams short and sweet! Taking long hour practice exams is tiresome, so we made our practice exam not more than 1 hour.</Typography>
        </Container>
        <Grid item xs={4} sm={8} md={10} marginTop={4} marginBottom={5} width={0.9}>
          <ExamsCatalog />
        </Grid>
      </Box>

      <div id="#contactus"></div>
      <Container component="section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}>
        <Button sx={{ border: '4px solid currentColor', borderRadius: 0, height: 'auto', py: 2, px: 5}}>
          <Typography variant="h4" component="span">
            Got any questions? Need help?
          </Typography>
        </Button>
        <Typography variant="subtitle1" sx={{ my: 3 }}>Refer below FAQs or use contact methods.</Typography>
        <ContactUs />
      </Container>
      
    </Grid>
  );
};

export default LandingPage;

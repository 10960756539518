import React from 'react';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { useGetTestEvent, useCreateTestResponse, useSubmitTestResponse } from '../../services/attendee-services';
import { Alert, Divider, FormControl, Grid, Skeleton, Stack, Typography } from '@mui/material';
import MUITextField from '../../components/widgets/fields/MUITextField';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import alerts from '../../util/alerts';
import errors from '../../util/errors';
import TestResponseModel from '../../models/v1/testresponse';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNameValidator, getMobileValidator, getEmailValidator, getIdentityNumberValidator } from '../../services/validations';
import { getSessionData, putSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import MUISnackBar from '../../components/widgets/MUISnackBar';
import { getDisplayDate, getDisplayTime, getStatusValue, getTestTypeValue } from '../../util/utility';
import { AppContext } from '../../AppContext';
import LiveQuestions from '../../components/widgets/LiveQuestions';

const TestPage = () => {

  const [alertMessage, setAlertMessage] = React.useState();
  const [alertSeverity, setAlertSeverity] = React.useState();
  const [snackBar, setSnackBar] = React.useState({show:false});

  var { setPageMode } = React.useContext(AppContext);
  React.useEffect(() => {
    setPageMode('attendee-exam')
  }, []);

  const determineTab = (testEvent) => {
    if(!testEvent || testEvent.errs){
      setTabId(0)
      setAlertSeverity("error")
      setAlertMessage(testEvent.errs[0].msg)
    }else if(testEvent.sts && testEvent.sts === constants.STATUS_CODE_SCHEDULED){
      setTabId(0)
      setAlertSeverity("info")
      setAlertMessage(alerts.A101)
    }else if(testEvent.sts && testEvent.sts === constants.STATUS_CODE_INPROGRESS){
      setTabId(0)
    }else if(testEvent.sts && testEvent.sts === constants.STATUS_CODE_COMPLETED){
      setTabId(0)
      setAlertSeverity("warning")
      setAlertMessage(alerts.A102)
    }else if(testEvent.sts && testEvent.sts === constants.STATUS_CODE_CANCELLED){
      setTabId(0)
      setAlertSeverity("warning")
      setAlertMessage(alerts.A103)
    }
  }

  // Form Validations
  const profileSchema = Yup.object().shape({
    fn: getNameValidator(),
    ln: getNameValidator(),
    idn: getIdentityNumberValidator(),
    mob: getMobileValidator(),
    eml: getEmailValidator()
  });
  const { handleSubmit:validateProfileForm, control:controlProfileForm } = useForm({mode:'onTouched', resolver: yupResolver(profileSchema)});   

  // Form data states
  const [testEvent, setTestEvent] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_TEST_EVENT)));
  React.useEffect(() => {
    if(testEvent && testEvent.sts){
      determineTab(testEvent);
    }
  }, [testEvent]); // Run the effect whenever the data changes

  const [tabId, setTabId] = React.useState(0);
  const [questionNumber, setQuestionNumber] = React.useState(1);
  const [testResponse, setTestResponse] = React.useState(new TestResponseModel());
  const navigate = useNavigate();

  const {data:testEventData, error:testEvenDataError, trigger:triggerTestEvent, isMutating:testEventMutating} = useGetTestEvent()
  React.useEffect(() => {
    if(testEventData && testEventData.sts){
      setTestEvent(testEventData)
      determineTab(testEventData);
      putSessionData(constants.SESSION_KEY_TEST_EVENT, JSON.stringify(testEventData))
    }else if(testEventData?.errs && testEventData?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:testEventData.errs[0].msg})
    }else if(testEvenDataError){
      setSnackBar({show:true,severity:'error',message:errors.E102})
    }
  }, [testEventData]); // Run the effect whenever the data changes
  const handleRefresh = async () => {
    const arg = { 
      pathParams: {eventId: testEvent?.id}
    }
    await triggerTestEvent(arg)
  }

  const handleTestStart = () => {
    setQuestionNumber(1)
    setTabId(1)
  };

  // This will be invoked as needed
  const {data:createTestResponse, error:createTestResponseError, trigger:triggerCreateTestResponse, isMutating:createTestResponseMutating} = useCreateTestResponse()
  React.useEffect(() => {
    if(createTestResponse && createTestResponse.id){
      testResponse.id = createTestResponse.id
      testResponse.stm = createTestResponse.stm
      putSessionData(constants.SESSION_KEY_TEST_RESPONSE, JSON.stringify(testResponse))
      setTabId(2)
      setSnackBar({show:true,severity:'success',message:alerts.A104})
    }else if(createTestResponse?.errs && createTestResponse?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:createTestResponse.errs[0].msg})
    }else if(createTestResponseError){
      setSnackBar({show:true,severity:'error',message:errors.E103})
    }
  }, [createTestResponse]); // Run the effect whenever the data changes

  const handleTestResponseCreate = async(formData) => {
    const arg = { 
      pathParams: { eventId: testEvent?.id },
      requestBody: formData
    }
    setTestResponse(formData)
    await triggerCreateTestResponse(arg)
  };

  // This will be invoked as needed
  const {data:submitTestResponse, error:submitTestResponseError, trigger:triggerSubmitTestResponse, isMutating:submitTestResponseMutating} = useSubmitTestResponse()
  const handleTestResponseSubmit = async() => {
    const arg = { 
      pathParams: { eventId: testEvent?.id, responseId: testResponse.id },
      eventId: testEvent?.id,
      requestBody: testResponse
    }
    await triggerSubmitTestResponse(arg)
  };
  React.useEffect(() => {
    if(submitTestResponse && submitTestResponse.id){
      testResponse.etm = submitTestResponse.etm
      putSessionData(constants.SESSION_KEY_TEST_RESPONSE, JSON.stringify(testResponse))
      setSnackBar({show:true,severity:'success',message:alerts.A105})
      navigate('/attendee/test/event/result')
    }else if(submitTestResponse?.errs && submitTestResponse?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:submitTestResponse.errs[0].msg})
    }else if(createTestResponseError){
      setSnackBar({show:true,severity:'error',message:errors.E103})
    }
  }, [submitTestResponse]); // Run the effect whenever the data changes
  
  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
      <MUISnackBar id="attendee-testevent-bar" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
      <Stack direction="column" spacing={1} sx={{ width: 1 }}>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
          <Grid item xs={4} sm={8} md={10}>
          </Grid>
          <Grid item xs={4} sm={8} md={10} marginTop={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='h5'><strong>Title:</strong></Typography>
                <Typography variant='h5'>{testEventMutating ? <Skeleton width={400}/> : testEvent?.ttl}</Typography>
                {testEvent?.sts === constants.STATUS_CODE_SCHEDULED ? <MUIButton loading={testEventMutating} onClick={handleRefresh} label="Refresh" variant="outlined" type="info"/> : ''}
              </Stack>
          </Grid>
          <Grid item xs={4} sm={8} md={10}>
              <Divider />
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Location:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={20}/> : testEvent?.loc}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Type:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={200}/> : getTestTypeValue(testEvent?.typ) }</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Status:</strong></Typography>
                <Typography id="testevent-status-txt" variant='body1'>{testEventMutating ? <Skeleton width={200}/> : getStatusValue(testEvent?.sts)}</Typography>
              </Stack>
          </Grid>

          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Scheduled Date:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={20}/> : getDisplayDate(testEvent?.sdt)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Test Started:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={40}/> : getDisplayTime(testEvent?.stm)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Test Ended:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={40}/> : getDisplayTime(testEvent?.etm)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={10}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Total Questions:</strong></Typography>
                <Typography variant='body1'>{testEventMutating ? <Skeleton width={20}/> : testEvent?.qcnt}</Typography>
              </Stack>
          </Grid>
   
          {tabId === 0 &&
            <Grid item xs={4} sm={8} md={10} marginTop={4}>
              {testEvent?.sts && testEvent.sts === 'IPG' ? (
                  <MUIButton id="testevent-take-btn" onClick={handleTestStart} label="Take Test" type="secondary"/>
                ) : (
                  <Stack direction="row" spacing={2}>
                    {testEventMutating ? '' : <Alert severity={alertSeverity}>{alertMessage}</Alert>}
                  </Stack>
              )}
            </Grid>
          }
          
          {tabId === 1 &&
            <FormControl sx={{marginLeft:2}}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>    
                <Grid item xs={4} sm={8} md={10} marginTop={4} marginBottom={2}>
                  <Typography variant='h5'>Your Profile</Typography>
                  <Divider />
                </Grid> 
                <Grid item xs={2} sm={4} md={3}>
                    <MUITextField id="attendee-fn" name="fn" label="First Name" control={controlProfileForm} required maxLength={41}/>
                </Grid> 
                <Grid item xs={2} sm={4} md={3}>
                    <MUITextField id="attendee-ln" name="ln" label="Last Name" control={controlProfileForm} required maxLength={41}/>
                </Grid>
                <Grid item xs={2} sm={4} md={2}>
                    <MUITextField id="attendee-idn" name="idn" label="Identity Number" control={controlProfileForm} maxLength={9}/>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <MUITextField id="attendee-mob" name="mob" label="Mobile" control={controlProfileForm} maxLength={11}/>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <MUITextField id="attendee-eml" name="eml" label="Email" control={controlProfileForm} required maxLength={81}/>
                </Grid>
                <Grid item xs={2} sm={4} md={10}>
                    <MUIButton id="attendee-profile-save" loading={createTestResponseMutating} onClick={validateProfileForm(handleTestResponseCreate)} label="Save" type="secondary"/>
                </Grid>
              </Grid>
            </FormControl>
          }

          {tabId === 2 &&
            <Stack direction={'column'} marginLeft={1} marginRight={5}>
                <LiveQuestions exam={testEvent.exam} questions={testEvent.qtns} response={testResponse} 
                  onFinalSubmit={()=>{handleTestResponseSubmit()}} />
            </Stack>
          }

          {tabId === 3 &&
              <>
                <Grid item xs={4} sm={8} md={10} marginTop={3} marginBottom={1}>
                  <Typography variant='h5'>Review Your Response</Typography>
                  <Divider />
                </Grid> 
                <Grid item xs={4} sm={2} md={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Full Name:</strong></Typography>
                    <Typography variant='body1'>{testResponse.fn}&nbsp;{testResponse.ln}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} sm={6} md={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Identity Number:</strong></Typography>
                    <Typography variant='body1'>{testResponse.idn}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Mobile:</strong></Typography>
                    <Typography variant='body1'>{testResponse.mob}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} sm={6} md={4}  marginBottom={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant='body1'><strong>Email:</strong></Typography>
                    <Typography variant='body1'>{testResponse.eml}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} sm={8} md={10}>
                  <Typography><strong>Responses</strong></Typography>
                  <Divider />
                </Grid>
                <Grid item xs={4} sm={8} md={10}>
                  <Typography>All questions answered.</Typography>
                  {/* <MUIReviewTable data={testResponse} /> */}
                </Grid>
                <Grid marginTop={2} item xs={4} sm={4} md={10}>
                  <MUIButton id="attendee-submit-test-btn" loading={submitTestResponseMutating} onClick={handleTestResponseSubmit} label="Submit" type="secondary"/>
                </Grid>
              </>
          }
        </Grid>
      </Stack>
    </Grid>
  );
};

export default TestPage;

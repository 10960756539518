import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Stack, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import TermsAndConditionsPage from '../../../pages/legal/TermsAndConditionsPage';
import PrivacyPolicyPage from '../../../pages/legal/PrivacyPolicyPage';
import CookiePolicyPage from '../../../pages/legal/CookiePolicyPage';
import Consent from '../Consent';
import { AppContext } from '../../../AppContext';
import { copyrightText, disclaimerText } from '../../../util/utility';

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const Img = styled('img')({
  marginTop: 0,
  width: '70%',
  height: 'auto'
});

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  }
];

export default function PublicFooter() {
  var { setOpenModel } = React.useContext(AppContext);

  const handleLink = (action) => {
    if(action === 'terms-and-conditions'){
      setOpenModel({tandc:true})
      window.dataLayer.push({event:'page_view',event_category:'footer',event_action:'click',event_label:'terms-and-conditions'})
    }else if(action === 'privacy-policy'){
      setOpenModel({pcypolicy:true})
      window.dataLayer.push({event:'page_view',event_category:'footer',event_action:'click',event_label:'privacy-policy'})
    }else if(action === 'cookie-policy'){
      setOpenModel({ckypolicy:true})
      window.dataLayer.push({event:'page_view',event_category:'footer',event_action:'click',event_label:'cookie-policy'})
    }
  }

  return (
    <>
      <Typography component="footer" sx={{marginTop:'calc(1% + 10px)', display: 'flex', bgcolor: 'warning.light' }} >
        <Stack direction={'column'} spacing={5} sx={{ marginTop:8, marginBottom:2}}>
          <Container sx={{display: 'flex'}}>
            <Grid container spacing={{xs:4, md:10}}>
              <Grid item xs={6} sm={4} md={3} sx={{display: { xs: "none", md: "block" }}}>
                <Link href="/">
                  <Img src="/images/logo_full_invert.png" />
                </Link>
                {/* <Grid container direction="column" justifyContent="flex-end" spacing={2} sx={{ height: 120 }}>
                  <Grid item sx={{ display: 'flex' }}>
                    <Box component="a" href="https://mui.com/" sx={iconStyle}>
                      <img src="/static/themes/onepirate/appFooterFacebook.png" alt="Facebook" />
                    </Box>
                    <Box component="a" href="https://twitter.com/MUI_hq" sx={iconStyle}>
                      <img src="/static/themes/onepirate/appFooterTwitter.png" alt="Twitter" />
                    </Box>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="h6" marked="left" gutterBottom>Links</Typography>
                <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-practice-exams" href="/#catalog">Practice Exams</Link>
                  </Box>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-conduct-test" href="/institute">Conduct Test</Link>
                  </Box>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-take-test" href="/attendee">Take Test</Link>
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item xs={3} sm={4} md={2}>
                <Typography variant="h6" marked="left" gutterBottom>Links</Typography>
                <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link href="/about-us">About Us</Link>
                  </Box>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link href="/resources">Resources</Link>
                  </Box>
                </Box>
              </Grid> */}
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="h6" marked="left" gutterBottom>Legal</Typography>
                <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-terms-and-conditions" component="button" onClick={()=>handleLink("terms-and-conditions")}>Terms and Conditions</Link>
                  </Box>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-privacy-policy" component="button" onClick={()=>handleLink("privacy-policy")}>Privacy Policy</Link>
                  </Box>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <Link id="footer-cookie-policy" component="button" onClick={()=>handleLink("cookie-policy")}>Cookie Policy</Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="h6" marked="left" gutterBottom>Language</Typography>
                <TextField select size="medium" variant="standard" SelectProps={{native: true}} sx={{ mt: 1, width: 100 }}>
                  {LANGUAGES.map((language) => (
                    <option value={language.code} key={language.code}>
                      {language.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Container>

          
          <Grid container spacing={1} marginLeft={2}> 
            <Grid item xs={10} sm={12} md={12}>
              <Stack direction={{xs:'column',sm:'row'}} spacing={1}>
                <Typography variant='body2'>{copyrightText()}</Typography>
                <Typography variant="caption">Build:{process.env.REACT_APP_VERSION}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={10} sm={12} md={12}>
              <Typography variant="body2">{disclaimerText()}</Typography>
            </Grid>
          </Grid>

        </Stack>
      </Typography>
      <TermsAndConditionsPage />
      <PrivacyPolicyPage />
      <CookiePolicyPage />
      <Consent />
    </>
  );
}
import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { areArraysMatchingUnordered, formatQuestionText } from '../../../util/utility';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function MUIAnswersAccordion(props) {
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    }

    let rows = [];
    if(props.questions && props.questions.length > 0 && props.result && !props.result.errs){
        props.questions.forEach((question) => {
            // find corresponding answer in the result obj
            const response = props.result.res.filter(obj => obj.qid === question.id);
            const answer = props.result.key.filter(obj => obj.qid === question.id);
            const correct = areArraysMatchingUnordered(response[0].ans, answer[0].ans);
            // Copy the elements
            rows.push({ ...question, response: response[0].ans, answer: answer[0].ans, correct: correct});
        });
    }else if(props.questions && props.questions.length > 0){
        props.questions.forEach((question) => {
            if(question.ans){
              // Copy the elements
              rows.push({ ...question, answer: question.ans});
            }
        });
    }
  
    const getAnswerOptionColor = (row,index) => {
      if(row.typ === 'SCQ'){
        if(row.opts[index].id === row.answer[0]){
          return 'green'
        }
      }else if(row.typ === 'MCQ'){
        if(row.answer.indexOf(row.opts[index].id) !== -1){
          return 'green'
        }
      }
      return ''
    }

    const getQuestionResultText = (row) => {
      if(row.correct){
        return 'Correct'
      }else{
        return 'Wrong (you selected '+row.response.join(', ')+')'
      }
    }

    const formatQuestion = (id, txt) => {
        var stack = document.getElementById("question-txt-"+id)
        if(stack){
          stack.innerHTML = ''
        }
        formatQuestionText(txt).forEach((element, index) => {
            var tag
            if(element.includes('//images')){
                tag = document.createElement('img');
                tag.setAttribute('src',process.env.REACT_APP_BASEURL+'/images/'+element.split('//images/')[1])
                tag.setAttribute('style','max-width:500px;width:100%;height:auto')
            }else if(element.includes('<pre>') && stack){
                stack.insertAdjacentHTML('beforeend',element)
            }else{
                tag = document.createElement('span');
                tag.innerText = element;
            }
            if(stack){
              stack.append(tag)
            }
        })
    }

    return (
      <div>
        {rows.map((row) => (
          <Accordion expanded={props.showAll || expanded === 'question-'+row.id} onChange={handleChange('question-'+row.id)} key={row.id}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <Typography>Question {row.id}</Typography>
                  {row.correct!=undefined && 
                    <Typography style={{ color: row.correct ? 'green' : 'red' }}>{getQuestionResultText(row)}</Typography>
                  }
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={10} sm={10} md={10} marginBottom={3}>
                <Stack direction={'column'} id={"question-txt-"+row.id} spacing={2}>
                  {formatQuestion(row.id, row.txt)}
                </Stack>
              </Grid>
              <Grid item xs={10} sm={10} md={10} >
                {row.typ === 'SCQ' && 
                  <RadioGroup name="ans" id="ans" key={row.id}>
                    {Array.from(Array(row.opts.length)).map((_, index) => (
                        <FormControlLabel style={{color: getAnswerOptionColor(row,index)}}
                                          value={row.opts[index].id}
                                          checked={row.opts[index].id === row.answer[0]}
                                          control={<Radio />} key={row.id+'-'+index}
                                          label={row.opts[index].id +'. '+row.opts[index].txt}/>
                    ))}
                  </RadioGroup>
                }
                {row.typ === 'MCQ' && 
                  <FormGroup>
                    {Array.from(Array(row.opts.length)).map((_, index) => (
                        <FormControlLabel style={{color: getAnswerOptionColor(row,index)}}
                                          value={row.opts[index].id} 
                                          checked={row.answer.indexOf(row.opts[index].id) !== -1}
                                          control={<Checkbox />} key={row.id+'-'+index}
                                          label={row.opts[index].id +'. '+row.opts[index].txt} />
                    ))}
                  </FormGroup>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
import React from 'react';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { useGetTestEventResult } from '../../services/attendee-services';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { getSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import MUIResultsTable from '../../components/widgets/tables/MUIResultsTable';
import MUIAnswersAccordion from '../../components/widgets/accordion/MUIAnswersAccordion';
import PDFDownload from '../../components/widgets/PDFDownload';
import { getDisplayTime, getStatusValue, getTestTypeValue } from '../../util/utility';
import alerts from '../../util/alerts';
import errors from '../../util/errors';

const TestResultPage = () => {
  
  const [testEvent, setTestEvent] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_TEST_EVENT)));
  const [testResponse, setTestResponse] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_TEST_RESPONSE)));
  const [showAllAccordions, setShowAllAccordions] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({show:false});

  const {data:testEventResult, error:testEventResultError, 
    trigger:triggerGetTestEventResult, isMutating:testEventResultMutating } = useGetTestEventResult()

  const handleRefreshResult = async () => {
    const arg = { 
      pathParams: { eventId: testEvent?.id, responseId: testResponse.id }
    }
    await triggerGetTestEventResult(arg)
  }
  React.useEffect(() => {
    if(testEventResult && testEventResult.id){
      setSnackBar({show:true,severity:'success',message:alerts.A105})
    }else if(testEventResult?.errs && testEventResult?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:testEventResult.errs[0].msg})
    }else if(testEventResultError){
      setSnackBar({show:true,severity:'error',message:errors.E103})
    }
  }, [testEventResult]); // Run the effect whenever the data changes

  return (
    <Grid id="attendee-test-result-page" container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
      <Stack direction="column" spacing={1} sx={{ width: 1 }}>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
          <Grid item xs={4} sm={8} md={10}>
          </Grid>
          <Grid item xs={4} sm={8} md={10} marginTop={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='h5'><strong>Title:</strong></Typography>
                <Typography variant='h5'>{testEvent?.ttl}</Typography>
                {testEventResult?.id ? <PDFDownload id='attendee-test-result-page' execute={(open) => setShowAllAccordions(open)} fileName={testResponse.fn +'-'+testResponse.ln+'-Test-Results-'+testEvent?.id+'.pdf'} /> : ''}
              </Stack>
          </Grid>
          <Grid item xs={4} sm={8} md={10}>
              <Divider />
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Event Id:</strong></Typography>
                <Typography variant='body1'>{testEvent?.id}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Type:</strong></Typography>
                <Typography variant='body1'>{getTestTypeValue(testEvent?.typ)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Status:</strong></Typography>
                <Typography variant='body1'>{getStatusValue(testEventResult?.tst ? testEventResult.tst.sts : testEvent?.sts)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Scheduled Date:</strong></Typography>
                <Typography variant='body1'>{testEvent?.sdt}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Test Started:</strong></Typography>
                <Typography variant='body1'>{getDisplayTime(testEvent?.stm)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Test Ended:</strong></Typography>
                <Typography variant='body1'>{getDisplayTime(testEventResult?.tst?.etm)}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Total Questions:</strong></Typography>
                <Typography variant='body1'>{testEvent?.qcnt}</Typography>
              </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={5}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Location:</strong></Typography>
                <Typography variant='body1'>{testEvent?.loc}</Typography>
              </Stack>
          </Grid>

          <Grid item xs={4} sm={8} md={10}>
              <Divider />
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Stack direction="row" spacing={1}>
              <Typography variant='body1'><strong>Full Name:</strong></Typography>
              <Typography variant='body1'>{testResponse.fn}&nbsp;{testResponse.ln}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
            <Stack direction="row" spacing={1}>
              <Typography variant='body1'><strong>Identity Number:</strong></Typography>
              <Typography variant='body1'>{testResponse.idn || '-'}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Stack direction="row" spacing={1}>
              <Typography variant='body1'><strong>Mobile:</strong></Typography>
              <Typography variant='body1'>{testResponse.mob || '-'}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
            <Stack direction="row" spacing={1}>
              <Typography variant='body1'><strong>Email:</strong></Typography>
              <Typography variant='body1'>{testResponse.eml || '-'}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={4} sm={8} md={10} marginTop={3}>
            <Stack direction="row" spacing={2}>
              <Typography variant='h5'>Your Score: </Typography>
              {testEventResult?.id ? '' : <MUIButton id="attendee-refresh-results" loading={testEventResultMutating} onClick={handleRefreshResult} label="Refresh" variant="outlined" type="info"/>}
            </Stack>
          </Grid>
          <Grid item xs={4} sm={8} md={9}>
            <MUIResultsTable data={testEventResult} />
          </Grid>

          <Grid item xs={4} sm={8} md={10}  marginTop={3}>
            <Stack direction="row" spacing={1}>
              <Typography variant='h5'>{testEventResult?.id ? 'Answers Review:' : ''}</Typography>
              {testEventResult?.id ? <MUIButton onClick={() => setShowAllAccordions(true)} label="Expand" variant="outlined" type="info"/> : ''}
              {testEventResult?.id ? <MUIButton onClick={() => setShowAllAccordions(false)} label="Collapse" variant="outlined" type="info"/> : ''}
            </Stack>
          </Grid>
          <Grid item xs={4} sm={8} md={10}>
            <MUIAnswersAccordion showAll={showAllAccordions} questions={testEvent.qtns} result={testEventResult}/>
          </Grid>
        </Grid>
      </Stack>   
    </Grid>
  );
};

export default TestResultPage;

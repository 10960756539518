import { Alert, Divider, FormControl, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import MUITextField from '../../../components/widgets/fields/MUITextField';
import { getEmailValidator, getMobileValidator, getNameValidator, getTypeValidator } from '../../../services/validations';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { useGetInstitute, useUpdateInstitute } from '../../../services/institute-services';
import MUISelectField from '../../../components/widgets/fields/MUISelectField';
import constants from '../../../util/constants';
import { AppContext } from '../../../AppContext';
import alerts from '../../../util/alerts';
import Swal from 'sweetalert2';
import MUISnackBar from '../../../components/widgets/MUISnackBar';
import { getSessionData } from '../../../services/storage-services';
import { isFutureDate } from '../../../util/utility';

const ProfilePage = () => {

  const { setHeaderTitle } = React.useContext(AppContext);
  React.useEffect(() => {
    setHeaderTitle('Institute Profile')
  }, []); // Run once

  const [snackBar, setSnackBar] = React.useState({show:false});

  const [subscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
  const [expiredSubscription, setExpiredSubscription] = React.useState(true); // let buttons be disabled first
  React.useEffect(() => {
      if(isFutureDate(subscription?.edt)){
          setExpiredSubscription(false)
          triggerInstitute()
      }else{
          setExpiredSubscription(true)
      }
  }, [subscription]); // Run once

  // Form Validations
  const profileSchema = Yup.object().shape({
    fnm: getNameValidator(),
    mob: getMobileValidator(),
    eml: getEmailValidator(),
    typ: getTypeValidator()
  });
  const { handleSubmit:validateProfileForm, reset:resetProfileForm, control:controlProfileForm } = useForm({mode:'onTouched', resolver: yupResolver(profileSchema)});   
  
  const {data:institute, error:instituteError, trigger:triggerInstitute, isMutating:instituteIsMutating } = useGetInstitute()
  React.useEffect(() => {
    if(!instituteIsMutating && !instituteError){
      resetProfileForm(institute)
    }
  }, [institute]); // Run the effect whenever the data changes


  // This will be invoked as needed
  const {data:updateInstituteData, error:updateInstituteError, trigger:triggerUpdateInstitute, isMutating:updateInstituteMutating} = useUpdateInstitute()
  const handleProfileSave = async(formData) => {
    const arg = { 
        requestBody: formData
    }
    await triggerUpdateInstitute(arg)
    if(!updateInstituteMutating){
        if(!updateInstituteError){
            if(updateInstituteData?.errs && updateInstituteData?.errs.length > 0){
                setSnackBar({show:true,severity:'error',message:updateInstituteData.errs[0].msg})
            }else{
                setSnackBar({show:true,severity:'success',message:alerts.A109})
            }
        }else{
            Swal.fire('Error', 'Unable to create Test, please retry again.','error')
        }
    }
  };

  return (   
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
      <MUISnackBar id="institute-profile-bar" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>

      {!isFutureDate(subscription?.edt) && 
          <Grid item xs={4} sm={8} md={9}>
              <Alert id="subscription-heading-id" variant="filled" severity="error">Your current subscription is Expired. Please renew by purchasing new subscription.</Alert>
          </Grid>
      }

      <Stack direction="column" spacing={2} sx={{ml:1, width: 1 }}>
          <FormControl>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>     
                <Grid item xs={4} sm={8} md={12} marginTop={3}>
                </Grid>
                <Grid item xs={4} sm={4} md={5}>
                    <MUITextField id="profile-fnm-txt" label="Institute Full Name" name="fnm" control={controlProfileForm} required maxLength={40}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-eml-txt" label="Email" name="eml" control={controlProfileForm} required maxLength={80}/>
                </Grid>
                <Grid item xs={4} sm={4} md={3}></Grid>
                <Grid item xs={4} sm={4} md={3}>
                    <MUITextField id="profile-mob-txt" label="Mobile" name="mob" control={controlProfileForm} maxLength={10}/>
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                    <MUISelectField id="profile-typ-sel" name='typ' label='Type' control={controlProfileForm} options={constants.INSTITUTE_TYPES}/>
                </Grid>
            </Grid>
            <Grid container paddingTop={4} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>  
                <Grid item xs={2} sm={4} md={5}>
                    <MUITextField id="profile-adr-ln-txt" name="adr.ln" label="Address Line" control={controlProfileForm} maxLength={80}/>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <MUITextField id="profile-adr-cn-txt" name="adr.cn" label="City Name" control={controlProfileForm} maxLength={80}/>
                </Grid>
                <Grid item xs={4} sm={4} md={3}></Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <MUISelectField id="profile-adr-cc-sel" name="adr.cc" label='Country' control={controlProfileForm} options={constants.COUNTRY_LIST} />
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <MUITextField id="profile-adr-sc-txt" name="adr.sc" label="State" control={controlProfileForm} maxLength={80}/>
                </Grid>
                <Grid item xs={2} sm={4} md={2}>
                    <MUITextField id="profile-adr-zc-txt" name="adr.zc" label="Zipcode" control={controlProfileForm} maxLength={10}/>
                </Grid>

                <Grid item xs={2} sm={4} md={10} marginBottom={5}>
                    <MUIButton id="profile-update-btn" loading={instituteIsMutating || updateInstituteMutating} disabled={expiredSubscription} onClick={validateProfileForm(handleProfileSave)} label="Update" variant="contained" type="secondary"/>
                </Grid>
            </Grid>
          </FormControl>
        
          <FormControl>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
              <Grid item xs={4} sm={8} md={10}>
                  <Typography variant="h4">Account</Typography>
                  <Divider />
              </Grid>
              <Grid item xs={4} sm={8} md={10}>
                  <Typography><strong>Right To Be Forgotten</strong></Typography>
                  <p>We're very sorry that you wish to leave taragati.ai, if there is anything that we can do that would make you want to stay, please contact support and let us know how we can help before you decide to close your account!</p>
                  <p>If you wish to completely delete your account and all data, continue reading below and submit your request. Deleting your account in this way will remove all test, test events, profile data and subscription data online.</p>
                  <p>Please Note: The data is NOT deleted IMMEDIATELY. <strong>We will earse your account and associated data with in 30 days after we receive your deletion request.</strong> Once your account is deleted, <strong>there is NO way to recover your account and associated data.</strong></p>
              </Grid>
              <Grid item xs={4} sm={8} md={10}>
                <Typography sx={{ color: "red" }}>To confirm that you wish to permanently close your account and delete your data, enter your full name.</Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={3}>
                  <MUITextField name="nm" control={controlProfileForm} id="nm" label="Full Name"/>
              </Grid>
              <Grid item xs={2} sm={4} md={10} marginBottom={5}>
                  <MUIButton onClick={() => {}} label="Delete" variant="contained" type="error"/>
              </Grid>
            </Grid>
          </FormControl>
      </Stack>
    </Grid>
  );
};

export default ProfilePage;

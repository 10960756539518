import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const MUISelectField = (props) => {

  const SelectFieldStyle = {
    // TBD
  };

  return (
    <Controller name={props.name} control={props.control} 
      render={({ 
        field: { onChange, onBlur, value, ref }, 
        fieldState: { error } }) => (
          <TextField 
            id={props.id}
            InputLabelProps={{ shrink: true }} 
            SelectProps={{
              style: SelectFieldStyle,
            }}
            size='small'
            variant={props.variant}
            select
            fullWidth 
            error={!!error} 
            helperText={error ? error.message : null} 

            value={value || ''} 
            onChange={onChange} // send value to hook form
            onBlur={onBlur} // notify when input is touched
            
            {...props}>
              {props.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.label}
                  </MenuItem>
              ))}
          </TextField> 
      )}>
    </Controller>
  );
}

export default MUISelectField;
import { Alert, Divider, FormControl, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import MUITextField from '../../../components/widgets/fields/MUITextField';
import { getEmailValidator, getMobileValidator, getNameValidator, getPasswordValidator } from '../../../services/validations';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { useGetUser, useUpdateUser } from '../../../services/institute-services';
import constants from '../../../util/constants';
import { AppContext } from '../../../AppContext';
import alerts from '../../../util/alerts';
import Swal from 'sweetalert2';
import MUISnackBar from '../../../components/widgets/MUISnackBar';
import { getSessionData } from '../../../services/storage-services';
import { isFutureDate } from '../../../util/utility';
import { changePassword } from '../../../services/authenticate';
import errors from '../../../util/errors';

const MyProfilePage = () => {

  const { setHeaderTitle } = React.useContext(AppContext);
  React.useEffect(() => {
    setHeaderTitle('My Profile')
  }, []); // Run once

  const [snackBar, setSnackBar] = React.useState({show:false});

  const [subscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
  const [expiredSubscription, setExpiredSubscription] = React.useState(true); // let buttons be disabled first
  React.useEffect(() => {
      if(isFutureDate(subscription?.edt)){
          setExpiredSubscription(false)
          triggerGetUser()
      }else{
          setExpiredSubscription(true)
      }
  }, [subscription]); // Run once

  // Form Validations
  const profileSchema = Yup.object().shape({
    fnm: getNameValidator(),
    mob: getMobileValidator(),
    eml: getEmailValidator()
  });
  const { handleSubmit:validateProfileForm, reset:resetProfileForm, control:controlProfileForm, getValues: getProfileValues} = useForm({mode:'onTouched', resolver: yupResolver(profileSchema)});   
  
  const {data:userData, error:getUserError, trigger:triggerGetUser, isMutating:getUserIsMutating } = useGetUser()
  React.useEffect(() => {
    if(!getUserIsMutating && !getUserError){
      resetProfileForm(userData)
    }
  }, [userData]); // Run the effect whenever the data changes

  // This will be invoked as needed
  const {data:updateUserData, error:updateUserError, trigger:triggerUpdateUser, isMutating:updateUserMutating} = useUpdateUser()
  const handleProfileSave = async(formData) => {
    const arg = { 
        requestBody: formData
    }
    await triggerUpdateUser(arg)
    if(!updateUserMutating){
        if(!updateUserError){
            if(updateUserData?.errs && updateUserData?.errs.length > 0){
                setSnackBar({show:true,severity:'error',message:updateUserData.errs[0].msg})
            }else{
                setSnackBar({show:true,severity:'success',message:alerts.A002})
            }
        }else{
            Swal.fire('Error', 'Unable to update details, please retry again.','error')
        }
    }
  };

  // Form Validations
  var [changePasswordLoading, setChangePasswordLoading] = React.useState(false);
  const passwordSchema = Yup.object().shape({
    opwd: getPasswordValidator(),
    npwd: getPasswordValidator(),
    cpwd: Yup.string().oneOf([Yup.ref('npwd'), null], 'Passwords must match')
  });
  const {handleSubmit:validatePasswordForm, reset:resetPasswordForm, control:controlPasswordForm } = useForm({mode:'onTouched', resolver: yupResolver(passwordSchema)});   
  const handlePasswordSave = async(formData) => {
    setChangePasswordLoading(true)
    try {
      const response = await changePassword(formData.opwd,formData.npwd)
      setChangePasswordLoading(false)
      if(response && response.success){
        setSnackBar({show:true,severity:'success',message:alerts.A003})
      }else if(response.toString().includes('Attempt limit exceeded')){
        setSnackBar({show:true,severity:'error',message:errors.E009})
      }
    } catch (error) {
      setSnackBar({show:true,severity:'error',message:errors.E009})
    } finally {
      setChangePasswordLoading(false)
    }
  }

  return (   
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <MUISnackBar id="institute-profile-bar" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
      {!isFutureDate(subscription?.edt) && 
          <Grid item xs={4} sm={8} md={9}>
              <Alert id="subscription-heading-id" variant="filled" severity="error">Your current subscription is Expired. Please renew by purchasing new subscription.</Alert>
          </Grid>
      }
      <Stack direction={{xs:'column',md:'row'}} spacing={1} sx={{ml:1.3}} marginTop={4}>
          <FormControl>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 6 }}>     
                <Grid item xs={4} sm={4} md={4} marginBottom={2}>
                  <Typography variant="h5">Personal Details</Typography>
                  <Divider />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-fnm-txt" label="Full Name" name="fnm" control={controlProfileForm} required maxLength={40}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-eml-txt" label="Email" name="eml" disabled control={controlProfileForm} required maxLength={80}/>
                </Grid>
                <Grid item xs={4} sm={4} md={2}></Grid>
                <Grid item xs={4} sm={4} md={2}>
                    <MUITextField id="profile-mob-txt" label="Mobile" name="mob" control={controlProfileForm} />
                </Grid>
                <Grid item xs={4} sm={4} md={4}></Grid>
                <Grid item xs={4} sm={4} md={2}>
                    <MUITextField id="profile-rol-txt" label="Role" disabled name="rol" control={controlProfileForm} />
                </Grid>
            </Grid>
            <Grid container paddingTop={4} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 6 }}>  
                <Grid item xs={2} sm={4} md={4} marginBottom={5}>
                    <MUIButton id="profile-update-btn" loading={getUserIsMutating || updateUserMutating} disabled={expiredSubscription} onClick={validateProfileForm(handleProfileSave)} label="Update" variant="contained" type="secondary"/>
                </Grid>
            </Grid>
          </FormControl>

          <FormControl>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 6 }}>    
                <Grid item xs={4} sm={4} md={5} marginBottom={2}>
                  <Typography variant="h5">Change Password</Typography>
                  <Divider />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-opwd-txt" label="Old Password" name="opwd" control={controlPasswordForm} required maxLength={20}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-npwd-txt" label="New Password" name="npwd" control={controlPasswordForm} required maxLength={20}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <MUITextField id="profile-cpwd-txt" label="Confirm Password" name="cpwd" control={controlPasswordForm} required maxLength={20}/>
                </Grid>
            </Grid>
            <Grid container paddingTop={4} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 6 }}>  
                <Grid item xs={2} sm={4} md={5} marginBottom={5}>
                    <MUIButton id="password-update-btn" loading={changePasswordLoading} disabled={expiredSubscription} onClick={validatePasswordForm(handlePasswordSave)} label="Update" variant="contained" type="secondary"/>
                </Grid>
            </Grid>
          </FormControl>
      </Stack>
      
    </Grid>
  );
};

export default MyProfilePage;

import React from 'react';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import alerts from '../../util/alerts'; 
import errors from '../../util/errors';
import ExamResponseModel from '../../models/v1/testresponse';

import { getSessionData, putSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import MUISnackBar from '../../components/widgets/MUISnackBar';
import { useCreateExamResponse, useGetExamEventResult, useGetPurchaseItem, useSubmitExamResponse } from '../../services/practiceexam-services';
import MUIExamAttemptsTable from '../../components/widgets/tables/MUIExamAttemptsTable';
import LiveQuestions from '../../components/widgets/LiveQuestions';
import { getCurrentTime } from '../../util/utility';
import MUIAnswersAccordion from '../../components/widgets/accordion/MUIAnswersAccordion';
import PDFDownload from '../../components/widgets/PDFDownload';
import MUIBreadCrumbs from '../../components/widgets/MUIBreadCrumbs';
import Swal from 'sweetalert2';
import { AppContext } from '../../AppContext';

const PracticeExamPage = () => {
  const navigate = useNavigate();
  var [snackBar, setSnackBar] = React.useState({show:false});
  var [tabId, setTabId] = React.useState(0);
  var [examResponse, setExamResponse] = React.useState(new ExamResponseModel());
  var [selectResultId, setSelectedResultId] = React.useState();
  var [examResult, setExamResult] = React.useState();
  var [showAllAccordions, setShowAllAccordions] = React.useState(false);

  const EXAM_DETAIL_CRUMBS = {
    links: [],
    current: {id:'bcrumb-exam-details', text: 'Exam Details'}
  };

  const TAKE_EXAM_CRUMBS = {
    links: [{id:'bcrumb-exam-details', text: 'Exam Details',onClick: ()=>{handleExamDetailsClick()}}],
    current: {text: 'Attempt'}
  };

  const EXAM_RESULTS_CRUMBS = {
    links: [{id:'bcrumb-exam-details', text: 'Exam Details',onClick: ()=>{setTabId(0)}}],
    current: {text: 'Results'}
  };

  const handleExamDetailsClick = () =>{
    Swal.fire({
      title: "Are you sure ?",
      text: "You will exit the exam in progress",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Exit'
    })
   .then((willExit) => {
      if(willExit.isConfirmed) {
        setTabId(0)
      }
   });
  }

  // Form data states
  var [practiceExam, setPracticeExam] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_PRACTICE_EXAM)));

  var { setPageMode } = React.useContext(AppContext);
  React.useEffect(() => {
    setPageMode('practice-exam')
  }, []);

  // This will be invoked as needed
  const {data:getPracticeExam, error:getPracticeExamError, trigger:triggerGetPracticeExam, isMutating:practiceExamMutating} = useGetPurchaseItem()
  React.useEffect(() => {
    if(getPracticeExam && getPracticeExam.sts){
      putSessionData(constants.SESSION_KEY_PRACTICE_EXAM, JSON.stringify({id:practiceExam.id, ...getPracticeExam}))
      setPracticeExam({id:practiceExam.id, ...getPracticeExam})
    }else if(practiceExam?.errs && practiceExam?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:practiceExam.errs[0].msg})
    }else if(getPracticeExamError){
      setSnackBar({show:true,severity:'error',message:errors.E207})
    }
  }, [getPracticeExam]); // Run the effect whenever the data changes

  // This will be invoked as needed
  var {data:createExamResponse, error:createExamResponseError, trigger:triggerCreateExamResponse, isMutating:createExamResponseMutating} = useCreateExamResponse()
  React.useEffect(() => {
    if(createExamResponse && createExamResponse.stm){
      examResponse.id = createExamResponse.id
      examResponse.stm = createExamResponse.stm
      examResponse.ltm = getCurrentTime()
      putSessionData(constants.SESSION_KEY_PRACTICE_EXAM_RESPONSE, JSON.stringify(examResponse))
      setTabId(1)
      setSnackBar({show:true,severity:'success',message:alerts.A201})
    }else if(createExamResponse?.errs && createExamResponse?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:createExamResponse.errs[0].msg})
    }else if(createExamResponseError){
      setSnackBar({show:true,severity:'error',message:errors.E206})
    }
  }, [createExamResponse, createExamResponseError]); // Run the effect whenever the data changes

  const handleExamResponseCreate = async() => {
    setExamResponse(new ExamResponseModel())
    // Find maximum attempts allowed and calculate current attempt
    const nextId = practiceExam.atmts? practiceExam.atmts.length + 1 : 1
    const arg = { 
      headers: {"x-purchaseid": practiceExam.id,"x-lastname": practiceExam.ln,"x-email": practiceExam.eml},
      requestBody: {id: nextId}
    }
    await triggerCreateExamResponse(arg)
  };

  // This will be invoked as needed
  const {data:submitExamResponse, error:submitExamResponseError, trigger:triggersubmitExamResponse, isMutating:submitExamResponseMutating} = useSubmitExamResponse()
  const handleExamResponseSubmit = async() => {
    const arg = {
      pathParams: { responseId: examResponse.id},
      headers: {"x-purchaseid": practiceExam.id, "x-lastname": practiceExam.ln, "x-email": practiceExam.eml},
      requestBody: examResponse
    }
    await triggersubmitExamResponse(arg)
  };
  React.useEffect(() => {
    if(submitExamResponse && submitExamResponse.id){
      examResponse.etm = submitExamResponse.etm
      putSessionData(constants.SESSION_KEY_PRACTICE_EXAM_RESPONSE, JSON.stringify(examResponse))
      setTabId(0)
      setSnackBar({show:true,severity:'success',message:alerts.A105})
      const arg = {headers: {"x-purchaseid": practiceExam.id,"x-lastname": practiceExam.ln,"x-email": practiceExam.eml}}
      triggerGetPracticeExam(arg)
    }else if(submitExamResponse?.errs && submitExamResponse?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:submitExamResponse.errs[0].msg})
    }else if(submitExamResponseError){
      setSnackBar({show:true,severity:'error',message:errors.E205})
    }
  }, [submitExamResponse, submitExamResponseError]); // Run the effect whenever the data changes


  // This will be invoked as needed
  const {data:getExamEventResult, error:getExamEventResultError, trigger:triggerGetExamEventResult, isMutating:getExamEventResultMutating} = useGetExamEventResult()
  React.useEffect(() => {
    if(getExamEventResult && getExamEventResult.res){
      setExamResult({id: selectResultId, ...getExamEventResult})
      putSessionData(constants.SESSION_KEY_PRACTICE_EXAM_RESULT, JSON.stringify({id: selectResultId, ...getExamEventResult}))
      setTabId(2)
    }else if(getExamEventResult?.errs && getExamEventResult?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:submitExamResponse.errs[0].msg})
    }else if(getExamEventResultError){
      setSnackBar({show:true,severity:'error',message:errors.E103})
    }
  }, [getExamEventResult]); // Run the effect whenever the data changes
  
  const handleExamResultDetails = async(id) => {
    setSelectedResultId(id)
    const arg = {
      pathParams: {responseId: id},
      headers: {"x-purchaseid": practiceExam.id,"x-lastname": practiceExam.ln,"x-email": practiceExam.eml}
    }
    setShowAllAccordions(false)
    await triggerGetExamEventResult(arg)
  }

  const calculateAttempts = () => {
    if(practiceExam.atmts){
      return practiceExam.exam.atmt - practiceExam.atmts.length
    }else{
      return practiceExam.exam.atmt
    }
  }

  return (
    <>
      <MUISnackBar open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>   
        <Grid id="practice-exam-page" container spacing={{ xs: 1, md: 1 }} columns={{ xs: 6, sm: 10, md: 12 }}>
          <Grid item xs={6} sm={10} md={12} marginLeft={1} sx={{my: 2}}>
            <Stack direction={{xs:'column', sm:'row'}} spacing={1} width={1}>
              <Stack direction="row" spacing={1} >
                <Typography variant='h5'><strong>Title:</strong></Typography>
                <Typography id="pexam-ttl-lbl" variant='h5'>{practiceExam?.exam?.ttl ? practiceExam.exam.ttl : <Skeleton width={400}/>}</Typography>
              </Stack>
              <Stack direction="row" width={0.2}>
                {tabId != 1 && <PDFDownload id='practice-exam-page' execute={(open) => setShowAllAccordions(open)} fileName={practiceExam.fn +'-'+practiceExam.ln+'-Exam-'+practiceExam?.exam.id+'.pdf'} />}
              </Stack>
            </Stack>
          </Grid>
          
          <Grid container marginLeft={2}>
            <Grid item xs={6} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Level:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.lvl : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Questions:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.qcnt : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Duration:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.tlmt +' Minutes' : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Validity:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.val +' Days' : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Attempts:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.atmt +' Submissions' : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='body1'><strong>Pass Percent:</strong></Typography>
                <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam?.exam? practiceExam.exam.pper +'%' : <Skeleton width={10}/>}</Typography>
              </Stack>
            </Grid>
          </Grid>


          <Grid container marginLeft={2} marginTop={1}>
            <Grid item xs={8} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Name:</strong></Typography>
                  <Typography id="pexam-nm-lbl" variant='body1'>{practiceExam? practiceExam.fn+' '+practiceExam.ln : <Skeleton width={20}/>}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Email:</strong></Typography>
                  <Typography id="pexam-eml-lbl" variant='body1'>{practiceExam? practiceExam.eml : <Skeleton width={20}/>}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Purchase Date:</strong></Typography>
                  <Typography id="pexam-pdt-lbl" variant='body1'>{practiceExam? practiceExam.pdt : <Skeleton width={20}/>}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Expiry Date:</strong></Typography>
                  <Typography id="pexam-edt-lbl" variant='body1' color="tomato">{practiceExam? practiceExam.edt : <Skeleton width={20}/>}</Typography>
                </Stack>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={10} md={12} marginTop={1}>
            <Divider />
          </Grid>

          {tabId === 0 &&
            <>
              <Grid my={1} marginLeft={1}>
                <MUIBreadCrumbs crumbs={EXAM_DETAIL_CRUMBS}/>
              </Grid>
              <Grid container marginLeft={1} marginTop={2} marginBottom={{xs:5, sm:10, md:25}}>
                <Grid item xs={11} sm={8} md={10}>
                  <Typography variant='h5'>Your Previous Submissions:</Typography>
                </Grid>
                <Grid item xs={11} sm={8} md={6} marginTop={1}>
                  <MUIExamAttemptsTable data={practiceExam.atmts} onDetails={handleExamResultDetails}/>
                </Grid>
                <Grid item xs={12} sm={8} md={4} marginTop={{xs:4, md:0}} marginLeft={{md:5}}>
                  <Typography variant='body1'><strong>You have {calculateAttempts()} submission left.</strong></Typography>
                  <Typography variant='body1'>You can view result of the submitted exam and download the report</Typography>
                  {calculateAttempts() > 0 &&
                    <Stack direction="row" spacing={2} alignItems={'baseline'} marginTop={2}>
                      <Typography variant='body1'>Ready to take exam ?</Typography>
                      <MUIButton id="take-exam-attempt" loading={createExamResponseMutating} size='small' onClick={handleExamResponseCreate} label="Take Exam" type="secondary"/>
                    </Stack>
                  }
                </Grid>
              </Grid>
            </>
          }
        
          {tabId === 1 &&
            <Stack direction={'column'} marginLeft={1} marginRight={5}>
              <Grid marginTop={1} marginLeft={1}>
                <MUIBreadCrumbs crumbs={TAKE_EXAM_CRUMBS}/>
              </Grid>
              <LiveQuestions exam={practiceExam.exam} questions={practiceExam.qtns} response={examResponse} 
                  onTimeExpiry={()=>{setTabId(0)}} 
                  onFinalSubmit={()=>{handleExamResponseSubmit()}}
                  onSubmitLoad={submitExamResponseMutating}/>
              <Grid marginBottom={4} item xs={4} sm={4} md={10}></Grid>
            </Stack>
          }

          {tabId === 2 &&
            <Stack direction={'column'} ml={1}>
              <Grid marginTop={1}>
                <MUIBreadCrumbs crumbs={EXAM_RESULTS_CRUMBS}/>
              </Grid>
              <Grid item xs={4} sm={8} md={10}  my={2}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='h5'>{examResult?.id ? 'Answers Review:' : ''}</Typography>
                  {examResult?.id ? <MUIButton onClick={() => setShowAllAccordions(true)} label="Expand" variant="outlined" type="info"/> : ''}
                  {examResult?.id ? <MUIButton onClick={() => setShowAllAccordions(false)} label="Collapse" variant="outlined" type="info"/> : ''}
                </Stack>
              </Grid>
              <Grid item xs={4} sm={7} md={10}>
                <MUIAnswersAccordion showAll={showAllAccordions} questions={practiceExam.qtns} result={examResult} />
              </Grid>
            </Stack>
          }
        </Grid>
    </>  
  )
}
export default PracticeExamPage;
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Link, Snackbar, Stack } from '@mui/material';

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined
}
  
function setCookie(cname,cvalue,expiryMinutes) {
  const d = new Date();
  d.setTime(d.getTime() + (expiryMinutes*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function clearAllCookies(except) {
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (!except || c.indexOf(except) != 0) {
          document.cookie = c + ";expires="+ new Date(0).toUTCString();
      }
  }
  return undefined
}

const consentGiven = () => {
  try{
    const consent = getCookie('taragati-consent');
    if(consent == 'accept-all'){
      // Fire GTM consent, granted all, future will have individual consent
      fireGTMConsent('granted')
      return true;
    }else if(consent == 'essential-only'){
      //FIRST Fire GTM consent, denied all, future will have individual consent
      fireGTMConsent('denied')
      clearAllCookies('taragati-consent')
      return true;
    }
  }catch{
  }
  // clear all cookies and return false
  clearAllCookies()
  return false;
}

function gtag(){
  window.dataLayer.push(arguments);
}
const fireGTMConsent = (action) => {
  gtag('consent','update',{
    'ad_storage': action,
    'analytics_storage': action,
    'personalization_storage': action,
    'functionality_storage': action,
    'security_storage': action
  })
}

const Consent = (props) => {
  var [gdprSnackBar,setGdprSnackBar] = React.useState({show:false});
  React.useEffect(() => {
    if(!consentGiven()){
      setGdprSnackBar({show:true})
    }
  }, []);
  
  const handleCookieConsent = (action) => {
    if(action === 'accept-all'){
      setCookie("taragati-consent","accept-all",365*24*60)
      // Fire GTM consent, granted all, future will have individual consent
      fireGTMConsent('granted')
      setGdprSnackBar({show:false})
    }else if(action === 'essential-only'){
      //FIRST Fire GTM consent, denied all, future will have individual consent
      fireGTMConsent('denied')
      setCookie("taragati-consent","essential-only",365*24*60)
      setGdprSnackBar({show:false})
    }
  }

  return (
    <Snackbar id="cookie-consent" open={gdprSnackBar.show} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} sx={{ width: '90%' }}
        message={ 
          <Stack direction={{xs:'column',sm:'row'}} spacing={3} justifyContent="space-between" alignItems="flex-end">
            <Stack direction={'column'} spacing={1}>
              <Typography variant='body1'><strong>We value your privacy</strong></Typography>
              <Typography variant='body2'>We use essential cookies to make this site work. With your consent, we may also use non-essential cookies to enhance your browsing experience, serve personalized content or ads, and analyze website traffic. 
                By clicking "Accept All", you give consent to our use of cookies. 
                Plese refer our <Link color={'#ffffff'} component={'button'} onClick={props.onCookieClick}>Cookie Policy</Link> and <Link color={'#ffffff'} component={'button'} onClick={props.onPrivacyClick}>Privacy Policy</Link>
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} minWidth={{sm:260}}>
              <Button id="consent-essential-btn" variant='outlined' size="small" color='info' onClick={()=>handleCookieConsent('essential-only')}>Essential Only</Button>
              <Button id="consent-accept-btn" variant='contained' size="small" color='info' onClick={()=>handleCookieConsent('accept-all')}>Accept All</Button>
            </Stack>
          </Stack>
        }>
    </Snackbar>
  );
}

export default Consent;
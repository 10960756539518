import { getHTTP} from '../util/utility'
import useSWRMutation from 'swr/mutation'

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useGetExamCatalog = ()  => {
    const { data, error, trigger, isMutating } = useSWRMutation(()=>process.env.REACT_APP_BASEURL + '/exam-catalog.json', getHTTP)
    return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
    }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useGetSubscriptionCatalog = ()  => {
  const { data, error, trigger, isMutating } = useSWRMutation(()=>process.env.REACT_APP_BASEURL + '/subscription.json', getHTTP)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}
import React from 'react';
import { Divider, FormControl, FormGroup, Grid, RadioGroup, Stack, Typography } from '@mui/material';
import MUIButton from './fields/MUIButton';
import errors from '../../util/errors';
import MUISnackBar from './MUISnackBar';
import { convertSecondsToTime, formatQuestionText } from '../../util/utility';
import Swal from 'sweetalert2';
import MUICheckBox from './fields/MUICheckBox';
import MUIRadioButton from './fields/MUIRadioButton';

const LiveQuestions = (props) => {
    var [questionNumber, setQuestionNumber] = React.useState(1);
    var [snackBar, setSnackBar] = React.useState({show:false});
    var [tabId, setTabId] = React.useState(0);
    var [savedAnswers, setSavedAnswers] = React.useState([0]);
    var [timeRemaining, setTimeRemaining] = React.useState(props.exam? props.exam.tlmt * 60 : -1);

    const ANSWER_CODE_ENUM = [
        {code:'ansOpt0', value:'A'},
        {code:'ansOpt1', value:'B'},
        {code:'ansOpt2', value:'C'},
        {code:'ansOpt3', value:'D'},
        {code:'ansOpt4', value:'E'}
    ]
    const getAnswerValue = (code) => {
        const filter = ANSWER_CODE_ENUM.filter((item) => item.code === code)
        return filter && filter.length > 0 ? filter[0].value : ''
    }

    // Form fields
    const [checkBoxes, setCheckBoxes] = React.useState({
        ansOpt0: false, ansOpt1: false, ansOpt2: false, ansOpt3: false, ansOpt4: false
    })
    const [radioButton, setRadioButton] = React.useState('')

    React.useEffect(() => {
        // Reset selections as new question
        setCheckBoxes({ansOpt0:false,ansOpt1:false,ansOpt2:false,ansOpt3:false,ansOpt4:false})
        setRadioButton('')
        
        document.getElementById("question-txt").innerHTML = ''
        formatQuestionText(props.questions[questionNumber-1].txt).forEach((element, index) => {
            var tag
            if(element.includes('//images')){
                tag = document.createElement('img');
                tag.setAttribute('src',process.env.REACT_APP_BASEURL+'/images/'+element.split('//images/')[1])
                tag.setAttribute('style','max-width:500px;width:100%;height:auto')
                document.getElementById("question-txt").appendChild(tag);
            }else if(element.includes('<pre>')){
                document.getElementById("question-txt").insertAdjacentHTML('beforeend',element)
            }else{
                tag = document.createElement('span');
                tag.innerText = element;
                document.getElementById("question-txt").appendChild(tag);
            }
        })
    }, [questionNumber]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if(timeRemaining > 0){
                setTimeRemaining(timeRemaining - 1);
            }else if(timeRemaining === 0){
                clearInterval(interval);
                Swal.fire('Time\'s up!', 'You did not submitted the exam on time, please retake the exam.','info')
                    .then(() => {
                        props.onTimeExpiry()
                    });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timeRemaining]);
    
    const handleAnswerChange = ev => {
        if(!props.response.res){
            props.response.res = [];
        }
        let existingAnswer = props.response.res.find(obj => obj.qid === questionNumber);
        
        if(ev.target.type === 'radio'){
            if (existingAnswer) {
                existingAnswer.ans = [getAnswerValue(ev.target.name)];
            } else {
                let newAnswer = {qid:questionNumber, ans:[getAnswerValue(ev.target.name)]};
                props.response.res.push(newAnswer);
            }
            // Handle radio button status
            setRadioButton(ev.target.name)
        }else if(ev.target.type === 'checkbox'){
            if (existingAnswer) {
                if(ev.target.checked){
                    existingAnswer.ans.push(getAnswerValue(ev.target.name));
                }else{
                    existingAnswer.ans = existingAnswer.ans.filter(item => item !== getAnswerValue(ev.target.name));
                }
            }else{
                let newAnswer = {qid:questionNumber, ans:[getAnswerValue(ev.target.name)]};
                props.response.res.push(newAnswer);
            }
            // Handle checkbox status
            if(ev.target.name === 'ansOpt0'){setCheckBoxes({...checkBoxes, ansOpt0:!checkBoxes.ansOpt0})}
            if(ev.target.name === 'ansOpt1'){setCheckBoxes({...checkBoxes, ansOpt1:!checkBoxes.ansOpt1})}
            if(ev.target.name === 'ansOpt2'){setCheckBoxes({...checkBoxes, ansOpt2:!checkBoxes.ansOpt2})}
            if(ev.target.name === 'ansOpt3'){setCheckBoxes({...checkBoxes, ansOpt3:!checkBoxes.ansOpt3})}
            if(ev.target.name === 'ansOpt4'){setCheckBoxes({...checkBoxes, ansOpt4:!checkBoxes.ansOpt4})}
        }
    }

    const handleAnswerSave = () => {
        // Validate this question is answered, currently it is mandate to answer
        if(!props.response || !props.response.res || props.response.res.length == 0
            || !props.response.res.find((obj) => obj.qid === questionNumber)){
            setSnackBar({show:true,severity:'error',message:errors.E203})
            window.dataLayer.push({event:'error_event',event_category:'practice-exam',event_action:'error',event_label:'E203',event_value:errors.E203})
            return;
        }

        setSavedAnswers(savedAnswers => [...savedAnswers, questionNumber])
        if(questionNumber === props.questions.length){
            setTabId(1)
        }else{
            setQuestionNumber(questionNumber + 1)
        }
    }

    return (
        <FormControl sx={{ml:1, mt:2}}>
            <MUISnackBar id="save-answer-err" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>   
              
                <Grid item xs={4} sm={6} md={6} marginTop={1} marginBottom={1} minWidth={400}>
                    <Stack direction="row" spacing={1} marginBottom={0} alignItems={'end'}>
                        <Typography variant='h5' style={{color:'red'}}>Question ({String(questionNumber) + '/' + String(props.questions.length)}):&nbsp;&nbsp;</Typography>
                        {/* <MUIButton onClick={handlePreviousClick} label="Previous" variant="outlined" type="info"/>
                        <MUIButton onClick={handleNextClick} label="Next" variant="outlined" type="info"/> */}
                        {timeRemaining!=-1 &&
                            <Stack direction="row" spacing={0} alignItems="center">
                                <Typography variant='h6'>Time Remaining:</Typography>
                                <Typography variant="body1" style={{color: timeRemaining < 60 ? 'red' : ''}}>{convertSecondsToTime(timeRemaining)}</Typography>
                            </Stack>
                        }
                    </Stack>
                    <Divider/>
                </Grid> 
                {tabId === 0 &&
                  <>
                    <Grid item xs={10} sm={10} md={10}>
                        <div className="prevent-select"><strong><Stack direction={'column'} id="question-txt" spacing={2}></Stack></strong></div>
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} >
                        <div className="prevent-select">
                            {props.questions[questionNumber-1].typ === 'SCQ' && 
                                <RadioGroup value={radioButton} onChange={handleAnswerChange}>
                                    {Array.from(Array(props.questions[questionNumber-1].opts.length)).map((_, index) => (
                                        <MUIRadioButton key={"ansOpt"+index} 
                                            id={"ansOpt"+index}
                                            name={"ansOpt"+index}
                                            value={"ansOpt"+index}
                                            label={props.questions[questionNumber-1].opts[index].id+". "+props.questions[questionNumber-1].opts[index].txt} />
                                    ))}
                                </RadioGroup>
                            }
                            {props.questions[questionNumber-1].typ === 'MCQ' && 
                                <FormGroup>
                                    {Array.from(Array(props.questions[questionNumber-1].opts.length)).map((_, index) => (
                                        <MUICheckBox key={"ansOpt"+index} withLabel={true}
                                            id={"ansOpt"+index}
                                            name={"ansOpt"+index}
                                            value={checkBoxes["ansOpt"+index]}
                                            onChange={handleAnswerChange}
                                            label={props.questions[questionNumber-1].opts[index].id+". "+props.questions[questionNumber-1].opts[index].txt} />
                                    ))}
                                </FormGroup>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={10} md={10}>
                        <MUIButton id="save-answer-btn" onClick={handleAnswerSave} label="Save" variant="contained" type="secondary"/>
                    </Grid>  
                  </>
                }
                {tabId === 1 &&
                    <>
                        <Grid item xs={12} sm={8} md={6} marginTop={{xs:4, md:1}}>
                            <Typography variant='h6'>All questions are answered, you can submit your response.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} marginTop={{xs:4, md:1}}>
                            <MUIButton id="submit-test-btn" loading={props.onSubmitLoad} onClick={()=>{props.onFinalSubmit()}} label="Submit" variant="contained" type="secondary"/>
                        </Grid>
                    </>
                }
            </Grid>
      </FormControl>
    );
};

export default LiveQuestions;
import { Button, Card, CardContent, Stack, Grid, Typography, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MUITextField from '../../components/widgets/fields/MUITextField';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MUIButton from '../../components/widgets/fields/MUIButton';
import { getFullNameValidator, getPasswordValidator, getUserNameValidator } from '../../services/validations';
import { styled } from '@mui/material/styles';
import { authenticate, signup } from '../../services/authenticate';
import MUISnackBar from '../../components/widgets/MUISnackBar';
import errors from '../../util/errors';
import { putSessionData } from '../../services/storage-services';
import constants from '../../util/constants';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { AppContext } from '../../AppContext';
import { isFutureDate } from '../../util/utility';
import TermsAndConditionsPage from '../legal/TermsAndConditionsPage';
import PrivacyPolicyPage from '../legal/PrivacyPolicyPage';
import alerts from '../../util/alerts';

const LoginPage = () => {
  var navigate = useNavigate();
  var [tabId, setTabId] = React.useState(0);
  var [signinLoading, setSigninLoading] = React.useState(false);
  var [signupLoading, setSignupLoading] = React.useState(false);
  var [snackBar, setSnackBar] = React.useState({show:false});
  var { setPageMode } = React.useContext(AppContext);

  React.useEffect(() => {
    setPageMode('institute-login')
  }, [setPageMode]);

  const Img = styled('img')({
    margin: 'auto',
    maxWidth: '80%'
  });

  const handleTabSwitch= (action) => {
    if(action === 'signup'){
      if(process.env.REACT_APP_ENABLE_INSTITUTE_SIGNUP){
        resetSignUpForm()
        setTabId(1)
      }else{
        setTabId(3)
      }
    }else if(action === 'signin'){
      resetSignInForm()
      setTabId(0)
    }else if(action === 'forgot'){
      setTabId(2)
    }
  }
  const signInForm = Yup.object().shape({
    eml: getUserNameValidator(),
    pwd: getPasswordValidator()
  });
  const { handleSubmit:validateSignInForm, reset:resetSignInForm, control:controlSignInForm } = useForm({mode:'onTouched', resolver: yupResolver(signInForm)});   
  const handleSignIn = async(formData) => {
    setSigninLoading(true)
    try {
      const response = await authenticate(formData.eml,formData.pwd)
      if(response && response.session && response.idToken ){
        const session = JSON.parse(response.session)
        console.log(session)
        putSessionData(constants.SESSION_KEY,JSON.stringify({id: session.id, idToken: response.idToken}))
        putSessionData(constants.SESSION_KEY_SUBSCRIPTION,JSON.stringify({...session.sub}))
        if(isFutureDate(session.edt)){
          navigate('/institute/dashboard')
        }else{
          navigate('/institute/subscription')
        }
      }else if(response && response.toString().includes('Incorrect username or password')){
        setSnackBar({show:true,severity:'error',message:errors.E001})
      }else if(response && response.toString().includes('Token is Invalid!')){
        setSnackBar({show:true,severity:'error',message:errors.E002})
      }else if(response && response.toString().includes('Network error')){
        setSnackBar({show:true,severity:'error',message:errors.E006})
      }else{
        setSnackBar({show:true,severity:'error',message:response.toString()})
      }
    } catch (error) {
      setSnackBar({show:true,severity:'error',message:errors.E003})
    } finally {
      setSigninLoading(false)
    }
  }

  const signUpForm = Yup.object().shape({
    fnm: getFullNameValidator(),
    eml: getUserNameValidator(),
    pwd: getPasswordValidator(),
    cpwd: Yup.string().oneOf([Yup.ref('pwd'), null], 'Passwords must match')
  });
  const { handleSubmit:validateSignUpForm, reset:resetSignUpForm, control:controlSignUpForm } = useForm({mode:'onTouched', resolver: yupResolver(signUpForm)});
  const handleSignup = async(formData) => {
    setSignupLoading(true)
    try {
      const response = await signup(formData.fnm,formData.eml,formData.pwd)
      if(response && response.username){
        setSnackBar({show:true,severity:'success',message:alerts.A001})
        resetSignUpForm()
        resetSignInForm({eml: formData.eml})
        setTabId(0)
      }else if(response.toString().includes('User already exists')){
        resetSignUpForm()
        setSnackBar({show:true,severity:'error',message:errors.E007})
      }else {
        resetSignUpForm()
        setSnackBar({show:true,severity:'error',message:errors.E008})
      }
    } catch (error) {
      resetSignUpForm()
      setSnackBar({show:true,severity:'error',message:errors.E008})
    } finally {
      setSignupLoading(false)
    }
  }

  const handleSendEmail = () => {
    document.location.href = "mailto:"
  }

  const handleWhatsApp = () => {
    window.open('https://api.whatsapp.com' , '_blank')
  }

  var { setOpenModel } = React.useContext(AppContext);
  const handleLink = (action) => {
    if(action === 'terms-and-conditions'){
      setOpenModel({tandc:true})
      window.dataLayer.push({event:'page_view',event_category:'footer',event_action:'click',event_label:'terms-and-conditions'})
    }else if(action === 'privacy-policy'){
      setOpenModel({pcypolicy:true})
      window.dataLayer.push({event:'page_view',event_category:'footer',event_action:'click',event_label:'privacy-policy'})
    }
  }

  return (
    <>
      <MUISnackBar open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
      <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }} paddingTop={5} marginBottom={10}>
          <Grid item xs={4} sm={8} md={5} sx={{display: { xs: "none", md: "block" }}} align="center">
            <Img src="/images/books.png" />
          </Grid>
          {tabId === 0 &&
            <Grid item xs={4} sm={8} md={5} align="center">
              <Stack direction="column" spacing={2} width={{xs:0.7, md:0.5}}>
                <Typography id="institute-login-title" variant="h4">Sign in</Typography>
                <div/>
                <MUITextField id="institute-login-eml" label="Email" name="eml" control={controlSignInForm} required/>
                <MUITextField id="institute-login-pwd" label="Passsword" name="pwd" type="password" control={controlSignInForm} required/>
                <Typography variant='body2'>You accept <Link id="footer-terms-and-conditions" component="button" onClick={()=>handleLink("terms-and-conditions")}>Terms and Conditions</Link> and <Link id="footer-privacy-policy" component="button" onClick={()=>handleLink("privacy-policy")}>Privacy Policy</Link></Typography>
                <MUIButton id="institute-login-btn" loading={signinLoading} onClick={validateSignInForm(handleSignIn)} label="Sign in" variant="contained" type="secondary" />
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Link onClick={()=>handleTabSwitch('signup')} variant="body2">New account?</Link>
                  <Link onClick={()=>handleTabSwitch('forgot')} variant="body2">Forgot password?</Link>
                </Stack>
              </Stack>
            </Grid>
          }
          {tabId === 1 &&
            <Grid item xs={4} sm={8} md={5} align="center">
              <Stack direction="column" spacing={2} width={{xs:0.7, md:0.6}}>
                <Typography id="institute-login-title" variant="h4">Sign up</Typography>
                <div/>
                <MUITextField id="institute-signup-fnm" label="Your Full Name" name="fnm" control={controlSignUpForm} required maxLength={80}/>
                <MUITextField id="institute-signup-eml" label="Login Email" name="eml" control={controlSignUpForm} required maxLength={80}/>
                <MUITextField id="institute-signup-pwd" label="Create Passsword" name="pwd" type="password" control={controlSignUpForm} required maxLength={80}/>
                <MUITextField id="institute-signup-cpwd" label="Confirm Passsword" name="cpwd" type="password" control={controlSignUpForm} required maxLength={80}/>
                <Typography variant='body2'>You accept <Link id="footer-terms-and-conditions" component="button" onClick={()=>handleLink("terms-and-conditions")}>Terms and Conditions</Link> and <Link id="footer-privacy-policy" component="button" onClick={()=>handleLink("privacy-policy")}>Privacy Policy</Link></Typography>
                <MUIButton id="institute-signup-btn" loading={signupLoading} onClick={validateSignUpForm(handleSignup)} label="Sign up" variant="contained" type="secondary" />
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Link onClick={()=>handleTabSwitch('signin')}><Typography variant='body1'>Already have an account?</Typography></Link>
                </Stack>
              </Stack>
            </Grid>
          }
          {tabId === 2 &&
            <Grid item xs={4} sm={8} md={5} align="center">
              <Stack direction="column" spacing={2} width={{xs:0.7, md:0.5}}>
                <Typography id="institute-login-title" variant="h4">Forgot password</Typography>
                <Typography id="institute-login-title" variant="body1">Please contact us via below options</Typography>  
                <div>
                  <Stack direction="row" spacing={{ xs: 2, sm: 2, md: 4 }} marginLeft={{ xs: 8}} justifyContent={'center'}>
                    <Card sx={{ minWidth: 200}} variant='outlined' justifyContent={'flex-start'}>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          Email:&nbsp;&nbsp;<Button variant='outlined' endIcon={<MailOutlineIcon />} size="small" color='info' onClick={()=>handleSendEmail()}>Send</Button>
                        </Typography>
                        <img alt="" src="/images/84h44.png" height={25} style={{verticalAlign:'bottom'}} />
                      </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 220}} variant='outlined'>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          WhatsApp:&nbsp;&nbsp;<Button variant='outlined' endIcon={<WhatsAppIcon />} size="small" color='info' onClick={()=>handleWhatsApp()}>Send</Button>
                        </Typography>
                        <img alt="" src="/images/933bss.png" height={25} style={{verticalAlign:'bottom'}} />
                      </CardContent>
                    </Card>
                  </Stack>
                </div>
                <Stack direction={'row'} spacing={2} justifyContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <Link onClick={()=>handleTabSwitch('signup')} variant="body2">Sign up</Link>
                  <Link onClick={()=>handleTabSwitch('signin')} variant="body2">Sign in</Link>
                </Stack>
              </Stack>
            </Grid>
          }

          {tabId === 3 &&
            <Grid item xs={4} sm={8} md={5} align="center">
              <Stack direction="column" spacing={2} width={{xs:0.7, md:0.5}}>
                <Typography id="institute-login-title" variant="h4">Sign up</Typography>
                <Typography id="institute-login-title" variant="body1">Please contact us via below options</Typography>  
                <div>
                  <Stack direction="row" spacing={{ xs: 2, sm: 2, md: 4 }} marginLeft={{ xs: 8}} justifyContent={'center'}>
                    <Card sx={{ minWidth: 200}} variant='outlined' justifyContent={'flex-start'}>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          Email:&nbsp;&nbsp;<Button variant='outlined' endIcon={<MailOutlineIcon />} size="small" color='info' onClick={()=>handleSendEmail()}>Send</Button>
                        </Typography>
                        <img alt="" src="/images/84h44.png" height={25} style={{verticalAlign:'bottom'}} />
                      </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 220}} variant='outlined'>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          WhatsApp:&nbsp;&nbsp;<Button variant='outlined' endIcon={<WhatsAppIcon />} size="small" color='info' onClick={()=>handleWhatsApp()}>Send</Button>
                        </Typography>
                        <img alt="" src="/images/933bss.png" height={25} style={{verticalAlign:'bottom'}} />
                      </CardContent>
                    </Card>
                  </Stack>
                </div>
                <Stack direction={'row'} spacing={2} justifyContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <Link onClick={()=>handleTabSwitch('signup')} variant="body2">Sign up</Link>
                  <Link onClick={()=>handleTabSwitch('signin')} variant="body2">Sign in</Link>
                </Stack>
              </Stack>
            </Grid>
          }
          
      </Grid>
      <TermsAndConditionsPage />
      <PrivacyPolicyPage />
    </>
  );
};

export default LoginPage;

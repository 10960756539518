import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';

import LoginIcon from '@mui/icons-material/Login';

import ReplayIcon from '@mui/icons-material/Replay';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';

import DownloadIcon from '@mui/icons-material/Download';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import PaymentIcon from '@mui/icons-material/Payment';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

const MUIButton = (props) => {
  let show = props.display === false ? false : true;
  const variant = props.variant ? props.variant : 'contained'
  const size = variant === 'contained' ? 'medium' : 'small'

  const renderIcon = (title) => {
    switch(title) {
      case 'Sign up':
        return <AddToQueueIcon />;
      case 'Sign in':
        return <LoginIcon />;
      case 'Search':
        return <SearchIcon />;
      case 'Add':
        return <AddCircleOutlineIcon />;
      case 'Create':
        return <AddCircleOutlineIcon />;
      case 'New Test':
          return <AddCircleOutlineIcon />;
      case 'Reload':
        return <ReplayIcon />;
      case 'Start':
        return <PlayCircleOutlineIcon />;
      case 'Cancel':
        return <DeleteOutlineIcon />;
      case 'Update':
        return <UpgradeIcon />;
      case 'Upgrade':
        return <UpgradeIcon />;
      case 'Delete':
        return <DeleteOutlineIcon />;
      case 'Complete':
        return <CheckCircleOutlineIcon />;
      case 'Take Test':
        return <PlayCircleOutlineIcon />;
      case 'Take Exam':
        return <PlayCircleOutlineIcon />;
      case 'Save':
        return <SaveIcon />;
      case 'Submit':
        return <SendIcon />;
      case 'Refresh':
        return <RefreshIcon />;
      case 'PDF':
        return <DownloadIcon />;
      case 'Expand':
        return <UnfoldMoreIcon />;
      case 'Collapse':
        return <UnfoldLessIcon />;
      case 'Previous':
        return <KeyboardArrowLeftIcon />;
      case 'Next':
        return <KeyboardArrowRightIcon />;
      case 'UPI Pay':
        return <QrCode2Icon />;
      case 'Stripe Pay':
        return <PaymentIcon />;
      default:
        return '';
    }
  }

  return (
    <>
      {show && 
        <LoadingButton  
              id={props.id}
              variant={variant} 
              size={props.size || size} 
              color={props.type} 
              onClick={props.onClick} 
              endIcon={renderIcon(props.label)}
              loading={props.loading}
              loadingPosition="end"
              disabled={props.disabled}
            >{props.label}</LoadingButton>
      }
    </>
  );
};

export default MUIButton;
import React from 'react';
import MUIButton from './fields/MUIButton';
import html2canvas from 'html2canvas'; 
import jsPDF from 'jspdf';  
import { copyrightText, disclaimerText } from '../../util/utility';

const PDFDownload = (props) => {
    const [downloading, setDownloading] = React.useState(false)
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handlePDFDownload = async () => {
      setDownloading(true)
      // Do any parent actions, if execute exist, expand the panel
      if(props.execute != undefined){
          props.execute(true)
          // Delay 1 sec for accordion to open all
          await delay(1000);
      }

      var logoImg = new Image();
      logoImg.src = '/images/logo_full.png';
      logoImg.onload = function() {
        const input = document.getElementById(props.id);  
        html2canvas(input)  
            .then((canvas) => {
                const imgWidth = 190;
                const pageHeight = 260;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let x = 10;
                let y = 25;
                heightLeft -= pageHeight;
                const pdf = new jsPDF('p', 'mm');

                // Header
                pdf.rect(0, 0, imgWidth + 20, 20, 'F'); // filled square
                pdf.addImage(logoImg, 'PNG', 5, 5, 50, 10, 'logo'); // Cache the image using the alias 'logo'

                // Add content
                pdf.addImage(canvas, 'PNG', x, y, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                  y = heightLeft - imgHeight;
                  pdf.addPage();
                  pdf.addImage(canvas, 'PNG', x, y, imgWidth, imgHeight, '', 'FAST');
                  heightLeft -= pageHeight;
                }
                
                // Footer
                pdf.rect(0, pageHeight+25, imgWidth + 20, 15, 'F'); // filled square
                pdf.setTextColor(255,255,255);
                pdf.setFontSize(8)
                pdf.text(5, pageHeight+30, copyrightText());
                pdf.setFontSize(6)
                pdf.text(5, pageHeight+35, disclaimerText());

                pdf.save(props.fileName, { returnPromise: true }).then(() => {
                    // if execute exist, collapse the panel
                    if(props.execute != undefined){
                        props.execute(false)
                    }
                    setDownloading(false)
                });
            })
      };
  }

  return (
    <MUIButton id={props.id+'-pdf'} loading={downloading} size='small' onClick={handlePDFDownload} label="PDF" variant="outlined" type="info"/>
  );
};

export default PDFDownload;
import React from 'react';
import { useDeleteTestEventResponse, useGetTestEvent, useGetTestEventResults, useUpdateTestEvent, useDeleteTestEvent } from '../../../services/testevent-services';
import constants from '../../../util/constants';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import errors from '../../../util/errors';
import Swal from 'sweetalert2';
import { extractMonth, extractYear, getDisplayDate, getDisplayTime, getStatusValue, getTestTypeValue } from '../../../util/utility';
import { putSessionData } from '../../../services/storage-services';
import MUIResultsTable from '../../../components/widgets/tables/MUIResultsTable';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MUIAnswersAccordion from '../../../components/widgets/accordion/MUIAnswersAccordion';
import PDFDownload from '../../../components/widgets/PDFDownload';
import MUISnackBar from '../../../components/widgets/MUISnackBar';
import alerts from '../../../util/alerts';
import { AppContext } from '../../../AppContext';

const TestEventDetailsPage = () => {
  const navigate = useNavigate();

  // Form data states 
  const [eventId, setEventId] = React.useState(useParams().eventId);
  const [tabId, setTabId] = React.useState(-1);
  const [showAllAccordions, setShowAllAccordions] = React.useState(false);
  
  const { setHeaderTitle } = React.useContext(AppContext);
  React.useEffect(() => {
    setHeaderTitle('Test Event Details')
    const arg = { 
      pathParams: { eventId: eventId }
    }
    triggerTestEvent(arg)
  }, []); // Run once

  const [displayCancelButton, setDisplayCancelButton] = React.useState(false);
  const [displayCompleteButton, setDisplayCompleteButton] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({show:false});
  
  const determineTab = (status) => {
    if(status && status === constants.STATUS_CODE_SCHEDULED){
      setTabId(0)
      setDisplayCancelButton(true)
      setDisplayCompleteButton(false)
    }else if(status && status === constants.STATUS_CODE_INPROGRESS){
      setTabId(1)
      setDisplayCancelButton(true)
      setDisplayCompleteButton(true)
    }else if(status && status === constants.STATUS_CODE_COMPLETED){
      setTabId(1)
      setDisplayCancelButton(false)
      setDisplayCompleteButton(false)
    }else if(status && status === constants.STATUS_CODE_CANCELLED){
      setTabId(0)
      setDisplayCancelButton(false)
      setDisplayCompleteButton(false)
    }
  }
  
  const {data:testEvent, error:testEventError, trigger:triggerTestEvent, isMutating:testEventMutating } = useGetTestEvent()
  React.useEffect(() => {
    if(testEvent && testEvent.id){
      determineTab(testEvent.sts);
      setSnackBar({show:true,severity:'success',message:alerts.A110})
    }else if(testEvent?.errs && testEvent?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:testEvent.errs[0].msg})
    }else if(testEventError){
      setSnackBar({show:true,severity:'error',message:errors.E103})
    }
  }, [testEvent]); // Run the effect whenever the data changes

  // This will be invoked as needed
  const {data:updateTestEventData, error:updateTestEventError, trigger:triggerUpdateTestEvent, isMutating:updateTestEventMutating} = useUpdateTestEvent()

  // This will be invoked as needed
  const {data:deleteTestEventResponse, error:deleteTestEventResponseError, trigger:triggerDeleteTestEventResponse, isMutating:deleteTestEventResponseMutating} = useDeleteTestEventResponse()

  // Test Results
  const {data:testEventResults, error:testEventResultsError, trigger:triggerGetTestEventResults, isMutating:testEventResultsMutating } = useGetTestEventResults()
       
  // Handle the Test status
  const handleTestStart = async() => {
      const arg = { 
        pathParams: { eventId: eventId },
        requestBody: {sts:constants.STATUS_CODE_INPROGRESS},
        queryParams: {year:extractYear(testEvent.sdt), month:extractMonth(testEvent.sdt)} 
      }
      await triggerUpdateTestEvent(arg)
      if(!updateTestEventError){
        if(updateTestEventData?.errs && updateTestEventData?.errs.length > 0){
            setSnackBar({show:true,severity:'error',message:updateTestEventData.errs[0].msg})
        }else{
            setSnackBar({show:true,severity:'success',message:alerts.A106})
            putSessionData(constants.SESSION_KEY_TEST_EVENT,JSON.stringify({...testEvent, stm:arg.requestBody.stm}))
            determineTab(constants.STATUS_CODE_INPROGRESS)
            triggerTestEvent({ pathParams: { eventId: eventId } })
        }
      }else{
          Swal.fire('Error', 'Unable to start Test Event, please retry again.','error')
      }
  };

  const handleTestComplete = async() => {
      const arg = { 
        pathParams: { eventId: eventId },
        requestBody: {sts:constants.STATUS_CODE_COMPLETED},
        queryParams: {year:extractYear(testEvent.sdt), month:extractMonth(testEvent.sdt)} 
      }
      await triggerUpdateTestEvent(arg)
      if(!updateTestEventError){
        if(updateTestEventData?.errs && updateTestEventData?.errs.length > 0){
            setSnackBar({show:true,severity:'error',message:updateTestEventData.errs[0].msg})
        }else{
          setSnackBar({show:true,severity:'success',message:alerts.A107})
          putSessionData(constants.SESSION_KEY_TEST_EVENT,JSON.stringify({...testEvent, etm:arg.requestBody.etm}))
          determineTab(constants.STATUS_CODE_COMPLETED);
          triggerTestEvent({ pathParams: { eventId: eventId } })
        }
      }else{
          Swal.fire('Error', 'Unable to completed Test Event, please retry again.','error')
      }
  };

  const handleTestCancel = async() => {
    Swal.fire({
        title: "Are you sure ?",
        text: "Once cancelled, you will not be able to recover this test event!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, cancel it!'
    })
    .then((willCancel) => {
        if(willCancel.isConfirmed) {
          cancelTestEvent()
        }
    });
  };
  // This will be invoked as needed
  const {data:deleteTestEventData, error:deleteTestEventError, trigger:triggerDeleteTestEvent, isMutating:deleteTestEventMutating} = useDeleteTestEvent()

  const cancelTestEvent = async() => {
    const arg = { 
      pathParams: { eventId: eventId },
      requestBody: {sts:constants.STATUS_CODE_CANCELLED},
      queryParams: {year:extractYear(testEvent.sdt), month:extractMonth(testEvent.sdt)} 
    }
    await triggerDeleteTestEvent(arg)
    if(!deleteTestEventError){
      if(deleteTestEventData?.errs && deleteTestEventData?.errs.length > 0){
          setSnackBar({show:true,severity:'error',message:deleteTestEventData.errs[0].msg})
      }else{
          navigate('/institute/test/events')  
          setSnackBar({show:true,severity:'success',message:alerts.A111})
      }
    }else{
        Swal.fire('Error', 'Unable to delete Test Event, please retry again.','error')
    }
  }

  const handleDeleteResponse = async(responseId) => {
    Swal.fire({
        title: "Are you sure ?",
        text: "Once deleted, you will not be able to recover this reponse!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
    })
    .then((willDelete) => {
        if(willDelete.isConfirmed) {
          deleteTestResponse(responseId)
        }
    });
  };
  const deleteTestResponse = async(responseId) => {
    const arg = { 
      pathParams: { eventId: eventId, responseId: responseId}
    }
    await triggerDeleteTestEventResponse(arg)
  }

  const handleRefreshResults = async () => {
    const arg = { 
      pathParams: { eventId: eventId }
    }
    await triggerGetTestEventResults(arg)
  }

  return (
    <Grid id="institute-test-result-page" container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
        <MUISnackBar open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
        <Stack direction="column" spacing={1} sx={{ width: 1 }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Grid item xs={4} sm={8} md={10} marginTop={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant='h5'><strong>Title:</strong></Typography>
                <Typography variant='h5'>{testEvent?.ttl}</Typography>
                <PDFDownload id='institute-test-result-page' execute={(open) => setShowAllAccordions(open)} fileName={'Test-Results-'+testEvent?.etm+'.pdf'} />
              </Stack>
            </Grid>
            <Grid item xs={4} sm={8} md={10}>
              <Divider />
            </Grid>
            <Grid item xs={4} sm={2} md={2.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Event Id:</strong></Typography>
                  <Typography id="testevent-id" variant='body1'>{testEvent?.id}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Type:</strong></Typography>
                  <Typography variant='body1'>{getTestTypeValue(testEvent?.typ)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={4} md={5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Status:</strong></Typography>
                  <Typography id="testevent-status-txt" variant='body1'>{getStatusValue(testEvent?.sts)}</Typography>
                </Stack>
            </Grid>

            <Grid item xs={4} sm={2} md={2.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Scheduled Date:</strong></Typography>
                  <Typography variant='body1'>{getDisplayDate(testEvent?.sdt)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Test Started:</strong></Typography>
                  <Typography id="testevent-stm-txt" variant='body1'>{getDisplayTime(testEvent?.stm)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={4} md={5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Test Ended:</strong></Typography>
                  <Typography id="testevent-etm-txt" variant='body1'>{getDisplayTime(testEvent?.etm)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Total Questions:</strong></Typography>
                  <Typography variant='body1'>{testEvent?.qcnt}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='body1'><strong>Location:</strong></Typography>
                  <Typography variant='body1'>{testEvent?.loc}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
                <Stack direction="row" spacing={1}>
                  {testEvent?.tlmt? <Typography variant='body1'><strong>Time Limit:</strong></Typography>  : ''}
                  {testEvent?.tlmt? <Typography variant='body1'>{testEvent.tlmt} minutes </Typography> : ''}
                </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={5}>
                <Stack direction="row" spacing={1}>
                  {testEvent?.nscr? <Typography variant='body1'><strong>Negative Score:</strong></Typography>  : ''}
                  {testEvent?.nscr? <Typography variant='body1'>{testEvent.nscr} </Typography> : ''}
                </Stack>
            </Grid>

            {tabId === 0 &&
              <Grid item xs={4} sm={8} md={10} marginTop={4}>
                {testEvent.sts && testEvent.sts === constants.STATUS_CODE_SCHEDULED ? (
                  <Stack direction="row" spacing={2}>
                    <MUIButton id="institute-testevent-start" loading={updateTestEventMutating} onClick={() => handleTestStart()} label="Start" type="secondary"/>
                    <MUIButton id="institute-testevent-cancel" loading={deleteTestEventMutating} display={displayCancelButton} onClick={() => handleTestCancel()} label="Cancel" type="error"/>
                  </Stack>
                ) : ( 
                  <p style={{ color: 'red' }}>Test event is cancelled!</p>
                )}
              </Grid>
            }

            {tabId === 1 && 
                <>
                  <Grid item xs={4} sm={8} md={10} marginTop={3}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant='h5'>Test Results: </Typography>
                        <MUIButton loading={testEventResultsMutating} onClick={handleRefreshResults} label="Refresh" variant="outlined" type="info"/>
                    </Stack>
                  </Grid>
                  <Grid item xs={4} sm={8} md={10}>
                    <MUIResultsTable data={testEventResults} onDeleteResponse={handleDeleteResponse}/>
                  </Grid>

                  <Grid item xs={4} sm={8} md={10} marginTop={2}>
                    <Stack direction="row" spacing={1} marginBottom={1}>
                        <Typography variant="h5">Test Answers:</Typography>
                        {testEvent?.qcnt > 0 ? <MUIButton onClick={() => setShowAllAccordions(true)} label="Expand" variant="outlined" type="info"/> : ''}
                        {testEvent?.qcnt > 0 ? <MUIButton onClick={() => setShowAllAccordions(false)} label="Collapse" variant="outlined" type="info"/> : ''}
                    </Stack>
                  </Grid>
                  <Grid item xs={4} sm={8} md={10}>
                    <MUIAnswersAccordion showAll={showAllAccordions} questions={testEvent.qtns} />
                  </Grid>
                  
                  <Grid item xs={4} sm={8} md={10} marginTop={4}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <MUIButton loading={updateTestEventMutating} display={displayCompleteButton} onClick={() => handleTestComplete()} label="Complete" type="secondary"/>
                      <MUIButton loading={updateTestEventMutating} display={displayCancelButton} onClick={() => handleTestCancel()} label="Cancel" type="error"/>
                    </Stack>
                  </Grid>
                </>
            }
          </Grid>
        </Stack>
    </Grid>
  );
};

export default TestEventDetailsPage;

import * as React from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import QuizIcon from '@mui/icons-material/Quiz';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Box, styled } from '@mui/material';
import { clearSessionData } from '../../../services/storage-services';

const categories = [
  {
    pid: 'Home',
    children: [
      { cid: 'dashboard', ttl: 'Dashboard', icon: <DashboardIcon />, href: '/institute/dashboard' }
    ],
  },
  {
    pid: 'Test Section',
    children: [
      { cid: 'testbank', ttl: 'Test Bank', icon: <QuizIcon />, href: '/institute/test/bank' },
      { cid: 'testevents', ttl: 'Test Events', icon: <ViewListIcon/>, href: '/institute/test/events'}
    ],
  },
  {
    pid: 'Admin',
    children: [
      { cid: 'institute', ttl: 'Institute', icon: <AccountBalanceIcon />, href: '/institute/profile'},
      { cid: 'subscription', ttl: 'Subscription', icon: <SubscriptionsIcon />, href: '/institute/subscription'}
    ],
  },
  {
    pid: 'Account',
    children: [
      { cid: 'profile', ttl: 'Profile', icon: <AccountBoxIcon />, href: '/institute/user/profile'},
      { cid: 'logout', ttl: 'Logout', icon: <LogoutIcon />, href: '' }
    ],
  }
];

export default function PrivateSidebar(props) {

  const location = useLocation()
  const navigate = useNavigate();

  const handleMenuClick = (pid, cid, href) => {
    if(pid === 'logout'){
      clearSessionData()
    }
    navigate(href)
  };

  const Img = styled('img')({
    marginTop: 18,
    marginLeft: 15,
    display: 'block',
    width: '60%',
    height: 'auto'
  });

  return (
    <>
        <Box sx={{bgcolor: 'primary.main'}} height={70}>
          <Link href="/">
            <Img src="/images/logo_full.png" />
          </Link>
        </Box>
        {categories.map(({pid, children }) => (
          <div>
            <Divider />
            <List key={'list-'+pid}>
              {children.map(({cid, ttl, icon, href}) => (
                <ListItem disablePadding>
                  <ListItemButton id={'sidemenu-'+cid+'-btn'} selected={href === location.pathname} onClick={() => {handleMenuClick(pid, cid, href)}}>
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={ttl} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        ))}
    </>
  );
}
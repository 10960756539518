export default Object.freeze({
    E000: 'Your session expired, please login again.',

    // Institute Test Error
    E001: 'Incorrect username or password, please try again.',
    E002: 'Unable to login, please try again.',
    E003: 'Unable to login, please try again.',
    E004: 'Unable to update test questions, please try again.',
    E005: 'Unable to get test questions, please try again.',
    E006: 'Network error, please check your internet and try again.',
    E007: 'Unable to signup, User already exist with provided email.',
    E008: 'Unable to signup, please try again.',
    E009: 'Attempt limit exceeded, please try after some time.',
    E010: 'Unable to change password, please try again.',

    // Attendee Test Errors
    E101: 'Please choose your answer and click Save.',
    E102: 'Unable to get test event details, please try again.',
    E103: 'Unable to save test event details, please try again.',
    E104: 'Unable to submit test event response, please try again.',
    E105: 'Unable to get test event results, please try again.',
    E106: 'Unable to create purchase item, please try again.',
    E107: 'Unable to get test event details, please try again.',

    // Practice Exam Error
    E201: 'Unable to get purchase item, please retry again.',
    E202: 'Unable to get purchase item, please retry again.',
    E203: 'Please choose your answer and click Save.',
    E204: 'Please accept Terms and Conditions checkbox.',
    E205: 'Unable to submit exam response, please try again.',
    E206: 'Unable to start exam, please try again.',
    E207: 'Unable to get purchase item, please retry again.',
})
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { AppContext } from '../../../AppContext';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ContactUs = (props) => {

  var [expanded, setExpanded] = React.useState();
  var { setOpenModel } = React.useContext(AppContext);

  const handleSendEmail = () => {
    document.location.href = "mailto:"
  }

  const handleWhatsApp = () => {
    window.open('https://api.whatsapp.com' , '_blank')
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack direction={{xs: "column", md: "row"}} spacing={{ xs: 4, sm: 4, md: 4 }} width={1}>
        <div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>Can i cancel practice exam after purchase ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Sorry, you <strong>can not cancel</strong> the purchase once it is successful, if you are looking for refund due to any technical issues, please contact us. Please refer our <Link onClick={()=>setOpenModel({tandc:true})} component={'button'}>refund policy</Link> for further reading.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>How long the practice exam is valid after purchase ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Practice exam is valid for <strong>30 days</strong> from the date of purchase, with in these 30 days, only <strong>3 final submissions</strong> are allowed. Any partical attempts are Not counted towards final submissions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>I did not get confirmation email even though my purchase is completed ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Please verify <strong>spam or junk</strong> mail box for the email, sometimes our email might have wrongly marked as spam or junk by email filters. If you still not able to find confirmation email, please contact us.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography>How can I get help with my practice exam purchase ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you are facing issues with purchase like, payment issues, bank or financial issues, please contact with your financial institute. For any other issues, please contact us.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Stack direction={{xs:'column',sm:'row'}} spacing={{ xs: 2, sm: 2, md: 4 }} justifyContent={'flex-start'}>
            <Card sx={{ minWidth: 200}} variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Email:&nbsp;&nbsp;<Button variant='outlined' endIcon={<MailOutlineIcon />} size="small" color='info' onClick={()=>handleSendEmail()}>Send</Button>
                </Typography>
                <img alt="" src="/images/84h44.png" height={25} style={{verticalAlign:'bottom'}} />
              </CardContent>
            </Card>
            <Card sx={{ minWidth: 220}} variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  WhatsApp:&nbsp;&nbsp;<Button variant='outlined' endIcon={<WhatsAppIcon />} size="small" color='info' onClick={()=>handleWhatsApp()}>Send</Button>
                </Typography>
                <img alt="" src="/images/933bss.png" height={25} style={{verticalAlign:'bottom'}} />
              </CardContent>
            </Card>
          </Stack>
        </div>
    </Stack>
  );
};

export default ContactUs;

import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { getSessionData } from './storage-services';
import constants from '../util/constants';

const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

// Verifier that expects valid access tokens:
const verifier = CognitoJwtVerifier.create({
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    tokenUse: "id",
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID
});

const userpool = new CognitoUserPool(poolData);
var user = undefined;

export const signup = (fullName,email,password)=>{
    return new Promise((resolve)=>{
        var validationDataList = [];
        validationDataList.push(new CognitoUserAttribute({Name: 'fullName', Value: fullName}))

        let defaultCnt = 'USA'
        const country = getSessionData(constants.SESSION_KEY_COUNTRY)
        if(country && country!=='undefined'){
            defaultCnt = JSON.parse(country).cnt
        }
        validationDataList.push(new CognitoUserAttribute({Name: 'country', Value: defaultCnt}))

        userpool.signUp(email,password,null,validationDataList,(err, data) => {
            if (err) {
                resolve(err);
            }else{
                var cognitoUser = data.user;
                resolve(cognitoUser)
            }
        });
    });
};

export const authenticate = (email,password)=>{
    user = new CognitoUser({Username:email,Pool:userpool});
    return new Promise((resolve)=>{
        const authDetails= new AuthenticationDetails({
            Username:email,
            Password:password
        });

        user.authenticateUser(authDetails,{
            onSuccess:async (result)=>{
                // Decode JWT ID Token and get sessionID
                try {
                    const payload = await verifier.verify(result.getIdToken().getJwtToken());
                    resolve({session: payload['session'], idToken: result.getIdToken().getJwtToken()})
                } catch {
                    resolve('Token is Invalid!')
                }
            },
            onFailure:(err)=>{
                resolve(err)
            }
        });
    });
};

export const changePassword = (oldPassword,newPassword)=>{
    return new Promise((resolve)=>{  
        user.changePassword(oldPassword,newPassword,(err, data) => {
            if (err) {
                resolve(err);
            }else{
                resolve({success:true});
            }
        });
    });
};

export const logout = () => {
    const user = userpool.getCurrentUser();
    user.signOut();
    window.location.href = '/';
};
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { getSessionData } from '../../../services/storage-services';
import constants from '../../../util/constants';

const PaymentResults = (props) => {
  var navigate = useNavigate();
  var [openDialog, setOpenDialog] = React.useState(false);
  var [purchase] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_PURCHASE)));

  React.useEffect(() => {
    if(purchase && purchase.id){
        setOpenDialog(true)
        if(props.result === 'purchase-success'){
            window.dataLayer.push({event: 'purchase', ecommerce:{transaction_id: purchase.id, currency: purchase.cur, value: purchase.amt, items: [{item_id: purchase.exam.id, item_name: purchase.exam.ttl}]}});
        }
    }
  }, [purchase]); // Run this only once

  const handleExamLink = (id) => {
    window.open('/practice-exam/attempt/'+id, "_blank", "noreferrer");
  }

  return (
    <Dialog open={openDialog}>
        {props.result === 'purchase-success' &&
            <>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Typography fontSize={24} color={'green'}>Thank You, Payment Received</Typography>
                    <IconButton onClick={() => {setOpenDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid item xs={4} sm={8} md={10} marginBottom={1}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant='body1'>Hello <strong>{purchase?.fn}&nbsp;{purchase?.ln}</strong>, Thank You for your purchase.</Typography>
                            <Typography variant='body2'>
                                We received your payment and it is under process. We will send a confirmation email as soon as payment is confirmed.
                            </Typography>
                            <Typography variant='body2  '>
                                Please verify your email inbox and spam folders, we will send email to: <strong>{purchase?.eml}</strong>.
                            </Typography>
                            <Divider />
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={8} md={10} marginBottom={1}>
                        <Stack direction="column" spacing={1}>
                            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                                <Grid item xs={4} sm={8} md={10}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><strong>Title:</strong></Typography>
                                    <Typography>{purchase?.ttl}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={2} md={3}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><>Questions:</></Typography>
                                    <Typography variant='body1'>{purchase?.exam.qcnt}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={3} md={3}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><>Level:</></Typography>
                                    <Typography variant='body1'>{purchase?.exam.lvl}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><>Duration:</></Typography>
                                    <Typography variant='body1'>{purchase?.exam.dur} Minutes</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={2} md={3}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><>Validity:</></Typography>
                                    <Typography variant='body1'>{purchase?.exam.val} days</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4}>
                                    <Stack direction="row" spacing={1}>
                                    <Typography variant='body1'><>Submissions:</></Typography>
                                    <Typography variant='body1'>{purchase?.exam.atmt} times</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Stack direction="column" spacing={1} marginTop={2}>
                        <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                            <Grid item xs={4} sm={8} md={10}>
                                <Stack direction="row" spacing={1} alignItems={'baseline'}>
                                    <Typography variant='body1'><strong>Ready to take exam?</strong></Typography>
                                    <Button id="start-exam-btn" variant="outlined" size='small' color="info" onClick={()=>{handleExamLink(purchase.id)}}>Take Exam</Button>
                                </Stack>
                                <Stack direction="column" spacing={1} alignItems={'baseline'} marginTop={2}>
                                    <Typography variant='body1'><strong>Not yet ready?</strong> dont worry you can take exam later using link provided in the email.</Typography>
                                    <Typography variant='body2'><strong>Got any questions? Need help?</strong> refer <a onClick={()=>{setOpenDialog(false)}} href="/#contactus">contact us</a> section.</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </DialogContent>
            </>
        }
        {props.result === 'purchase-cancel' &&
            <>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Typography fontSize={24} color={'red'}>Something Wrong, Payment Cancelled</Typography>
                    <IconButton onClick={() => {setOpenDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid item xs={4} sm={8} md={10} marginBottom={2}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant='body1'>Hello {purchase?.fn}, Your payment is not processed or cancelled.</Typography>
                            <Typography variant='body2'>
                                You can retry the payment again by purchasing our practice exams.
                            </Typography>
                            <Typography variant='body2'><strong>Got any questions? Need help?</strong> refer <a href="">contact us</a> section.</Typography>
                            <Divider />
                        </Stack>
                    </Grid>
                </DialogContent>
            </>
        }
    </Dialog>
  );
};

export default PaymentResults;

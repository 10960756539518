import useSWRMutation from 'swr/mutation'

import { deleteRequest, getRequest, postRequest, putRequest } from '../util/utility'


export const useGetTestEvents = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWR Hook will avoid invoking the API if the path parameter eventId is null
*/
export const useGetTestEvent = (arg)  => {
    const { data, error, trigger, isMutating} = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event/{eventId}', getRequest)
    return {
        data: data,
        error: error,
        trigger: trigger,
        isMutating: isMutating
    }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useCreateTestEvent = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event', postRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useUpdateTestEvent = (arg)  => {
    const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event/{eventId}', putRequest)
    return {
      data: data,
      error: error,
      trigger: trigger,
      isMutating: isMutating
    }
}

/*
  useSWRMutation, it will be invoked manual only as needed
  https://swr.vercel.app/docs/mutation#useswrmutation
*/
export const useDeleteTestEvent = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event/{eventId}', deleteRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWR Hook will avoid invoking the API if the path parameter eventId is null
  https://swr.vercel.app/docs/conditional-fetching#conditional
*/
export const useGetTestEventResults = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event/{eventId}/results', getRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}

/*
  useSWR Hook will avoid invoking the API if the path parameter eventId is null
  https://swr.vercel.app/docs/conditional-fetching#conditional
*/
export const useDeleteTestEventResponse = (arg)  => {
  const {data, error, trigger, isMutating } = useSWRMutation(() => process.env.REACT_APP_API_BASEURL + '/institute/test/event/{eventId}/response/{responseId}', deleteRequest)
  return {
    data: data,
    error: error,
    trigger: trigger,
    isMutating: isMutating
  }
}
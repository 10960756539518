import { FormControlLabel, Radio } from '@mui/material';
import React, { Children } from 'react';

const MUIRadioButton = (props) => {
  return (
    <FormControlLabel 
        id={props.id}
        name={props.name}
        control={<Radio />} 
        
        value={props.value}
        label={props.label}>
    </FormControlLabel>
  );
};

export default MUIRadioButton;
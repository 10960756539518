import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const MUITextField = (props) => {
  return (
    <Controller name={props.name} control={props.control}
      render={({ 
        field: { onChange, onBlur, value, ref }, 
        fieldState: { error } }) => (
          <TextField
            id={props.id}
            InputLabelProps={{ shrink: true }} 
            inputProps={
              {maxLength: props.maxLength ? props.maxLength : ''}
            }
            size="small"
            fullWidth 
            error={!!error} 
            helperText={error ? error.message : props.helperText}

            value={value || ''} 
            onChange={onChange} // send value to hook form
            onBlur={onBlur} // notify when input is touched
            inputRef={ref} // wire up the input ref

            {...props}/>
      )}>
    </Controller>
  );
};

export default MUITextField;
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDisplayTime } from '../../../util/utility';
import { Link } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MUIExamAttemptsTable = (props) => {
    let rows = [];
    if(props.data && props.data.length > 0){
        props.data.forEach((value, index) => {
            // Copy the element along with its index to the new array
            rows.push({ index: index + 1, ...value });
        });
    }else if(props.data && props.data.id){
        rows.push({ index: 1, ...props.data });
    }

    return (
        <TableContainer id="practiceexam-result-table" component={Paper}>
            <Table size="small">
                {rows.length < 1 &&
                    <caption>No previous submissions to desplay.</caption>
                }
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Submit ID</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Score</StyledTableCell>
                        <StyledTableCell>Result</StyledTableCell>
                        <StyledTableCell align="right">Action</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                            <StyledTableCell>{row.cdt}</StyledTableCell>
                            <StyledTableCell>{row.scr || '-' }</StyledTableCell>
                            <StyledTableCell>{row.rlt === 'P' ? 'Pass' : 'Fail'}</StyledTableCell>
                            <StyledTableCell align="right">
                                <Link component="button" onClick={() => props.onDetails(row.id)}>Details</Link>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MUIExamAttemptsTable;
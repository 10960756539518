import * as Yup from 'yup';

export const getUserNameValidator = ()  => {
    return Yup.string()
        .required('Email is required')
        .email('Provide valid email format')
}

export const getPasswordValidator = ()  => {
    return Yup.string()
        .required('Password is required')
        .min(8, 'Provide min 8 characters')
        .max(40, 'Maximum 40 characters allowed')
        .test("isValidPass", "Provide Upper, Lower & Numbers", (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 3;
            const conditions = [hasLowerCase, hasUpperCase, hasNumber];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
        })
}

export const getEventIdValidator = ()  => {
    return Yup.string()
        .required('Event Id is required')
}

export const getNameValidator = ()  => {
    return Yup.string()
        .required('Name is required')
        .matches('^[A-Za-z].*$', { message: 'Start with alphabet only', excludeEmptyString: true })
        .min(2, 'Provide minimum 2 characters')
        .max(40, 'Maximum 40 characters allowed')
        .matches('^[A-Za-z ]*$', { message: 'Only alphabet and space allowed', excludeEmptyString: true })
}

export const getFullNameValidator = ()  => {
    return Yup.string()
        .required('Name is required')
        .matches('^[A-Za-z].*$', { message: 'Start with alphabet only', excludeEmptyString: true })
        .min(2, 'Provide minimum 2 characters')
        .max(40, 'Maximum 80 characters allowed')
        .matches('^[A-Za-z ]*$', { message: 'Only alphabet and space allowed', excludeEmptyString: true })
}

export const getIdentityNumberValidator = ()  => {
    return Yup.string()
        .min(2, 'Provide minimum 2 characters')
        .max(8, 'Maximum 8 characters allowed')
}

export const getMobileValidator = ()  => {
    return Yup.string()
        .matches('[1-9]{1,1}[0-9]{9,9}', { message: 'Provide valid mobile number', excludeEmptyString: true })
        .max(10, 'Provide only 10 digits mobile')
}

export const getEmailValidator = ()  => {
    const val = Yup.string()
        .required('Email is required')
        .email('Provide valid email format')
        .max(80, 'Maximum 80 characters allowed')
    return val;
}

export const getAddressLineValidator = ()  => {
    return Yup.string()
            .matches('[1-9]{1,1}[0-9]{9,9}', { message: 'Provide valid address line', excludeEmptyString: true })
            .max(80, 'Maximum 80 characters allowed')
}

export const getFeedbackMessageValidator = ()  => {
    return Yup.string()
        .required('Message is required')
        .matches('[1-9]{1,1}[0-9]{9,9}', { message: 'Provide valid mobile number', excludeEmptyString: true })
        .max(10, 'Provide only 10 digits mobile')
        .max(40, 'Maximum 40 characters allowed')
}

export const getTitleValidator = ()  => {
    return Yup.string()
        .required('Title is required')
        .min(2, 'Provide minimum 2 characters')
        .max(80, 'Maximum 80 characters allowed')
}

export const getLocationValidator = ()  => {
    return Yup.string()
        .required('Location is required')
        .min(2, 'Provide minimum 2 characters')
        .max(40, 'Maximum 40 characters allowed')
}

export const getTypeValidator = ()  => {
    return Yup.string()
        .required('Type is required')
}

export const getFutureDateValidator = ()  => {
    return Yup.string()
        .required('Date is required')
}

export const getQuestionTextValidator = ()  => {
    return Yup.string()
        .required('Question is required')
        .min(2, 'Provide minimum 2 characters')
        .max(2056, 'Maximum 2056 characters allowed')
}

export const getAnswerOptionReqValidator = ()  => {
    return Yup.string()
        .required('Answer Option is required')
        .min(2, 'Provide minimum 2 characters')
        .max(512, 'Maximum 512 characters allowed')
}

export const getAnswerOptionOptValidator = ()  => {
    return Yup.lazy(value => {
        if (value != null) {
            return Yup.string()
                .min(2, 'Provide minimum 2 characters')
                .max(512, 'Maximum 512 characters allowed')
        }
        return Yup.mixed().notRequired();
    })
}
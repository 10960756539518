import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const MUICheckBox = (props) => {
  
    if(props.withLabel){
      return (
        <FormControlLabel
            id={props.id}
            name={props.name}
            control={<Checkbox 
                        checked={props.value}
                        onChange={props.onChange}
                    />}
                    
            label={props.label}>
        </FormControlLabel>
      )
    }else{
      return (
        <Controller name={props.name} control={props.control}
          render={({ 
            field: { onChange, onBlur, value, ref }, 
            fieldState: { error } }) => (
              <Checkbox
                id={props.id}
                size="medium"

                checked={value} 
                onChange={onChange} // send value to hook form
                onBlur={onBlur} // notify when input is touched
                inputRef={ref} // wire up the input ref

                {...props}/>
          )}>
        </Controller>
      )
    }
  
};

export default MUICheckBox;
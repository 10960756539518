import { Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';

const HowItWorks = (props) => {
  const navigate = useNavigate();

  const handleLink = (title) => {
    if(title == 'catalog'){
      props.gotoCatalog()
    }else{
      navigate('/'+title)
    }
  }

  return (
    <Container component="section" sx={{ display: 'flex' }}>
      <Stack direction={{xs:'column',sm:'row'}} justifyContent="center" mx={{ xs: 4, sm: 1, md: 4}} spacing={{xs:2, sm:2, md:5}}>

        <Grid item xs={4} sm={3} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Practice Exams
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Boost your certification preparation plan with the best online practice exams.
              </Typography>
              
            </CardContent>
            <Stack direction="row" justifyContent="center">
              <Button variant='contained' color='secondary' size="small" onClick={()=>{handleLink("catalog")}}>Practice Exams</Button>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={4} sm={4} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Institutions
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Institutions can schedule and deliver assessments to test takers in a way that works for you.
              </Typography>
            </CardContent>
            <Stack direction="row" justifyContent="center">
              <Button variant='outlined' color="info" size="small" onClick={()=>{handleLink("institute")}}>Conduct Test</Button>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={4} sm={3} md={3.3}>
          <Card sx={{ minHeight: 180 }} variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Attendees
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Students and Attendees can take online tests and download their reports.
              </Typography>
              
            </CardContent>
              <Stack direction="row" justifyContent="center">
                <Button variant='outlined' color="info" size="small" onClick={()=>{handleLink("attendee")}}>Take Test</Button>
              </Stack>
            
          </Card>
        </Grid>
      </Stack>
    </Container>
  );
};

export default HowItWorks;

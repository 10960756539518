import React from 'react';
import { useGetTestQuestions } from '../../../services/testbank-services';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { getSessionData } from '../../../services/storage-services';
import constants from '../../../util/constants';
import MUIQuestionsAccordion from '../../../components/widgets/accordion/MUIQuestionsAccordion';
import { getTestTypeValue } from '../../../util/utility';
import PDFDownload from '../../../components/widgets/PDFDownload';
import { AppContext } from '../../../AppContext';
import PrepareQuestions from '../../../components/widgets/PrepareQuestions';
import errors from '../../../util/errors';

const TestDetailsPage = () => {

    // Form data states 
    var [testId, setTestId] = React.useState(useParams().testId)
    var [testDetails, setTestDetails] = React.useState()
    var [showPrepareQuestions, setShowPrepareQuestions] = React.useState(false);
    var [showAllAccordions, setShowAllAccordions] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({show:false});

    const { setHeaderTitle } = React.useContext(AppContext);
    React.useEffect(() => {
      setHeaderTitle('Test Details')
      setTestDetails(getTestDetailsFromSession(testId))

      // Trigger get questions
      triggerTestQuestions({queryParams: { testId: testId}})
    }, []); // Run once

    const getTestDetailsFromSession = (testId) => {
        const tests = JSON.parse(getSessionData(constants.SESSION_KEY_TEST_BANK) ? getSessionData(constants.SESSION_KEY_TEST_BANK) : [])
        const filter = tests.filter((item) => item.id === testId)
        return filter ? filter[0] : {}
    }

    const {data:testQuestions, error:testQuestionsError, trigger:triggerTestQuestions, isMutating:testQuestionsMutating} = useGetTestQuestions()
    React.useEffect(() => {
        if(testQuestions && testQuestions.qtns){
            setTestDetails({...testDetails, qcnt:testQuestions.qtns.length})
        }else if(testQuestions?.errs && testQuestions?.errs.length > 0){
            setSnackBar({show:true,severity:'error',message:testQuestions.errs[0].msg})
        }else if(testQuestionsError){
            setSnackBar({show:true,severity:'error',message:errors.E005})
        }
    }, [testQuestions]); // Run the effect whenever the data changes

    const handleQuestionsWindow = (show, reload) => {
        setShowPrepareQuestions(show)
        if(reload){
            triggerTestQuestions({queryParams: { testId: testId}})
        }
    }

    return (
        <Grid id="institute-test-details-page" container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Stack direction="column" spacing={1} sx={{ width: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                    <Grid item xs={4} sm={8} md={10} marginTop={3}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant='h5'><strong>Title:</strong></Typography>
                            <Typography variant='h5'>{testDetails?.ttl}</Typography>
                            <Divider />
                            <PDFDownload id='institute-test-details-page' execute={(open) => setShowAllAccordions(open)} fileName={'test_details_'+testDetails?.id+'.pdf'} />
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant='body1'><strong>Total Questions:</strong></Typography>
                            <Typography id="testdetails-qcnt" variant='body1'>{testDetails?.qcnt}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={8}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant='body1'><strong>Type:</strong></Typography>
                            <Typography id="testdetails-type" variant='body1'>{getTestTypeValue(testDetails?.typ)}</Typography>
                        </Stack>
                    </Grid>
                    { testDetails?.typ === 'EXAM' &&
                      <>
                        <Grid item xs={4} sm={2} md={2}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Time Limit:</strong></Typography>
                                <Typography id="testdetails-tlmt" variant='body1'>{testDetails?.tlmt? testDetails.tlmt + ' minutes' : '-'}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={6} md={8}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Negative Scoring:</strong></Typography>
                                <Typography id="testdetails-nscr" variant='body1'>{testDetails?.nscr? testDetails.nscr + ' minutes' : '-'}</Typography>
                            </Stack>
                        </Grid>
                      </>
                    }
                    <Grid item xs={4} sm={8} md={10} marginTop={2}>
                        <Stack direction="row" spacing={1} marginBottom={1}>
                            <Typography variant="h5">Test Questions:</Typography>
                            {testDetails?.qcnt === 0 ? <MUIButton id="add-questions-btn" loading={testQuestionsMutating} onClick={() => setShowPrepareQuestions(true)} label="Add Questions" variant="outlined" type="secondary"/> : ''}
                            {testDetails?.qcnt > 0 ? <MUIButton id="expand-questions-btn" loading={testQuestionsMutating} onClick={() => setShowAllAccordions(true)} label="Expand" variant="outlined" type="info"/> : ''}
                            {testDetails?.qcnt > 0 ? <MUIButton id="collapse-questions-btn" loading={testQuestionsMutating} onClick={() => setShowAllAccordions(false)} label="Collapse" variant="outlined" type="info"/> : ''}
                            {testDetails?.qcnt > 0 ? <MUIButton id="edit-questions-btn" loading={testQuestionsMutating} onClick={() => setShowPrepareQuestions(true)} label="Edit Questions" variant="outlined" type="secondary"/> : ''}
                        </Stack>
                        <Divider/>
                    </Grid>
                    <Grid item xs={4} sm={8} md={10}>
                        <MUIQuestionsAccordion showAll={showAllAccordions} questions={testQuestions} />
                    </Grid>
                </Grid>
            </Stack>
            <PrepareQuestions show={showPrepareQuestions} onClose={(reload)=>handleQuestionsWindow(false,reload)} questions={testQuestions} testId={testId}/>
        </Grid>
    );
};

export default TestDetailsPage;

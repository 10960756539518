import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Divider, Link, Stack } from '@mui/material';
import { getTestTypeValue } from '../../../util/utility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MUITestBankTable = (props) => {
    const navigate = useNavigate();

    let rows = [];
    if(props.data && props.data.length > 0){
        props.data.forEach((value, index) => {
            // Copy the element along with its index to the new array
            rows.push({ index: index + 1, ...value });
        });
    }

    const handleTestView = (selectedTestId) => {
        navigate('/institute/test/bank/'+selectedTestId)
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small" id="institute-testbank-tbl">
                {rows.length < 1 &&
                    <caption>No Test to display, create new Test from menu option.</caption>
                }
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>TB.N</StyledTableCell>
                        <StyledTableCell>Test Title</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell align="right">Questions</StyledTableCell>
                        <StyledTableCell align="right">Action</StyledTableCell>
                        <StyledTableCell align="right">Action</StyledTableCell>
                        <StyledTableCell align="right">Action</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <StyledTableRow id={"testbank-row-"+row.index} key={row.index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row">{row.index}</StyledTableCell>
                        <StyledTableCell>{row.ttl}</StyledTableCell>
                        <StyledTableCell>{getTestTypeValue(row.typ)}</StyledTableCell>
                        <StyledTableCell id={"testbank-qcnt-"+row.index} align="right">{row.qcnt}</StyledTableCell>
                        <StyledTableCell align="right">
                            <Link id={"testbank-view-"+row.index} component="button" onClick={() => handleTestView(row.id)}>View</Link>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Link id={"testbank-edit-"+row.index} component="button" onClick={() => props.onEditTest(row.id)}>Edit</Link>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Link id={"testbank-createevent-"+row.index} component="button" onClick={() => props.onCreateEvent(row.id)}>Create Event</Link>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MUITestBankTable;
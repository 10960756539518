import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MUITextField from '../fields/MUITextField';
import MUIButton from '../fields/MUIButton';
import IconButton from '@mui/material/IconButton';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PaymentIcon from '@mui/icons-material/Payment';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import errors from '../../../util/errors';
import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getEmailValidator, getNameValidator } from '../../../services/validations';
import { useCreatePurchaseItem } from '../../../services/practiceexam-services';
import MUISnackBar from '../MUISnackBar';
import MUISelectField from '../fields/MUISelectField';
import constants from '../../../util/constants';
import { getSessionData, putSessionData } from '../../../services/storage-services';
import { useGetExamCatalog } from '../../../services/catalog-services';
import { AppContext } from '../../../AppContext';

const ExamsCatalog = (props) => {

  var [openExamDialog, setOpenExamDialog] = React.useState(false);
  var [selectedExam, setSelectedExam] = React.useState();
  var [profile, setProfile] = React.useState();
  var [activeStep, setActiveStep] = React.useState(0);
  var [snackBar, setSnackBar] = React.useState({show:false});
  var [showUPIPay, setShowUPIPay] = React.useState(false);
  var [tandcAccepted, setTandCAccepted] = React.useState(false);

  var { setOpenModel } = React.useContext(AppContext);
  const handleLink = (action) => {
    if(action === 'terms-and-conditions'){
      setOpenModel({tandc:true})
      window.dataLayer.push({event:'page_view',event_category:'practice-exam',event_action:'click',event_label:'terms-and-conditions'})
    }
  }

  var [examCatalog, setExamCatalog] = React.useState();
  var {data:getExamCatalog, error:getExamCatalogError, trigger:getExamCatalogTrigger, isMutating:getExamCatalogMutating} = useGetExamCatalog()
  if(!examCatalog){
    getExamCatalogTrigger();
  }
  React.useEffect(() => {
    if(getExamCatalog && getExamCatalog.cats.length>0 && !examCatalog){
      let size
      let catalog = getExamCatalog;
      let cats = getExamCatalog.cats;
      for (let index = 0; index < cats.length; index++) {
        let chunks = []
        const examCount = cats[index].exams.length
        size = examCount > 3 ? Math.ceil(cats[index].exams.length/2) : examCount
        for (let i = 0; i < cats[index].exams.length; i += size) {
          let chunk = cats[index].exams.slice(i, i + size);
          chunks.push(chunk);
        }
        catalog.cats[index].exams = chunks
      }
      setExamCatalog(catalog)
    }else if(getExamCatalog?.errs && getExamCatalog?.errs.length > 0){
      
    }else if(getExamCatalogError){
      
    }
  }, [getExamCatalog, getExamCatalogError, examCatalog]); // Run the effect whenever the data changes


  var [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  // Form Validations
  const examEventSchema = Yup.object().shape({
    fn: getNameValidator(),
    ln: getNameValidator(),
    eml: getEmailValidator(true)
  });
  const { handleSubmit:validateExamEventSchema, reset:resetExamEventSchema, control:controlExamEventSchema } = useForm({mode:'onTouched', resolver: yupResolver(examEventSchema)});   

  const paymentSchema = Yup.object().shape({
  });
  const { handleSubmit:validatePaymentForm, reset:resetPaymentForm, control:controlPaymentForm } = useForm({mode:'onTouched', resolver: yupResolver(paymentSchema)}); 
  var [price, setPrice] = React.useState({});

  const findPrice = (exam, cur) => {
    const item = exam.prices.filter(price => price.cur === cur);
    return item[0]
  }

  const handleCurrencyChange = (event) => {
    resetPaymentForm({cur:event.target.value})
    setPrice(findPrice(selectedExam, event.target.value))
    if(examCatalog.pgwys.length === 2){
      setShowUPIPay((event.target.value === 'INR'))
    }
  }

  const handleExamDialog = (id) => {
    // If No Payment Gateway enabled, show information dialog
    if(examCatalog.pgwys.length === 0){
      Swal.fire('Sorry!', 'Payment links are Not active, we are working hard to bring them live.','info')
      return;
    }

    let selectedExam = {};
    // Check if country / currency already determined, else default to USD
    var defaultCur = "USD"
    const country = getSessionData(constants.SESSION_KEY_COUNTRY)
    if(country && country!=='undefined'){
      defaultCur = JSON.parse(country).cur
    }
    resetPaymentForm({cur: defaultCur})
    examCatalog.cats.forEach(cat => {
      cat.exams.forEach(exams => {
        exams.forEach(exam => {
          if(exam.id === id){
            selectedExam = {...exam, val: cat.val, atmt: cat.atmt, prices: examCatalog.prices, pgwys: examCatalog.pgwys};
            // Find Price
            setPrice(findPrice(selectedExam, defaultCur))
          }
        })
      })
    })
    setSelectedExam(selectedExam)
    resetExamEventSchema()
    setActiveStep(0)
    setOpenExamDialog(true)
    if(examCatalog.pgwys.length === 2){
      setShowUPIPay((defaultCur === 'INR'))
    }
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({event: 'view_item', ecommerce:{currency: defaultCur, value: price.amt, items: [{item_id: selectedExam.id, item_name: selectedExam.ttl}]}});
  }
  
  // This will be invoked as needed
  const {data:createPurchase, error:createPurchaseError, trigger:triggerCreatePurchase, isMutating:createPurchaseeMutating} = useCreatePurchaseItem({})
  React.useEffect(() => {
    if(createPurchase && createPurchase.id){
      putSessionData(constants.SESSION_KEY_PURCHASE, JSON.stringify({...profile, id: createPurchase.id, exam: selectedExam, amt: price.amt, cur: price.cur}))
      window.location.replace(createPurchase.plnk);
    }else if(createPurchase?.errs && createPurchase?.errs.length > 0){
      setSnackBar({show:true,severity:'error',message:createPurchase.errs[0].msg})
    }else if(createPurchaseError){
      setSnackBar({show:true,severity:'error',message:errors.E202})
    }
  }, [createPurchase, createPurchaseError, profile, selectedExam, price]); // Run the effect whenever the data changes

  const handleProfileSave = async(formData) => {
    setProfile({...formData, exam:{id:selectedExam.id}})
    window.dataLayer.push({event: 'add_to_cart', ecommerce:{currency: price.cur, value: price.amt, items: [{item_id: selectedExam.id, item_name: selectedExam.ttl}]}});
    setActiveStep(1)
  };
  
  const handlePayment = async(formData, pgwy) => {
    if(!tandcAccepted){
      setSnackBar({show:true,severity:'error',message:errors.E204})
      window.dataLayer.push({event:'error_event',event_category:'practice-exam',event_action:'error',event_label:'E204',event_value:errors.E204})
      return;
    }
    const arg = { 
      requestBody: {...profile, pay:{gwy:{nm:pgwy},cur:formData.cur}}
    }
    window.dataLayer.push({event: 'begin_checkout', ecommerce:{currency: formData.cur, value: price.amt, items: [{item_id: selectedExam.id, item_name: selectedExam.ttl}]}});
    await triggerCreatePurchase(arg)
  }
  const handleUPIPayment = async(formData) => {
    handlePayment(formData, 'PHONEPE')
  };

  const handleStripePayment = async(formData) => {
    handlePayment(formData, 'STRIPE')
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <AccountBoxIcon />,
      2: <PaymentIcon />,
      3: <ConfirmationNumberIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <>
    <MUISnackBar id="exam-purchase-err" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
    <Stack direction="row" sx={{ width: 1 }} padding={2}>
      <Grid container spacing={{ xs: 4, sm: 5, md: 10 }} columns={{ xs: 4, sm: 8, md: 10 }}>
        {getExamCatalogMutating &&
          <>
            <Grid item xs={4} sm={8} md={5} >
              <Stack spacing={1} width={{xs: 300, sm:400, md: 450}}>
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
              </Stack>
            </Grid>
            <Grid item xs={4} sm={8} md={5}>
              <Stack spacing={1} width={{xs: 300, sm:400, md: 450}}>
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
                <Skeleton variant="rectangular" animation="wave" height={40} />
              </Stack>
            </Grid>
          </>
        }
        {!getExamCatalogMutating && examCatalog?.cats.length > 0 && examCatalog.cats.map((cat) => (
            cat.exams.map((row, index) => (
              <Grid item xs={4} sm={8} md={5} key={index}>
                <Typography variant='h5' marginBottom={2}>{cat.ttl}</Typography>
                  {row.map((exam) => (
                    <Accordion expanded={expanded === 'exam-'+exam.id} onChange={handleChange('exam-'+exam.id)} key={exam.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={"panel-title-"+exam.id}>
                          <Typography>{exam.ttl}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                            <Grid item xs={4} sm={4} md={3}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Questions:</strong></Typography>
                                <Typography variant='body1'>{exam?.qcnt}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={4} sm={4} md={3}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Level:</strong></Typography>
                                <Typography variant='body1'>{exam?.lvl}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Duration:</strong></Typography>
                                <Typography variant='body1'>{exam?.tlmt} Minutes</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={4} sm={4} md={7}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant='body1'><strong>Reference:</strong></Typography>
                                <Typography variant='body1'><Link href={exam?.more} target="_blank">exam details</Link></Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                          <Stack sx={{ pt: 2 }} direction="row" spacing={2} justifyContent="flex-start" marginTop={1}>
                            <Button id={"take-exam-btn-"+exam.id} variant="contained" size='small' color="secondary" onClick={()=>{handleExamDialog(exam.id)}}>Take Exam</Button>
                          </Stack>
                        </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>  
            ))
        ))}
      </Grid>
    </Stack>
    <Dialog open={openExamDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography id="take-practice-exam-lbl" fontSize={24}>Take Practice Exam</Typography>
            <IconButton onClick={() => {setOpenExamDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
            <Grid item xs={4} sm={8} md={10} marginBottom={2}>
                <Stack direction="column" spacing={1}>
                  <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                      <Grid item xs={4} sm={8} md={10}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body1'><strong>Title:</strong></Typography>
                          <Typography>{selectedExam?.ttl}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={3} md={3}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body1'><>Level:</></Typography>
                          <Typography variant='body1'>{selectedExam?.lvl}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={2} md={3}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body1'><>Questions:</></Typography>
                          <Typography variant='body1'><strong>{selectedExam?.qcnt}</strong></Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={3} md={4}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body1'><>Duration:</></Typography>
                          <Typography variant='body1'><strong>{selectedExam?.tlmt} Minutes</strong></Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={4} md={5}>
                        <Paper elevation={0} sx={{ backgroundColor: "info.light", padding: "5px"}}>
                          <Stack direction="row" spacing={1} marginBottom={2}>
                            <Typography variant='body1'><>Validity:</></Typography>
                            <Typography variant='body1'>{selectedExam?.val} days *</Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant='body1'><>Attempts:</></Typography>
                            <Typography variant='body1'>{selectedExam?.atmt} final submissions *</Typography>
                          </Stack>
                        </Paper>
                      </Grid>
                      <Grid item xs={4} sm={4} md={5}>
                        <Paper elevation={0} sx={{ backgroundColor: "info.light", padding: "5px" }}>
                          <Stack direction="row" spacing={1} marginBottom={1} width={0.8}>
                            <Typography variant='body1'><>Currency:</></Typography>
                            <MUISelectField id="exam-currency-code" name="cur" variant='standard' control={controlPaymentForm} options={constants.PAY_CURRENCIES} onChange={handleCurrencyChange}/>
                          </Stack>
                          <Stack direction="row" spacing={2} alignItems={'center'}>
                            <Typography id="exam-price-id" variant='body1'>Price: <s>{price.mrp}</s>&nbsp;&nbsp;<strong>{price.amt}&nbsp;{price.sym}</strong></Typography>
                            <Tooltip title="50% Off - Launch Offer!!" placement="right-start" fontSize="small"><LocalOfferOutlinedIcon /></Tooltip>
                          </Stack>
                        </Paper>
                      </Grid>
                  </Grid>
                  <Divider />
                </Stack>
            </Grid>
            <Stack sx={{ width: '100%' }} spacing={4} marginBottom={3}>
                <Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
                  <Step key={'Profile'}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{'Profile'}</StepLabel>
                  </Step>
                  <Step key={'Checkout'}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{'Checkout'}</StepLabel>
                  </Step>
                </Stepper>
            </Stack>
            {activeStep === 0 &&
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                  <Grid item xs={4} sm={4} md={5}>
                      <MUITextField id="exam-profile-fn" label="First Name" name="fn" control={controlExamEventSchema} required maxLength={41}/>
                  </Grid>
                  <Grid item xs={4} sm={4} md={5}>
                      <MUITextField id="exam-profile-ln" label="Last Name" name="ln" control={controlExamEventSchema} required maxLength={41}/>
                  </Grid>
                  <Grid item xs={4} sm={8} md={10}>
                      <MUITextField id="exam-profile-eml" label="Email" name="eml" control={controlExamEventSchema} required maxLength={81}/>
                  </Grid>
                  <Grid item xs={4} sm={8} md={10}>
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant='body2'>* Expires: 30 days from the date of purchase or after 3 final submissions.</Typography>
                        <MUIButton id="exam-profile-next-btn" loading={false} onClick={validateExamEventSchema(handleProfileSave)} size="small" label="Next" type="secondary" />
                      </Stack>
                  </Grid>
              </Grid>
            }
            {activeStep === 1 &&
            <>
              <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                  <Grid item xs={4} sm={4} md={5} marginLeft={2}>
                      <Stack direction="column" spacing={1} marginBottom={0}>
                        <Typography variant='body1'><strong>Profile: </strong><Link id="edit-exam-profile-btn" onClick={() => setActiveStep(0)}>edit</Link></Typography>
                        <Typography variant='body1'>{profile.fn} {profile.ln}</Typography>
                        <Typography variant='body1'>{profile.eml}</Typography>
                      </Stack>
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item xs={4} sm={3} md={4} alignItems={'center'}>
                      { showUPIPay &&
                        <Stack direction="column" spacing={2} marginLeft={4}>
                          <MUIButton id="exam-upi-pay-btn" loading={createPurchaseeMutating} onClick={validatePaymentForm(handleUPIPayment)} label="UPI Pay" type="info" />
                          <MUIButton id="exam-stripe-pay-btn" loading={createPurchaseeMutating} onClick={validatePaymentForm(handleStripePayment)} label="Stripe Pay" type="primary" variant="outlined"/>
                        </Stack>
                      }
                      { !showUPIPay &&
                        <Stack direction="column" spacing={1} marginLeft={4}>
                          <MUIButton id="exam-stripe-pay-btn" loading={createPurchaseeMutating} onClick={validatePaymentForm(handleStripePayment)} label="Stripe Pay" type="info" />
                        </Stack>
                      }
                  </Grid>
                  <Grid item xs={4} sm={8} md={10} marginTop={1}>
                      <Stack direction="row" spacing={0} alignItems="center">
                        <Checkbox id="exam-accept-terms-btn" required color="success" sx={{[`&`]: {color: 'red'}}} onChange={(event)=>setTandCAccepted(event.target.checked)}/>
                        <Typography variant='body2' color={'red'}>No cancellation allowed, you accept our <Link color="inherit" component={'button'} onClick={()=>handleLink('terms-and-conditions')} target="_self">Terms and Conditions</Link></Typography>
                      </Stack>
                      <Typography variant='body2'>* You will get exam link on confirmation page and also with in email.</Typography>
                  </Grid>
              </Grid>
              </>
            }
        </DialogContent>
    </Dialog>
    </>
  );
};

export default ExamsCatalog;

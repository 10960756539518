import React from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, Typography, Grid, Stack, FormControlLabel, Checkbox, TextField, keyframes } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getQuestionTextValidator, getAnswerOptionReqValidator, getAnswerOptionOptValidator } from '../../services/validations';
import MUITextArea from './fields/MUITextArea';
import MUIButton from './fields/MUIButton';
import Swal from 'sweetalert2';
import { strContainInArray } from '../../util/utility';
import { getSessionData } from '../../services/storage-services';
import constants from '../../util/constants';
import MUICheckBox from './fields/MUICheckBox';
import { useUpdateTestQuestions } from '../../services/testbank-services';
import errors from '../../util/errors';
import alerts from '../../util/alerts';
import MUISnackBar from './MUISnackBar';

const PrepareQuestions = (props) => {

  var [questions, setQuestions] = React.useState([]);
  var [currentQuestion, setCurrentQuestion] = React.useState();
  var [currentQuestionModified, setCurrentQuestionModified] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({show:false});

  // Handle new questions or existing questions logic here when ever dialog opens
  React.useEffect(() => {
    if(props.show === true){
        setCurrentQuestionModified(false)
        if(props.questions && props.questions.qtns){
            // Clone the object to keep original safe until
            setQuestions(Object.assign([], props.questions.qtns))
            mapQuestionData(Object.assign({}, props.questions.qtns[0]));
            console.log(questions)
        }else{
            setQuestions([{}])
            mapQuestionData({id:1, opts:[], ans:[]})
        }
    }
  }, [props.show]); // Run when ever dialog open / close

  // Mapping data to show the current question in the window
  const mapQuestionData = async(qtn) => {
    setCurrentQuestion(qtn)
    resetQuestionSchema({id:qtn.id, 
                        typ:qtn.typ,
                        txt:qtn.txt, 
                        optA:qtn.opts[0]?.txt, 
                        optACheck: strContainInArray("A",qtn.ans),
                        optB:qtn.opts[1]?.txt, 
                        optBCheck: strContainInArray("B",qtn.ans),
                        optC:qtn.opts[2]?.txt||null, 
                        optCCheck: strContainInArray("C",qtn.ans),
                        optD:qtn.opts[3]?.txt||null,
                        optDCheck: strContainInArray("D",qtn.ans), 
                        optE:qtn.opts[4]?.txt||null,
                        optECheck: strContainInArray("E",qtn.ans)})
  }

  const handleAdd = async(formData) => {
    // Verify if the Limit on Number of Test under the Subscribed package is complete
    const sub = JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION))?.sub
    var limit = -1
    if(sub === constants.PKGTRAIL && questions?.length > 9){
        limit = 10
    }else if(sub === constants.PKG10 && questions?.length > 9){
        limit = 10
    }else if(sub === constants.PKG25 && questions?.length > 24){
        limit = 24
    }else if(sub === constants.PKG100 && questions?.length > 99){
        limit = 100
    }

    if(limit > -1){
        Swal.fire({
            target: document.getElementById('edit-questions-dialog'),
            title: 'Subscription Limit', 
            text: 'Your current subscription has a limit of '+limit+' Questions per Test. To create New Question, either upgrade the subscription or delete the unused Test Question.',
            icon: 'info'
        })
    }else{
        // Save current question and create new one
        questions[currentQuestion.id-1] = saveQuestion(formData)
        // add empty object at the end
        const count = questions.length
        questions[count] = {}
        setQuestions(questions)
        mapQuestionData({id:count+1, txt:null, 
                        opts:[{id:'A',txt:null},{id:'B',txt:null},{id:'C',txt:null},{id:'D',txt:null},{id:'E',txt:null}], 
                        ans:[]})
    }
  }

  const saveQuestion = (data) => {
    var qtn = {id:data.id, txt: data.txt, 
                opts:[], 
                ans:[]}
    if(data.optA){qtn.opts.push({id:'A',txt:data.optA})}
    if(data.optB){qtn.opts.push({id:'B',txt:data.optB})}
    if(data.optC){qtn.opts.push({id:'C',txt:data.optC})}
    if(data.optD){qtn.opts.push({id:'D',txt:data.optD})}
    if(data.optE){qtn.opts.push({id:'E',txt:data.optE})}

    if(data.optACheck){qtn.ans.push("A")}
    if(data.optBCheck){qtn.ans.push("B")}
    if(data.optCCheck){qtn.ans.push("C")}
    if(data.optDCheck){qtn.ans.push("D")}
    if(data.optECheck){qtn.ans.push("E")}
    if(qtn.ans.length<2){qtn.typ = constants.SCQ_TYPE}
    else {qtn.typ = constants.MCQ_TYPE}
    return qtn;
  }

  const handleDelete = async() => {
    Swal.fire({
        target: document.getElementById('edit-questions-dialog'),
        title: "Are you sure ?",
        text: "Delete current question ? you will not be able to recover this question after submit!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
    })
    .then((willDelete) => {
        if(willDelete.isConfirmed) {
            questions.splice(currentQuestion.id-1,1)
            // Reset question number again in sequence
            questions.forEach((object, index) => {
              object.id = index + 1
            })
            setQuestions(questions)
            // if this is 1st question, load next one, else load previous one
            if(currentQuestion.id == 1){           
                mapQuestionData(questions[0])
            }else{
                mapQuestionData(questions[currentQuestion.id-2])
            }
        }
    });
  };

  // Show warning if current question is modified
  const handlePrevious = async(formData) => {
    // Save current question
    questions[currentQuestion.id-1] = saveQuestion(formData)
    mapQuestionData(questions[currentQuestion.id-2])
  };

  // Show warning if current question is modified
  const handleNext = async(formData) => {
    // Save current question
    questions[currentQuestion.id-1] = saveQuestion(formData)
    mapQuestionData(questions[currentQuestion.id])
  };

  // Form Validations
  const questionSchema = Yup.object().shape({
    id: Yup.number(),
    typ: Yup.string(),
    txt: getQuestionTextValidator(),
    optA: getAnswerOptionReqValidator(),
    optACheck: Yup.bool(),
    optB: getAnswerOptionReqValidator(),
    optBCheck: Yup.bool(),
    optC: getAnswerOptionOptValidator(),
    optCCheck: Yup.bool(),
    optD: getAnswerOptionOptValidator(),
    optDCheck: Yup.bool(),
    optE: getAnswerOptionOptValidator(),
    optECheck: Yup.bool()
  }).test(
    (obj) => {
        if (obj.optACheck || obj.optBCheck || obj.optCCheck ||  obj.optDCheck || obj.optECheck) {
            return true; // at least one selected
        }else{
            return new Yup.ValidationError('Minimum one answer to be selected',null,'txt');
        }
    }
  );
  const {handleSubmit:validateQuestionSchema, reset:resetQuestionSchema, control:controlQuestionSchema, setValue:setValueQuestionSchema, getValues:getValuesQuestionSchema, clearErrors} = useForm({mode:'onChange', resolver: yupResolver(questionSchema)});   

  const handleCheckBoxChange = (event) => {
    // Toggle the values
    setCurrentQuestionModified(true)
    setValueQuestionSchema(event.target.name,!getValuesQuestionSchema(event.target.name))
    if(getValuesQuestionSchema(event.target.name)){
        clearErrors()
    }
  };

  const handleQuestionSubmit = async(formData) => {
    questions[currentQuestion.id-1] = saveQuestion(formData)
    const arg = { 
        pathParams: {testId: props.testId},
        requestBody: {qtns:questions}
    }
    triggerTestQuestions(arg)
  };

  const {data:testQuestions, error:testQuestionsError, trigger:triggerTestQuestions, isMutating:testQuestionsMutating } = useUpdateTestQuestions()
  React.useEffect(() => {
    if(testQuestions?.errs && testQuestions?.errs.length > 0){
        setSnackBar({show:true,severity:'error',message:testQuestions.errs[0].msg})
    }else if(testQuestionsError){
        setSnackBar({show:true,severity:'error',message:errors.E102})
    }else if(testQuestions && testQuestions.id){
        setSnackBar({show:true,severity:'success',message:alerts.A113})
        props.onClose(true)
    }
  }, [testQuestions]); // Run the effect whenever the data changes

  const handleClose = () => {
    if(currentQuestionModified){
        Swal.fire({
            target: document.getElementById('edit-questions-dialog'),
            title: "Are you sure ?",
            text: "Your changes will be lost. You need to submit the changes!",
            icon: "warning",
            showCancelButton: true,confirmButtonColor: '#d33',cancelButtonColor: '#3085d6',confirmButtonText: 'Yes, close!'
        })
        .then((willClose) => {
            if(willClose.isConfirmed) {props.onClose()}
        });
    }else{
        props.onClose()
    }
  };

  return (
    <>
        <MUISnackBar id="testbank-questions-bar" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
        <Dialog id="edit-questions-dialog" open={props.show} fullWidth maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography id="questions-dialog-lbl" fontSize={24}>Test Question: {currentQuestion?.id} of {questions?.length}</Typography>
                        <MUIButton id="prepare-qtns-previous" label="Previous" variant="outlined" type="info" disabled={currentQuestion?.id <= 1} onClick={validateQuestionSchema(handlePrevious)}/>
                        <MUIButton id="prepare-qtns-next" label="Next" variant="outlined" type="info" disabled={currentQuestion?.id >= questions?.length} onClick={validateQuestionSchema(handleNext)}/>
                    <Typography>Goto:</Typography>
                </Stack>
                <IconButton id="testbank-questions-close" onClick={() => handleClose()} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid item xs={4} sm={8} md={10} marginBottom={2}>
                    <Stack direction="column" spacing={1}>
                        <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                            <Grid item xs={4} sm={8} md={10}>
                                <Stack direction="column" spacing={1} marginBottom={2}>
                                    <MUITextArea id="question.txt" onInput={()=>setCurrentQuestionModified(true)}name="txt" rows={4} label="Question Text" control={controlQuestionSchema} maxLength={2056}/>
                                </Stack>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <MUICheckBox id="question.optACheck" onChange={handleCheckBoxChange} name="optACheck" control={controlQuestionSchema} />
                                        <MUITextArea id="question.optA.txt" onInput={()=>setCurrentQuestionModified(true)} name="optA" rows={1} label="Option: A" control={controlQuestionSchema} maxLength={512}/>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <MUICheckBox id="question.optBCheck" onChange={handleCheckBoxChange} name="optBCheck" control={controlQuestionSchema}/>
                                        <MUITextArea id="question.optB.txt" onInput={()=>setCurrentQuestionModified(true)} name="optB" rows={1} label="Option: B" control={controlQuestionSchema} maxLength={512}/>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <MUICheckBox id="question.optCCheck" onChange={handleCheckBoxChange} name="optCCheck" control={controlQuestionSchema}/>
                                        <MUITextArea id="question.optC.txt" onInput={()=>setCurrentQuestionModified(true)} name="optC" rows={1} label="Option: C" control={controlQuestionSchema} maxLength={512}/>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <MUICheckBox id="question.optDCheck" onChange={handleCheckBoxChange} name="optDCheck" control={controlQuestionSchema}/>
                                        <MUITextArea id="question.optD.txt" onInput={()=>setCurrentQuestionModified(true)} name="optD" rows={1} label="Option: D" control={controlQuestionSchema} maxLength={512}/>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <MUICheckBox id="question.optECheck" onChange={handleCheckBoxChange} name="optECheck" control={controlQuestionSchema}/>
                                        <MUITextArea id="question.optE.txt" onInput={()=>setCurrentQuestionModified(true)} name="optE" rows={1} label="Option: E" control={controlQuestionSchema} maxLength={512}/>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" marginTop={3} justifyContent="space-between">
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <MUIButton id="prepare-qtns-add" label="Add" variant="outlined" type="secondary" size="small" onClick={validateQuestionSchema(handleAdd)}/>
                                        <MUIButton id="prepare-qtns-delete" label="Delete" variant="outlined" type="secondary" disabled={questions?.length <= 1} onClick={()=>handleDelete()}/>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography>All questions done?</Typography>
                                        <MUIButton id="prepare-qtns-submit" label="Submit" variant="contained" type="secondary" size="small" onClick={validateQuestionSchema(handleQuestionSubmit)}/>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </DialogContent>
        </Dialog>
    </>
  );

}
export default PrepareQuestions;
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import QuizIcon from '@mui/icons-material/Quiz';
import GradingIcon from '@mui/icons-material/Grading';

function handleClick(event) {
  event.preventDefault();
}

const MUIBreadCrumbs = (props) => {
  const renderIcon = (title) => {
    switch(title) {
      case 'Results':
        return <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit"/>;
      case 'Attempt':
        return <QuizIcon sx={{ mr: 0.5 }} fontSize="inherit"/>;
      default:
        return <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit"/>;
    }
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        {props.crumbs.links.map((row) => (
          <Link id={row.id} key={row.id} sx={{ display: 'flex', alignItems: 'center' }} component={'button'} onClick={row.onClick}>
            {renderIcon(row.text)}{row.text}
          </Link>
        ))}
        {props.crumbs.current &&
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            {renderIcon(props.crumbs.current.text)}{props.crumbs.current.text}
          </Typography>
        }
      </Breadcrumbs>
    </div>
  );
}

export default MUIBreadCrumbs;